@sidebar-pane-bg-color: @color-white;
@sidebar-section-title-color: @color-text-default-on-light;

@sidebar-section-item-color: @color-primary;

@sidebar-divider-size: @size-s;
@sidebar-divider-border-color: @color-divider-default;
@sidebar-divider-bg-color: @color-white;

@sidebar-submit-bg-color: @color-white;

@sidebar-title-margin-bottom: @spacing-s;
@sidebar-padding-horizontal: @spacing-xxl;
@sidebar-padding-bottom: @spacing-4xl;

.sidebar__pane {
	position: fixed;
	z-index: @z-index-nine-hundred;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	width: @size-sidebar-width;
	flex-flow: column;
	background-color: @color-white;
	border-top-left-radius: @border-radius-sidebar;
	box-shadow: @box-shadow-sidebar;
	overflow-x: hidden;
}

.sidebar__content {
	position: relative;
	flex: 1 1 auto;
	padding: @spacing-xl @sidebar-padding-horizontal @sidebar-padding-bottom @sidebar-padding-horizontal;
	overflow-x: hidden;
}

.sidebar__section {
	position: relative;
	padding: 0 @sidebar-padding-horizontal;
	overflow-wrap: anywhere;
	word-break: break-word; // "normal" should be here to work with overflow-wrap desirably, however, due to lack of support in Safari…

	.sidebar__content & {
		padding: 0;
	}

	& + & {
		margin-top: @spacing-xxl;
	}

	// The reason for a special class is to make a whole usage row highlighted on hover
	&--contains-hover {
		padding: 0;

		.sidebar__section-title, .sidebar__section-content {
			padding: 0 @sidebar-padding-horizontal;
			margin-bottom: @sidebar-title-margin-bottom;
		}

		.sidebar__section-item {
			padding: 4px @sidebar-padding-horizontal;

			&--adjacent {
				padding-top: 0;
				margin-top: 0;
			}
		}
	}

	& + &--no-title {
		margin-top: @spacing-l;
	}

	.asset-library-sidebar__content & {
		&:first-child {
			padding-top: @spacing-xxl;
		}

		&:last-child {
			padding-bottom: @sidebar-padding-bottom;
		}
	}
}

.sidebar__section-title {
	.typography-styles-headline-medium();

	margin-bottom: @sidebar-title-margin-bottom;
	color: @sidebar-section-title-color;


	&--is-clickable {
		cursor: pointer;

		&:hover {
			background-color: @content-item-usage-hover-bg-color;
			transition: all @OLD_transition-150;
		}
	}
}

.sidebar__section-content {
	.typography-styles-label-large();

	color: @color-text-low-emphasis;
}

.sidebar__section-item {
	.typography-styles-body-medium();

	color: @color-text-default-on-light;

	&--is-clickable {
		display: flex;
		align-items: center;
		cursor: pointer;

		&:hover,
		&:focus {
			background-color: @content-item-usage-hover-bg-color;
			transition: all @transition-bg-hover;
		}
	}
}

.sidebar__section-item-link {
	.typography-styles-action-medium();

	color: @sidebar-section-item-color;
	text-decoration: none;

	&:hover {
		color: @color-primary-hover;
		text-decoration: underline;
	}
}

.sidebar__section-button-wrapper {
	margin-top: @spacing-l;
}

.sidebar__divider {
	position: relative;
	height: (@sidebar-divider-size * 0.5);
	border-bottom: 1px solid @sidebar-divider-border-color;
	margin-top: @spacing-s;
	margin-bottom: (@sidebar-divider-size * 0.5) + @spacing-s;

	&::before {
		.typography-styles-label-large();

		position: absolute;
		top: 0;
		left: calc((50% - (@sidebar-divider-size * 0.5)));
		display: flex;
		width: @sidebar-divider-size;
		height: @sidebar-divider-size;
		align-items: center;
		justify-content: center;
		border: 1px solid @sidebar-divider-border-color;
		border-radius: @border-radius-pill;
		background-color: @sidebar-divider-bg-color;
		color: @color-text-low-emphasis;
		content: "or";
		text-transform: uppercase;
	}
}

.sidebar__header {
	display: flex;
	width: 100%;
	max-width: 100%;
	justify-content: space-between;
	margin-bottom: @spacing-xl;
}

.sidebar__title {
	.typography-styles-headline-medium();

	margin-bottom: 0;
	color: @color-text-default-on-light;
}

.sidebar__action-close {
	margin-left: @spacing-m;
}

.sidebar__subtitle {
	.typography-styles-headline-medium();

	margin-bottom: @spacing-l;
	color: @color-text-default-on-light;
}

.sidebar__description {
	.typography-styles-label-medium();

	margin-top: @spacing-s;
	color: @color-text-default-on-light;
}

.sidebar__submit {
	z-index: @z-index-one-hundred;
	flex: 0 0 auto;
	padding: @spacing-xl @spacing-xxl;
	border-top: @border-width-default solid @color-divider-default;
	background: @sidebar-submit-bg-color;
}

.sidebar__submit-details {
	.typography-styles-caption();

	color: @color-text-default-on-light;
}

.sidebar__submit-action {
	.sidebar__submit-details:not(:empty) + & {
		margin-top: @spacing-l;
	}
}

.sidebar__submit-alert {
	.typography-styles-ui-paragraph();

	color: @color-alert-text;

	.sidebar__submit-action + & {
		margin-top: @spacing-l;
	}
}
