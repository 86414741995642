//
// Utility classes
// --------------------------------------------------

// Floats
// -------------------------

.clearfix {
	clear: both;
	.clearfix();
}


// Flexbox
// ------------------------

.flex {
	display: flex;

	&--center {
		justify-content: center;
	}

	&--column {
		flex-direction: column;
	}

	& > .flex-item--max-width--1000 {
		width: 100%;
		max-width: 1000px;
	}

	& > .flex-item--grow--1 {
		flex-grow: 1;
	}
}

.u-ellipsis {
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.u-no-text-decoration {
	&,
	&:focus,
	&:hover {
		text-decoration: none;
	}
}

.u-inherit-color {
	&,
	&:focus,
	&:hover {
		color: inherit;
	}
}

.u-rotate-90 {
	transform: rotate(90deg);
}

.u-space-between--2 {
	& > * + * {
		margin-left: @grid-unit;
	}
}


.u-inline-block {
	display: inline-block;
}

.u-transparent-border {
	border-color: transparent;

	&:hover,
	&:focus {
		border-color: transparent;
	}

	&[disabled]:hover {
		border-color: transparent;
	}
}

.u-no-left-padding {
	padding-left: 0;
}

.u-transparent-background {
	background-color: transparent;
}

.u-spacing-top-l {
	margin-top: @spacing-l;
}

.u-spacing-bottom-l {
	margin-bottom: @spacing-l;
}

.content--ignore-pointer-events {
	/* stylelint-disable*/
	pointer-events: none !important;
	/*stylelint-enable*/
}

.u-no-margin {
	margin: 0;
}
