/* ----------------------------------------------------------------------------------------------------------*\
		#asset-library-pane
\*---------------------------------------------------------------------------------------------------------- */
.asset-library-pane {
	@media print {
		max-width: 100%;
	}
}


.asset-library-pane__editor-pane {
	width: 100%;
	max-width: 1000px;
	margin: 0 auto @spacing-l auto;
}


/* ----------------------------------------------------------------------------------------------------------*\
		#asset-library-pane__editor-content

		[1] vertical rhythm for all children
\*---------------------------------------------------------------------------------------------------------- */
.asset-library-pane__editor-content {
	position: relative;

	& > * + * {
		margin-top: @spacing-card; /* [1] */
	}

	&&--is-disabled > .content-item-pane__elements-group {
		&:first-child {
			border-bottom: solid 1px @color-divider-disabled;
		}

		+ .content-item-pane__elements-group {
			margin-top: 0;
		}
	}

	img {
		max-width: 100%;
		background-color: @color-white;
	}

	.asset-library-pane__callout-pane + & {
		margin-top: @spacing-l;
	}
}

.asset-library-pane__editor-preview {
	display: inline-block;
	padding: @spacing-l;
	border-radius: @border-radius-element;
	background: @color-white;
	box-shadow: @box-shadow-xs;

	.asset-library-pane__editor-content--is-disabled & {
		margin-left: @content-item-element-horizontal-padding;
	}
}
