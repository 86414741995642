.modal-dialog-two-column-layout {
	display: flex;
	width: (110 * @grid-unit);
	height: auto;
	flex-flow: column;

	&--narrow {
		width: (96 * @grid-unit);
		max-width: 100%;
	}
}

.modal-dialog-two-column-layout__top {
	margin-bottom: @spacing-l;
}

.modal-dialog-two-column-layout__sections {
	display: flex;
	min-height: 0;
}

.modal-dialog-two-column-layout__section-wrapper {
	position: relative;
	min-width: 0;
	flex: 1;
}

.modal-dialog-two-column-layout__section {
	height: 100%;
	overflow-y: auto;
}

.modal-dialog-two-column-layout__sections-separator {
	width: 1px;
	background: @color-divider-default;
}
