.asset-folder__icon {
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin: 0 @spacing-s 0 @spacing-l;
	color: @color-yellow40;
}

.asset-folder__name {
	.typography-styles-body-medium();

	overflow: hidden;
	width: 100%;
	color: @color-text-default-on-light;
	text-overflow: ellipsis;
	white-space: nowrap;

	&:last-child {
		margin-right: @spacing-s;
	}
}

.asset-folder__action {
	margin: 0 @spacing-s;
}
