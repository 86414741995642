@action-link-color: @color-text-default-on-light;

.action-link {
	.typography-styles-label-medium();

	background-color: transparent;
	color: @action-link-color;
	cursor: pointer;
	text-decoration: none;
	transition: all @OLD_transition-150;

	&:hover,
	&:focus {
		text-decoration: none;
	}

	&:active {
		text-decoration: none;
	}

	&:focus {
		.tab-focus();
	}

	.taxonomy & {
		padding: @spacing-xs;
		border-radius: @border-radius-s;

		&:hover,
		&:focus {
			background-color: @color-background-hover;
			color: @action-link-color;
		}
	}

	+ .action-link,
	+ .btn-wrapper,
	.btn-wrapper + & {
		margin-left: @spacing-s;
	}
}

.action-link__pane {
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	.btn-wrapper,
	.btn-wrapper + .btn-wrapper {
		margin: @spacing-xs @spacing-s @spacing-xs 0;
	}
}

.action-link__pane-wrapper {
	display: flex;
	min-width: 0;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	margin-top: @spacing-xs;
	margin-bottom: @spacing-xs;

	.action-link__pane-info {
		overflow: hidden;
		margin-right: @spacing-s;
		margin-bottom: @spacing-xs;
		text-overflow: ellipsis;
	}
}
