.contextual-help {
	position: absolute;
	z-index: @z-index-seven-hundred;
	bottom: @spacing-l;
	left: (@size-navigation-bar-width + @spacing-main-layout-left);
	width: (64 * @grid-unit);
}

.contextual-help__pane {
	position: relative;
	display: flex;
	flex-direction: column;
	padding: @spacing-card;
	border-radius: @border-radius-card;
	background-color: @color-gray80;
	box-shadow: @box-shadow-l;
	transition: all 0.5s ease;

	&--hidden {
		opacity: 0;
		transform: translateY((3 * @grid-unit));
	}
}

.contextual-help__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: @spacing-xl;
}

.contextual-help__title {
	.typography-styles-headline-large();

	margin-bottom: 0;
	color: @color-text-default-on-dark;
}

.contextual-help__content {
	.typography-styles-ui-paragraph();

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: @color-text-default-on-dark;
	user-select: none;
}

.contextual-help__bulletpoints {
	margin-top: 0;
}

.contextual-help__dismiss-button.btn--quinary {
	border-color: @color-transparent;
	background-color: @color-transparent;
	color: @color-text-default-on-dark;

	&:hover {
		background-color: rgb(@color-white 0.16);
	}
}

.contextual-help__link {
	color: @color-link-inverted;

	&:hover,
	&:focus {
		color: @color-link-inverted-hover;
	}
}
