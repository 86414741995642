// Creates a dashed border via SVG image-background
// Simple variant which can be used for elements which are not changing their side
.dashed-border(@width, @color, @border-radius, @dash-array, @dash-offset) {
	// SVG stroke is rendered at the center of the path. Its outer portion is rendered outside
	// the viewBox so it is not visible — we need to double it to get the expected visible "stroke" width.
	// E.g. in case of 2px stroke-width, 1px is rendered on the inside and 1px on the outside.
	// Only the inside stroke of 1px is visible.
	@stroke-width: @width * 2;
	@stroke: "stroke='@{color}' stroke-width='@{stroke-width} ' stroke-dasharray='@{dash-array}' stroke-dashoffset='@{dash-offset}' stroke-linecap='butt'";
	@rect-attrs: "width='100%' height='100%' fill='none' rx='@{border-radius}' ry='@{border-radius}' @{stroke}";
	@svg-code: "<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect @{rect-attrs} /></svg>";
	@esc-svg-code: escape(@svg-code);

	background-image: url("data:image/svg+xml,@{esc-svg-code}");
}
