@editable-app-bar-height: @size-m;
@editable-app-bar-color-background: @color-white;
@editable-app-bar-padding: @spacing-xl;

/* ------------------------------------*\
		Components


		editable-app-bar consists of
			__nav-pane..........................Wrapper for navigation
				__name-editor.....................Name editor
					__text-field....................Text field in editable app bar
				__actions-pane.....................Wrapper for actions - status message undo button
					__tool..........................Wrapper for action item
			__utility-pane......................Wrapper for actions
				__tool..........................Wrapper for action item
			__separator............Separates bar from other components, goes right after the .editable-app-bar
\*------------------------------------ */

.editable-app-bar {
	display: flex;
	height: @editable-app-bar-height;
	min-height: @editable-app-bar-height;
	align-items: center;
	padding: 0 @editable-app-bar-padding;
	background-color: @editable-app-bar-color-background;
	transition: all @OLD_transition-250;
}


.editable-app-bar__utility-pane {
	display: flex;
	flex: 0 0 auto;
	align-items: center;
	justify-content: flex-end;
}


.editable-app-bar__nav-pane {
	position: relative;
	display: flex;
	overflow: hidden;
	height: 100%;
	flex: 1 1 auto;
	padding-right: @spacing-s;
}

.editable-app-bar__actions-pane {
	position: relative;
	display: flex;
	align-items: center;
	margin-left: @spacing-l;
}

.editable-app-bar_action {
	margin-right: @spacing-m;
}
