@canvas-inner-section-restricted-width: 1000px;
@canvas-sidebar-content-in-content-model-width: (30 * @grid-unit);
@canvas-sidebar-detached-scrollbars-right-spacing: @spacing-main-layout-right;
@canvas-sidebar-top-spacing: @spacing-main-layout-top;
@canvas-scrollbar-width: (4 * @grid-unit);
@canvas-inner-section-offset: (@size-navigation-bar-width + @canvas-scrollbar-width);
@canvas-inner-section-breakpoint: (@canvas-inner-section-restricted-width + @canvas-inner-section-offset);

.canvas {
	display: flex;
	min-width: 0;
	height: 100%;
	min-height: 0;
	flex-flow: column;
	align-items: stretch;

	.dialog & {
		position: relative;
	}
}

.canvas__wrapper {
	display: flex;
	width: 100%;
	// Safari needs min constraints to force nested flex to shrink properly
	min-width: 0;
	height: 100%;
	min-height: 0;
	flex: 1 1 auto;
	flex-flow: column;
	align-items: stretch;
	justify-content: stretch;
}

.canvas__navigation {
	display: flex;
	min-height: @notification-bar-height;
}

.canvas__workspace {
	position: relative;
	display: flex;
	min-height: 0;
	flex: 1 1 auto;
	align-items: stretch;

	@media print {
		overflow: initial;
	}

	&--in-modal {
		position: static;
	}

	&--detached-scrollbars {
		overflow-x: hidden;
		overflow-y: auto;
	}
}

.canvas__content {
	position: relative;
	display: flex;
	min-width: 0;
	min-height: 0;
	flex: 1 1 auto;
	flex-direction: column;

	@media print {
		overflow: auto;
	}

	.canvas__workspace--detached-scrollbars & {
		margin-right: (@size-sidebar-width + @canvas-sidebar-detached-scrollbars-right-spacing);
	}

	.canvas__workspace--in-entity-webhook & {
		width: 100%;
		margin-right: 0;
	}

	.canvas__workspace--in-custom-app & {
		width: 100%;
	}

	.canvas__workspace--in-content-model & {
		@content-model-sidebar-space: (@canvas-sidebar-content-in-content-model-width + @spacing-main-layout-right);

		.vertically-stretched();
		// Use explicit width based on viewport width to properly size in all screen resolutions
		// and to prevent changing of space between the content and sidebar when the scrollbar appears
		width: calc((100vw - @content-model-sidebar-space - @size-navigation-bar-width));
		margin-right: @content-model-sidebar-space;
	}
}

.canvas__content-pane {
	flex: 1 1 auto;
	padding: @spacing-main-layout-top @spacing-main-layout-right 0 @spacing-main-layout-left;
	overflow-x: hidden;
	overflow-y: auto;

	@media print {
		overflow: auto;
	}

	&--without-vertical-scrollbar {
		overflow-y: hidden;
	}

	&--in-modal {
		padding-left: @spacing-card;
	}

	& > *:last-child {
		// Firefox doesn't support bottom padding in scrolling containers properly, we need to use margin on the last element instead
		// See DRAFT-4304 for more details
		margin-bottom: @spacing-main-layout-bottom;
	}

	&--no-bottom-offset > *:last-child {
		margin-bottom: 0;
	}

	.canvas__workspace--detached-scrollbars & {
		overflow-x: visible;
		overflow-y: visible;
	}

	.canvas__workspace--in-content-model & {
		.vertically-stretched();
	}
}

.canvas__notifications {
	padding-right: @spacing-element-edge-horizontal;
	padding-left: @spacing-element-edge-horizontal;

	&:not(:empty) {
		padding-top: @spacing-element-edge-vertical;

		.content-item-filter + .canvas__content & {
			margin-top: @spacing-main-layout-top;
		}

		.content-item-filter--in-modal + .canvas__content & {
			padding-right: @spacing-l;
			margin-top: @spacing-l;
		}
	}
}

.canvas__inner-section {
	&--restricted-width {
		width: 100%;
		max-width: @canvas-inner-section-restricted-width;
	}

	/*
			The rules below are related to the FIX to avoid "content jumping" when scrollbar appears on the page.
			The inner section of the canvas has a restricted width of @canvas-inner-section-restricted-width pixels.
			So to avoid a "content jumping" problem we need to handle two situations:
				1) Viewport has enough width to show the content and the content is horizontally centered using margin: 0 auto.
				2) Viewport does not have enough space and the inner section content width is reduced.
		*/

	/*
		The 1st problem is handled using the padding-left rule to adjust the position of the canvas inner section by scrollbar width
		when scrollbar appears on the page (slightly move content to the right as if there was no scrollbar).
		To get the width of the sidebar we use the difference between 100 viewport width points (vw) and the sum of several other values
		including the 100% width of inner section, horizontal padding of the container and width of the application left sidebar.
		The trick is that when a scrollbar is present on the page, the difference between 100vw and the sum of all other values mentioned above
		will equal the width of the scrollbar, because 100% width of the element does not include the scrollbar. But when scrollbar is not
		visible, the difference will equal 0.

		The 'box-sizing' rule must be set to 'content-box' because otherwise padding will be included into the max-width of the section.

		!IMPORTANT!
		When this breaks, just remove all the hacks and replace overflow-y: auto with overflow-y: scroll for .canvas__content-pane.
	*/

	&--centered {
		box-sizing: content-box;
		padding-left: calc((100vw - 100% - @canvas-inner-section-offset));
		margin: 0 auto;

		@media only screen and (max-width: @canvas-inner-section-breakpoint) {
			padding-left: 0;
		}
	}
}

.canvas__notification-pane {
	z-index: @z-index-six-hundred;

	&--is-over-another {
		z-index: @z-index-seven-hundred;
	}
}

.canvas__sidebar {
	display: flex;
	max-height: 100%;
	flex: 0 0 auto;
	flex-flow: column;

	&--default-skin {
		z-index: @z-index-one;
		height: 100%;
		border-left: @border-width-default solid @color-border-low-emphasis;
		background-color: @color-white;
	}

	.canvas__workspace--detached-scrollbars & {
		position: fixed;
		right: 0;
		height: 100%;
		max-height: calc((100vh - @status-bar-height - @canvas-sidebar-top-spacing));
		margin-top: @canvas-sidebar-top-spacing;
		margin-right: @spacing-xl;
	}

	.canvas--detached-scrollbars & {
		position: absolute;
		top: @canvas-sidebar-top-spacing;
		right: @canvas-sidebar-detached-scrollbars-right-spacing;
		bottom: 0;
		max-height: calc((100% - @canvas-sidebar-top-spacing));
	}

	.canvas__workspace--in-content-model & {
		z-index: @z-index-two;
	}

	@media print {
		display: none;
	}
}

.canvas__sidebar-content {
	width: @size-sidebar-width;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;

	&--in-content-models {
		width: @canvas-sidebar-content-in-content-model-width;
		margin-bottom: @spacing-element-edge-vertical;
	}
}

.canvas__content .canvas__window {
	position: absolute;
	width: 100%;
	height: 100%;
	border: none;
}
