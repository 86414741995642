@form-text-field-vertical-padding: ((@size-input-height - @line-height-l - (2 * @border-width-input)) / 2);

.placeholder(@color: @color-text-hint) {
	color: @color;
	opacity: 1; // Override Firefox´s unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
	-webkit-text-fill-color: @color;
}

.form-text-field() {
	.typography-styles-body-medium();

	display: flex;
	width: 100%;
	min-width: 0;
	height: @size-input-height;
	padding: @form-text-field-vertical-padding @spacing-input-large;
	border: @border-width-input solid @color-input-border;
	border-radius: @border-radius-input;
	background-color: @color-input-background;
	color: @color-text-default-on-light;
	resize: vertical;
	transition: border @OLD_transition-250;

	&::placeholder {
		.placeholder();
	}
}

.form-text-field-focus() {
	border-color: @color-border-active;
	color: @color-text-default-on-light;
	outline: none;
}

.form-text-field-readonly() {
	border-color: @color-border-disabled;
	background-color: @color-background-disabled;
	color: @color-text-default-on-light;
	cursor: text;
	text-overflow: ellipsis;

	&:focus {
		border-color: @color-border-disabled;
	}
}
