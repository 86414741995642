.dismissible-popover {
	display: flex;
	justify-content: space-between;
}

.dismissible-popover__title {
	.typography-styles-headline-medium();

	margin-bottom: @spacing-s;
	color: @color-text-default-on-dark;
}

.dismissible-popover__button {
	margin-left: @spacing-l;

	.btn--quinary {
		color: @color-text-default-on-dark;

		&:hover {
			background-color: rgb(@color-white 0.16);
		}
	}
}
