.web-spotlight__tabs {
	grid-area: tabs;
}

.web-spotlight__tab-switch {
	margin-left: @spacing-between-buttons-horizontal;
}

.web-spotlight__tabbed-navigation {
	padding-right: @spacing-main-layout-right;

	.tabbed-navigation__tabs {
		align-items: center;
	}

	.tabbed-navigation__tab {
		min-width: (11 * @grid-unit);
		margin-top: 0;

		&:focus {
			z-index: @z-index-one;
			text-decoration: none;
			.shadow-focus-styles();
		}

		& .tabbed-navigation__tab-content {
			margin: 0 auto;
		}
	}
}

.web-spotlight__preview-api-last-changes-status {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: @spacing-m;
}
