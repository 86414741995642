@rte-table-border-color: @color-border-default;
@rte-table-cell-border-width: 1px;
@rte-table-cell-padding: @spacing-m;

.rte__table {
	margin-left: @rte-sleeve-caret-block-width;
	border-collapse: collapse;
	vertical-align: bottom;
	word-break: normal;

	&--reached-max-width {
		word-break: break-word;
	}

	.rte__table-table {
		display: table;
		width: 100%;
		height: 100%;
	}
}

.rte__table-row {
	display: table-row;
	height: 100%;
	border-top: @rte-table-cell-border-width solid @rte-table-border-color;
	border-right: @rte-table-cell-border-width solid @rte-table-border-color;
	border-bottom: @rte-table-cell-border-width solid @rte-table-border-color;
}

.rte__table-cell {
	position: relative;
	display: table-cell;
	border-left: @rte-table-cell-border-width solid @rte-table-border-color;
	vertical-align: top;

	.rte__block {
		&--is-first {
			padding-top: @rte-table-cell-padding;
		}

		&--is-last {
			padding-bottom: @rte-table-cell-padding;
		}
	}

	.rte__text-block {
		padding-right: @rte-table-cell-padding;
		padding-left: @rte-table-cell-padding;

		&--is-empty.rte__block--is-first.rte__block--is-last {
			height: 100%;
		}
	}

	.rte__content-image {
		max-width: calc((100% - (@rte-sleeve-caret-block-width * 2)));
		padding-right: @rte-table-cell-padding;
		padding-left: @rte-table-cell-padding;
	}

	.rte__custom-block-sleeve--is-before div {
		position: relative;
		left: @rte-table-cell-padding - @rte-sleeve-caret-block-width;
	}

	.rte__custom-block-sleeve--is-after div {
		position: relative;
		left: -@rte-table-cell-padding + @rte-sleeve-caret-block-width;
		padding-right: @rte-table-cell-padding - @rte-sleeve-caret-block-width;
	}
}

// Firefox allows placing caret between the table cell content and cell resizer with down arrow, but that is invalid DOM selection in terms of editor context
// We prevent this by using this extra container leveraging the fact that Firefox doesn't handle flex layout correctly in contenteditable
// See KCL-4313 for more details
.rte__table-cell-content-wrapper {
	display: flex;
	width: 100%;
	height: 100%;
}

.rte__table-cell-content {
	width: 100%;
	height: 100%;
	// We need to cut the overflow so that the drop extension area after an image
	// doesn't overlay the cell to the left and intercept with its mouse actions.
	// We can't use overflow: hidden as it causes unwanted scrolling of the table cell content.
	// Detailed explanation with visuals: https://kontent-ai.atlassian.net/browse/KCL-9133?focusedCommentId=532568
	clip-path: inset(0);
}

.rte__table-cell-resizer {
	position: absolute;
	z-index: 2;
	// We need to place the resizer above its siblings to properly overlay them
	top: 0;
	right: (@rte-table-cell-padding * -0.5);
	width: (@rte-table-cell-padding + @rte-table-cell-border-width);
	height: 100%;
	user-select: none;

	*::selection {
		background-color: transparent;
	}

	&--is-enabled {
		cursor: col-resize;
	}

	// Hide the resizer in case drag is in progress to not interfere with dragging files which need continuous drop area
	.rte--is-dragging & {
		display: none;
	}
}

.rte__table-menu {
	position: absolute;
}
