.rating-radio-button {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&:has(input.focus-visible) &__label {
		.shadow-focus-styles();
	}

	&__description {
		cursor: pointer;
	}

	&__label {
		width: 13 * @grid-unit;
		height: 11 * @grid-unit;
		padding: @spacing-xl 0;
		border-radius: @border-radius-m;
		background-color: @color-background;
		cursor: pointer;

		&:hover {
			background-color: @color-background-hover;
		}

		&__text {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
}
