.mask-modal {
	position: fixed;
	z-index: @z-index-eight-hundred;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	background-color: @color-overlay-background;

	&--over-all-other-elements {
		z-index: @z-index-ten-thousand;
	}

	&--transparent {
		background: transparent;
	}
}
