@content-group-selection-max-width: (30 * @grid-unit);

.content-group-pane {
	z-index: @z-index-one;
}

.content-group-selection-bar {
	.border-low-emphasis();

	display: flex;
	max-width: 100%;
	flex: 1;
	align-items: center;
	justify-content: flex-start;
	padding: @spacing-s;
	border-radius: 0 0 @border-radius-l @border-radius-l;
	border-top: 0;
	background-color: @color-white;
}

.content-group-selection-bar__element-count {
	.typography-styles-subheadline-large();

	margin: @spacing-l;
	color: @color-text-hint;

	&--selected {
		color: @color-primary;
	}
}

.content-group-selection-bar__actions {
	max-width: @content-group-selection-max-width;
}
