@import "../mixins/component-loading-indicator.less";

.bar-item__loading-indicator-pane {
	width: 100%;
	padding: @spacing-xs;
}

.bar-item__loading-indicator {
	.component__loading-indicator();
}
