//
// Grid system
// --------------------------------------------------

// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container {
	.container-fixed();

	@media (min-width: @OLD_screen-sm-min) {
		width: @OLD_container-sm;
	}

	@media (min-width: @OLD_screen-md-min) {
		width: @OLD_container-md;
	}

	@media (min-width: @OLD_screen-lg-min) {
		width: @OLD_container-lg;
	}
}


// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

.container-fluid {
	.container-fixed();
}


// Row
//
// Rows contain and clear the floats of your columns.

.row {
	.make-row();

	display: flex;
	flex-wrap: wrap;
}


.row + .row {
	margin-top: @OLD_grid-gutter-height;
}


// Columns
//
// Common styles for small and large grid columns

.make-grid-columns();

// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

.make-grid(xs);

// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.

@media (min-width: @OLD_screen-sm-min) {
	.make-grid(sm);
}


// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

@media (min-width: @OLD_screen-md-min) {
	.make-grid(md);
}


// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width: @OLD_screen-lg-min) {
	.make-grid(lg);
}
