.drag-placeholder() {
	border-radius: @border-radius-m;
	background-color: @color-background-disabled;
	background-image: none;
	box-shadow: @box-shadow-xs;

	> * {
		opacity: 0;
	}
}

.cursor-draggable() {
	&:not([disabled]):not(.disabled) {
		cursor: grab;

		&:active {
			cursor: grabbing;
		}
	}
}


