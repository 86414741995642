@right-nav-bg-color: @color-white;
@right-nav-text-color: @color-text-default-on-light;

.right-nav {
	position: fixed;
	z-index: @z-index-nine-hundred;
	top: 0;
	right: 0;
	bottom: 0;
	width: @size-sidebar-width;
	padding: @spacing-l 0;
	border-radius: @spacing-s 0 0 @spacing-s;
	background-color: @right-nav-bg-color;
	box-shadow: @box-shadow-l;
	overflow-x: hidden;
}

.right-nav__top-title {
	.typography-styles-headline-large();

	padding-top: @spacing-s;
	color: @right-nav-text-color;
}

.right-nav__section {
	padding: 0 @spacing-l @spacing-l @spacing-l;
}

.right-nav__header {
	.typography-styles-headline-small();

	margin-top: @spacing-xl;
	margin-bottom: @spacing-element-label;
	color: @right-nav-text-color;
}

.right-nav__title {
	.typography-styles-headline-medium();

	padding: 0 @spacing-l;
	margin-top: 0;
	margin-bottom: @spacing-s;
	color: @right-nav-text-color;
}

.right-nav__content {
	.typography-styles-ui-paragraph();

	margin-bottom: @spacing-l;
	color: @right-nav-text-color;

	&--is-empty {
		margin-bottom: @spacing-xl;
	}

	&--is-loading {
		text-align: center;
	}
}

.right-nav__divider {
	margin-bottom: @spacing-l;
}

.right-nav__filter {
	margin-bottom: @spacing-l;

	& > .form-control {
		.typography-styles-body-medium();

		border-color: @color-input-border;
		background-color: @right-nav-bg-color;
		color: @right-nav-text-color;

		&:focus,
		&:hover {
			border-color: @color-border-hover;
		}
	}
}

.right-nav__dropdown {
	&:hover:not(.combo-box--is-disabled) {
		border-color: @color-border-hover;
	}

	&--has-error,
	&--has-error:focus,
	&--has-error:hover {
		border-color: @color-border-alert;
	}
}

// Vertical rhythm
.right-nav__title + .right-nav__content,
.right-nav__content p + a {
	margin-top: @spacing-l;
}
