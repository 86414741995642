/* ------------------------------------*\
		Components

		content-items-search-area
			__search-phrase.........................Search phrase: text
			__search-scope..........................Searching scope: Content, Names..
\*------------------------------------ */

.content-items-search-area {
	display: flex;
	margin-bottom: @spacing-l;
}

.content-items-search-area__search-phrase {
	min-width: (38 * @grid-unit);
	flex-grow: 1;
}

.content-items-search-area__search-scope {
	width: (260px + @spacing-s);
	min-width: 0;
	padding-left: @spacing-s;
}
