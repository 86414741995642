/* ------------------------------------*\
	Components

	.modal-dialog consists of
		__pane.................Holds dialog
		__header...............Wraps the first line of dialog
			__header-title.....Wraps context title
		__content..............Wraps paragraphs with details
		__footer...............Wraps main actions
\*------------------------------------ */

.modal-dialog {
	z-index: @z-index-nine-hundred;
	display: flex;
	width: 100%;
	min-height: calc((100% - @spacing-element-edge-vertical));
	align-items: center;
	justify-content: center;
	animation: 250ms slide-down;

	&--full-height {
		align-items: stretch;
	}

	&--with-fixed-top {
		min-height: calc((100% - 10vh - @spacing-element-edge-vertical));
		align-items: baseline;
		margin-top: 5vh;
	}
}

@keyframes slide-down {
	from {
		opacity: 0;
		transform: translateY(-2%);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.modal-dialog__pane {
	position: relative;
	display: flex;
	min-width: 100px;
	min-height: 100px;
	max-height: 100%;
	flex-direction: column;
	flex-shrink: 1;
	border-radius: @border-radius-l;
	background: @color-white;
	box-shadow: @box-shadow-l;

	&:last-child {
		padding-bottom: @spacing-card;
	}
}

.modal-dialog__header {
	position: relative;
	display: flex;
	width: 100%;
	max-width: 100%;
	flex-shrink: 0;
	padding: @spacing-card;

	.modal-dialog--with-dividers & {
		border-bottom: @border-width-default solid @color-border-low-emphasis;
	}

	.confirmation-dialog & {
		max-width: (@confirmation-dialog-width + (2 * @spacing-card));
	}
}

.modal-dialog__header-title {
	.typography-card-headline();

	display: -webkit-box;
	overflow: hidden;
	flex-grow: 1;
	-webkit-box-orient: vertical;
	color: @color-text-default-on-light;
	-webkit-line-clamp: 3;
	word-break: break-word;
}

.modal-dialog__content {
	.typography-styles-label-large();

	display: flex;
	min-height: 0;
	flex-grow: 1;
	flex-shrink: 1;
	padding: 0 @spacing-card;
	background-color: @color-white;
	color: @color-text-default-on-light;

	&--with-dividers {
		padding: @spacing-card @spacing-l 0 @spacing-l;
	}

	&--with-full-width {
		padding: 0;
	}
}

.modal-dialog__scroll-table-wrapper {
	display: flex;
	width: 100%;
	flex-direction: column;

	.scroll-table__body .scroll-table__row {
		padding-right: 0;
		border-bottom: 0;
	}
}

.modal-dialog__footer {
	display: flex;
	flex: 0 0 auto;
	justify-content: space-between;
	padding: @spacing-card @spacing-card 0 @spacing-card;

	.modal-dialog--with-dividers & {
		border-top: @border-width-default solid @color-border-low-emphasis;
	}
}

.modal-dialog__footer-content {
	display: flex;
	align-items: center;
	gap: @spacing-between-buttons-horizontal;

	& + & {
		margin-left: @spacing-between-buttons-horizontal;
	}
}

.modal-dialog__actions-pane-left {
	padding-right: (20 * @grid-unit);
}

/* ----------------------------------------------------------------------------------------------------------*\
		#modal__section
\*---------------------------------------------------------------------------------------------------------- */

.modal__section {
	position: relative;
	word-wrap: break-word;

	& + & {
		margin-top: @spacing-card;
	}
}

.modal__section-title {
	.typography-styles-headline-small();

	margin-bottom: @spacing-s;
	color: @color-text-default-on-light;
}
