.content-component {
	position: relative;
	display: block;
	margin-left: @rte-sleeve-caret-block-width;
	line-height: 0;

	&::before {
		position: absolute;
		border-radius: @border-radius-bar-item;
		border-top-color: transparent;
		background: @color-background;
		content: "";
		inset: 0;
		pointer-events: none;
		transition: background-color @transition-250;

		.border-low-emphasis();
	}

	&--is-dragging {
		.drag-placeholder();

		padding-bottom: 0;
		border: 0;
	}

	&--is-disabled {
		padding-bottom: 0;
	}

	&:not(&--is-top-level) {
		width: calc((100% + @rte-linked-item-extra-space - @rte-sleeve-caret-block-width));

		.content-item-pane--is-small &,
		.floating-editor & {
			width: calc((100% + @rte-linked-item-extra-space-small - @rte-sleeve-caret-block-width));
		}
	}

	&--is-collapsed {
		padding-bottom: 0;
	}

	&--is-not-allowed {
		.forbidden-object();
	}
}

.content-component__content {
	overflow: hidden;
	border-radius: 0 0 @border-radius-bar-item @border-radius-bar-item;

	.content-item-element__wrapper--is-disabled {
		&::before {
			background-color: @color-background-element-disabled;
		}
	}

	.rte--has-focus &--is-highlighted {
		.content-item-guidelines,
		.content-item-element__wrapper,
		.content-group-tab,
		.bar-item__bar,
		.content-component,
		.asset-thumbnail__actions-pane,
		.asset-thumbnail__bottom {
			background: @color-background-block-selection;
		}
	}
}

.expanded-content-component {
	overflow: hidden;
	border-radius: 0 0 @border-radius-bar-item @border-radius-bar-item;

	.content-item-element--is-disabled {
		background-color: @color-background-element-disabled;
	}
}
