@loader-wrapper-height: 42vh;
@loader-width: 200px;
@loader-height: (0.5 * @grid-unit);
@loader-animation-edge: (@loader-width - @loader-height);

@loader-bg-color: @color-gray30;
@loader-bubble-bg-color: @color-primary;


.loader__wrapper {
	display: flex;
	min-height: @loader-wrapper-height;
	max-height: @loader-wrapper-height;
	flex-direction: column;
	flex-grow: 1;
	align-items: center;
	justify-content: center;

	&--narrow {
		min-height: @scroll-table-row-min-height;
	}

	&--minimum {
		min-height: auto;
	}

	&--sidebar {
		min-height: auto;
		margin: @spacing-xxl 0;
	}
}


.loader {
	overflow: hidden;
	width: @loader-width;
	height: @loader-height;
	border-radius: @border-radius-m;
	animation: loader 6.2s ease;
	background-color: @loader-bg-color;
	transform-origin: 50% 100%;
}

.loader__image {
	max-width: 200px;
	margin-bottom: 20px;
}

.loader__text {
	.typography-styles-ui-paragraph();

	margin-bottom: @spacing-xl;
	color: @color-text-low-emphasis;
}

.loader__bubble {
	width: @loader-width;
	height: @loader-height;
	border-radius: @border-radius-l;
	animation: bubble 1.7s ease infinite;
	animation-delay: 550ms;
	background-color: @loader-bubble-bg-color;
	transform-origin: 0% 100%;
}


@keyframes bubble {
	0% {
		transform: translate((@loader-animation-edge * -1), 0);
	}

	50% {
		transform: translate(@loader-animation-edge, 0);
	}

	100% {
		transform: translate((@loader-animation-edge * -1), 0);
	}
}


@keyframes loader {
	0% {
		opacity: 0;
		transform: translate(0, 0) scale(0.01, 1);
	}

	10% {
		opacity: 0.2;
		transform: translate(0, 0) scale(0.01, 1);
	}

	14% {
		opacity: 1;
		transform: translate(0, 0) scale(1, 1);
	}

	100% {
		opacity: 1;
		transform: translate(0, 0) scale(1, 1);
	}
}
