//
// Really simple "flexbox grid"
// --------------------------------------------------
// We can extend it in the future to our needs when we will need to use it anywhere else (now it's used only on the "API keys" page) - e.g.
// we can rewrite it to real flexbox grid (inspiration e.g. in Bootstrap´s 4 Grid system which is using flexbox or Foundation 6 Flex Grid)
// or any other way to fit our needs ...
.flex-grid {
	&__pane {
		display: flex;
		max-width: 1344px;
		flex-flow: row wrap;
		align-items: stretch;
		justify-content: flex-start;
		margin-right: (@spacing-card * -1);
		margin-bottom: (@spacing-card * -1);

		&--has-wide-item {
			max-width: 1000px;
		}
	}

	&__item {
		display: flex;
		flex: 0 0 33.333%;
		padding-right: @spacing-xl;
		padding-bottom: @spacing-xl;

		@media (max-width: @OLD_screen-md-max) {
			flex: 0 0 50%;
		}

		@media (max-width: @OLD_screen-sm-max) {
			flex: 0 0 100%;
		}
	}
}
