@rte-sleeve-caret-block-width: 1px;
@rte-sleeve-block-width: @spacing-s;
@rte-sleeve-block-extra-space: (@content-item-element-horizontal-padding - @rte-sleeve-block-width);
@rte-sleeve-block-extra-space-small: (@content-item-element-horizontal-padding-small - @rte-sleeve-block-width);

// This ratio is calculated from observations of the current font to properly calculate font size for proper caret size
@caret-to-font-size-ratio: 0.85;

@rte-sleeve-image-line-height: @asset-thumbnail-height;
@rte-sleeve-image-font-size: ((@rte-sleeve-image-line-height - (2 * @asset-thumbnail-border-radius)) * @caret-to-font-size-ratio);

@rte-sleeve-bar-item-line-height: @size-bar-item-bar;
@rte-sleeve-bar-item-font-size: ((@rte-sleeve-bar-item-line-height - (2 * @border-radius-bar-item)) * @caret-to-font-size-ratio);

@rte-sleeve-table-line-height: (@rte-empty-paragraph-size + (2 * @rte-table-cell-padding));
@rte-sleeve-table-font-size: (@rte-sleeve-table-line-height * @caret-to-font-size-ratio);

@drop-extension-area-height: @spacing-s;

// Caret before is consistent for all custom blocks and placed inline just before each of them
.caret-before() {
	// The main element spans full width of the editor but this area doesn't contain the caret yet
	overflow: visible;

	// The caret place sticks out at the left side bottom to be visually placed at the left top of the custom block
	div {
		width: 1px;
		padding-right: 1px; // Make sure caret does not get anti-aliased out in Safari
		padding-left: @content-item-element-horizontal-padding; // Allows placing caret in of rte block which starts on the left side
		margin-left: (@content-item-element-horizontal-padding * -1);
	}
}

// Inline blocks (images, tables not reaching full width) have the caret after positioned just within the normal element flow
.inline-block-caret-after() {
	width: @rte-sleeve-caret-block-width;
	vertical-align: top;

	div {
		width: @rte-sleeve-caret-block-width;
	}
}

// Full width blocks have the caret place at their end taking just the last pixels of space to prevent line break
.full-width-block-caret-after() {
	div {
		// We can't use absolute position here, because FF allows them to be moved within contenteditable and it can't be disabled
		// https://bugzilla.mozilla.org/show_bug.cgi?id=462758
		position: relative;
		padding-right: 1px; // Make sure caret does not get anti-aliased out in Safari
		padding-left: (@rte-sleeve-block-width - @rte-sleeve-caret-block-width);
		margin-left: calc((100% - @rte-sleeve-block-width));
		cursor: text;
		font-size: @rte-sleeve-bar-item-font-size;
		line-height: @rte-sleeve-bar-item-line-height;
		transform: translateY((@size-bar-item-bar * -1));

		/* stylelint-disable-next-line selector-max-compound-selectors */
		.rte .rte & {
			margin-left: calc((100% + @rte-sleeve-block-extra-space));

			/* stylelint-disable-next-line selector-max-compound-selectors */
			.content-item-pane--is-small &,
			.floating-editor & {
				margin-left: calc((100% + @rte-sleeve-block-extra-space-small));
			}
		}
	}
}

// Full width table needs to adjust its original full-width block caret (which is mostly OK), but it needs to fit slightly differently to the layout
.full-width-table-caret-after-adjustments() {
	div {
		padding-right: @content-item-element-horizontal-padding;
		padding-left: 0;
		margin-left: calc((100% - @rte-sleeve-caret-block-width));
		transform: translateY(((@rte-sleeve-table-line-height * -1) + @border-width-default));

		/* stylelint-disable-next-line selector-max-compound-selectors */
		.content-item-pane--is-small &,
		.floating-editor & {
			padding-right: @content-item-element-horizontal-padding-small;
		}
	}
}

// Bar item custom blocks have an extra hover visual which extends the cursor place to get more visible when the mouse is over it
.absolute-bar-item-block-caret-after-hover-overlay() {
	div {
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			width: (@rte-sleeve-block-width + @rte-sleeve-caret-block-width);
			height: @size-bar-item-bar;
			border-bottom-right-radius: @border-radius-bar-item;
			border-top-right-radius: @border-radius-bar-item;
			content: "";
			pointer-events: none;
		}

		&:hover::before {
			background-clip: padding-box;
			background-color: @color-background-hover;
		}

		.rte__content--is-disabled &::before {
			background-color: transparent;
		}
	}
}

// Bar item custom blocks have a hover overlay over the whole bar item when the caret place after them is hovered
.absolute-bar-item-block-caret-after-full-width-hover-overlay() {
	&::before {
		position: absolute;
		z-index: @z-index-one;
		bottom: 100%;
		width: 100%;
		height: @size-bar-item-bar;
		border-radius: @border-radius-bar-item;
		margin-left: @rte-sleeve-caret-block-width;
		content: "";
		pointer-events: none;
		transition: background-color @OLD_transition-150;

		/* stylelint-disable-next-line selector-max-compound-selectors */
		.rte .rte & {
			width: calc((100% + @content-item-element-horizontal-padding));
		}
	}

	&:hover::before {
		background-color: @color-background-hover;

		.rte__content--is-disabled & {
			background-color: transparent;
		}
	}
}

.rte-block-drop-extension-area(@width: 100%) {
	position: absolute;
	z-index: @z-index-one;
	right: 0;
	width: @width;
	height: @drop-extension-area-height;
	content: "";
}

// When dropping external files, we need a full-width area under the custom block to capture the drag events
// we provide an extra sleeve extension under the custom block to achieve that
// this is especially important when the custom block is at the end of the editor as there is no next block that could capture the drag events
// it is positioned absolutely to not interfere with the editor layout and interaction but still capturing mouse events
.absolute-sleeve-after-full-width-drop-extension-area(@width: 100%) {
	&::after {
		.rte-block-drop-extension-area(@width);

		bottom: (@drop-extension-area-height * -1);

		/* stylelint-disable-next-line selector-max-compound-selectors */
		.rte .rte & {
			width: calc((100% + @content-item-element-horizontal-padding));
		}
	}
}

.absolute-sleeve-before-full-width-drop-extension-area() {
	&::after {
		.rte-block-drop-extension-area();

		top: (@drop-extension-area-height * -1);

		/* stylelint-disable-next-line selector-max-compound-selectors */
		.rte .rte & {
			width: calc((100% + @content-item-element-horizontal-padding));
		}
	}
}

.rte__custom-block-sleeve {
	&--is-before,
	&--is-after {
		div {
			// By using translate, we give the element its own stacking context, making sure it displays above its owner even when it is placed in DOM before it
			transform: translateX(0);
		}
	}

	&--is-after {
		position: relative;
		z-index: @z-index-two;
		transform: translateX(0);
	}

	&--is-before {
		.caret-before();
	}

	&--is-before.rte__block--is-first {
		position: relative;

		.absolute-sleeve-before-full-width-drop-extension-area();

		.rte__table-cell &::after {
			top: 0;
			height: @rte-table-cell-padding;
		}
	}

	&--is-before:not(.rte__next--is-image):not(.rte__next--is-table-cell) {
		font-size: @rte-sleeve-bar-item-font-size;
		line-height: @rte-sleeve-bar-item-line-height;
	}

	&.rte__next--is-image > div {
		font-size: @rte-sleeve-image-font-size;
		line-height: @rte-sleeve-image-line-height;
	}

	&.rte__next--is-table-cell > div {
		padding-top: @border-width-default;
		font-size: @rte-sleeve-table-font-size;
		line-height: @rte-sleeve-table-line-height;
	}

	&--is-after:not(.rte__previous--is-image):not(.rte__previous--is-table-cell) {
		.full-width-block-caret-after();
		.absolute-bar-item-block-caret-after-hover-overlay();
		.absolute-bar-item-block-caret-after-full-width-hover-overlay();

		&.rte__block--is-last {
			position: relative;

			.absolute-sleeve-after-full-width-drop-extension-area();
		}
	}

	&.rte__previous--is-image {
		.inline-block-caret-after();

		> div {
			// Content takes the rest of the space right from content-image in order to provide correct mouse area response
			// Caret sized and positioned to the full right edge of content-image
			font-size: @rte-sleeve-image-font-size;
			line-height: @rte-sleeve-image-line-height;
		}
	}

	.rte__table:not(.rte__table--reached-max-width) + & {
		.inline-block-caret-after();

		align-self: flex-end;

		// Table has the caret after positioned at the bottom as we are unable to match its size to the table height so we align it to the bottom of the table
		vertical-align: bottom;
	}

	&--is-after.rte__block--is-last.rte__previous--is-image,
	.rte__table:not(.rte__table--reached-max-width) + &--is-after.rte__block--is-last {
		position: relative;

		.absolute-sleeve-after-full-width-drop-extension-area(100vw);

		.rte__table-cell &::after {
			bottom: 0;
			height: @rte-table-cell-padding;
		}
	}

	.rte__table--reached-max-width + &--is-after {
		position: relative;

		.full-width-block-caret-after();
		.full-width-table-caret-after-adjustments();
		.absolute-sleeve-after-full-width-drop-extension-area();
	}

	&.rte__previous--is-table-cell > div {
		// Content takes the rest of the space right from the table in order to provide correct mouse area response
		// Caret is sized and positioned to the full right edge of last table row as this is the minimum known height
		font-size: @rte-sleeve-table-font-size;
		line-height: @rte-sleeve-table-line-height;
	}
}
