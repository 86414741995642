/* ------------------------------------*\
    Component variables
\*------------------------------------ */

@webhook-message-listing--dialog-width: (80 * @grid-unit);

.webhook-message-listing__filter {
	position: relative;
	display: flex;
	overflow: hidden;
	align-items: center;
}

.webhook-message-listing__dropdown-wrapper {
	float: right;
}

.webhook-message-listing__table-cell {
	&--actions {
		overflow: visible;
	}

	&--message-content {
		.data-table__cell-item-content,
		.data-table__column-name {
			justify-content: flex-end;
		}
	}
}

.webhook-message-listing__status {
	display: flex;
	min-width: 0;
	align-items: center;
}

.webhook-message-listing__tag {
	overflow: unset;

	& + .webhook-message-listing__text {
		margin-left: @spacing-s;
	}
}

.webhook-message-listing__action-item {
	position: relative;
	display: flex;
	align-items: center;
	padding: @spacing-xs;
	border-radius: @border-radius-s;
	color: @color-icon-default;
	cursor: pointer;

	&:hover,
	&:focus {
		background-color: @color-background-hover;
	}

	&--is-active {
		background-color: @color-background-selected;

		&:hover,
		&:focus {
			background-color: @color-background-selected;
		}
	}

	.webhook-message-listing__status + & {
		margin-left: @spacing-s;
	}
}

.webhook-message-listing__dialog {
	position: absolute;
	top: @btn-quinary-size;
	display: flex;
	width: @webhook-message-listing--dialog-width;
	flex-direction: column;
	cursor: initial;
	text-align: left;

	&--position-right {
		left: (@popover-arrow-offset * -1);
	}

	&--position-left {
		left: ((@webhook-message-listing--dialog-width * -1) + @popover-arrow-offset + @popover-arrow-size);
	}
}

.webhook-message-listing__dialog-body {
	position: relative;
}
