/* ----------------------------------------------------------------------------------------------------------*\
		Components

		codename-editor consists of
				__snippet-prefix?.............................Snippet prefix of snippet elements in codename edit popover
				__warning?....................................Friendly warning
				__action-button-pane..........................Codename editor buttons


\*---------------------------------------------------------------------------------------------------------- */

.codename-editor__action-button-pane {
	display: flex;
	justify-content: flex-end;
	margin-top: @spacing-l;
}

.codename-editor__snippet-prefix {
	overflow: hidden;
	text-overflow: ellipsis;
}
