@rte-padding: (@size-m / 2);
@rte-border-radius: @border-radius-input;

@rte-content-border-color: @color-input-border;
@rte-content-focus-border-color: @color-border-active;
@rte-content-error-color: @color-border-alert;
@rte-content-disabled-bg-color: @color-background-disabled;
@rte-content-placeholder-color: @color-text-hint;

.rte {
	// We need this so that the content in ContentOverlay does not overflow the scrollable area in which the editor is placed
	// And also to make sure that all elements absolutely positioned to the editor have proper positioning offset to parent
	// If this is missing, and editor is placed into absolutely positioned parent, the above problems may occur
	position: relative;

	// Transparent overlay to disallow actions over locked editor
	&--is-locked {
		&:not(.rte--is-dragging) {
			&::after {
				position: absolute;
				z-index: @z-index-six-hundred;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-color: transparent;
				content: "";
			}
		}
	}
}

.rte__content {
	.typography-styles-body-large();

	position: relative;
	width: 100%;
	border: @border-width-default solid @rte-content-border-color;
	border-radius: @border-radius-input;
	background-color: @color-input-background;
	color: @color-text-default-on-light;
	outline: none;

	.public-DraftEditor-content {
		padding: @rte-padding;

		.content-element__value--in-guidelines-element & {
			padding: @spacing-xs @spacing-s;
		}
	}

	.content-element__value--in-guidelines-element & {
		border: @border-width-default solid @color-transparent;
		border-radius: @border-radius-s;
		background-color: @content-element-guidelines-text-input-bg-color;

		&::placeholder {
			.placeholder();
		}

		&:hover:not(.rte__content--has-focus) {
			background-color: @content-element-guidelines-text-input-hover-bg-color;
		}
	}

	// We prevent content immediately after the editor to display inline by adding full-width block element to the end of the content
	// to properly finalize the editor layout in case it ends with inline blocks (image or non-full-width table)
	&::after {
		display: block;
		height: 0;
		content: "";
	}

	&--has-focus {
		border: @border-width-default solid @rte-content-focus-border-color;
		transition: all @OLD_transition-250;

		.content-element__value--in-guidelines-element & {
			border: @border-width-default solid @content-item-element-focus-border-color;
		}
	}

	&--has-error {
		border: @border-width-default solid @rte-content-error-color;
		color: @rte-content-error-color;
	}

	&--is-disabled {
		background-color: @rte-content-disabled-bg-color;
		color: @color-text-default-on-light;
	}

	&--is-disabled&--is-disabled-in-comment {
		color: @color-text-disabled;
	}

	.rte__link {
		cursor: text;
		text-decoration: underline;

		&--is-clickable {
			cursor: pointer;
		}

		&--is-broken {
			color: @color-alert-text;

			&:hover {
				color: @color-alert-hover;
			}
		}

		&--is-edited {
			background-color: @color-background-text-selection;
		}

		&--type-is-not-allowed {
			.forbidden-background();
		}
	}
}

/**
 * Overload of default rte placeholder class
 */
.public-DraftEditorPlaceholder-root {
	position: absolute;
	z-index: 0;
	padding: @rte-padding;
	color: @color-text-hint;
	font-size: @rte-text-base-font-size;
	// Placeholder is placed out of the contenteditable so we need to prevent clicks on it which could cause blur to the editor itself
	pointer-events: none;
	-webkit-text-fill-color: @color-text-hint;

	.content-element__value--in-guidelines-element & {
		padding: @spacing-xs @spacing-s;
	}

	//Ensure that element placeholder text in disabled RTE could be longer than one line
	.rte__content--is-disabled & {
		position: relative;

		& + .DraftEditor-editorContainer {
			display: none;
		}
	}
}

.public-DraftEditorPlaceholder-inner {
	color: @rte-content-placeholder-color;
	-webkit-text-fill-color: @rte-content-placeholder-color;
}

.public-DraftEditorPlaceholder-hasFocus {
	color: @color-text-hint;
}

.DraftEditorPlaceholder-hidden {
	display: none;
}
