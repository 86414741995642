@primary-button-shadow: 0 8px 14px 2px rgba(@color-primary, 0.14), 0 6px 20px 5px rgba(@color-primary, 0.12), 0 8px 10px -5px rgba(@color-primary, 0.2);
@not-focus-visible: ~":not(.focus-visible)";

.shadow-focus-styles(@base-shadow: 0 0) {
	@inner-shadow-focus: inset 0 0 3px 0 @color-focus;
	@outer-shadow-focus: 0 0 0 @width-focus @color-focus;

	box-shadow: @outer-shadow-focus, @inner-shadow-focus, @base-shadow;

	.js-focus-visible &:focus@{not-focus-visible} {
		box-shadow: @base-shadow;
	}
}

/* use only in cases where overflow: hidden is unavoidable */
.shadow-focus-styles-inner-only(@base-shadow: 0 0) {
	@inner-shadow-blur: inset 0 0 3px 2px @color-focus;
	@inner-shadow-outline: inset 0 0 0 @width-focus @color-focus;

	box-shadow: @inner-shadow-outline, @inner-shadow-blur, @base-shadow;

	.js-focus-visible &:focus@{not-focus-visible} {
		box-shadow: @base-shadow;
	}
}

.remove-shadow-focus-styles(@base-shadow: none) {
	.js-focus-visible & {
		box-shadow: @base-shadow;
	}
}

.primary-button-shadow() {
	box-shadow: @primary-button-shadow;
}
