.configure-workflow-link {
	.typography-styles-label-medium();

	display: flex;
	align-items: center;
	margin-top: @spacing-s;
	color: @color-text-default-on-light;

	.configure-workflow-link__hint-text {
		margin-left: @spacing-s;
	}
}
