@content-item-filter-bg-color: @color-sidebar-background;
@content-item-filter-border-radius: @border-radius-m;

.content-item-filter {
	overflow: hidden;
	width: (36 * @grid-unit);
	border-right: @border-width-default solid @color-border-low-emphasis;
	background-color: @color-background;

	&--in-modal {
		padding-left: (@spacing-card - @facet-horizontal-spacing);
	}
}

.content-item-filter__content {
	padding-top: @spacing-main-layout-top;
	padding-bottom: @spacing-main-layout-bottom;
	overflow-x: hidden;
	overflow-y: auto;
}
