.instruction-dialog-content {
	min-width: 0;
	flex-shrink: 1;
}

.instruction-dialog-content__image {
	width: 1119px;
	max-width: 100%;
	height: 573px;
	max-height: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;

	&--inline-comments {
		background-image: url("/images/content/rte-add-comment-how-to.gif");
	}
}
