/* ----------------------------------------------------------------------------------------------------------*\
		Components


		modular-content consists of
			__items................................List of items in Linked items
			__actions..............................List of actions to manipulate content items
\*---------------------------------------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------------------------------------------*\
		#modular-content__items
\*---------------------------------------------------------------------------------------------------------- */
.modular-content__items {
	.bar-item__list {
		padding-top: 0;
		padding-left: 0;
	}

	& .modular-content__items .linked-item {
		border-right: none;
	}

	.content-component {
		border-right: none;
	}
}


/* ----------------------------------------------------------------------------------------------------------*\
		#modular-content__actions-pane
\*---------------------------------------------------------------------------------------------------------- */
.modular-content__actions-pane {
	display: flex;

	.modular-content__items + & {
		margin-top: @spacing-l;
		margin-bottom: @spacing-xs;
	}
}

.modular-content__action-pane-button {
	flex-shrink: 1;
}

