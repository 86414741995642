// These variables can be used in the inline CSS generated by the code, use var(<variable-name>) instead of hardcoded value
body {
	--text-selection-bg-color: @color-background-text-selection;
	--block-selection-bg-color: @color-background-block-selection;
	--element-selection-bg-color: @color-background-block-selection;
	--none-selection-bg-color: transparent;
	--comment-bg-color: @color-comment-background-highlighted;
	--comment-highlighted-bg-color: @color-comment-background-selected;
	--suggestion-bg-color: @color-suggestion-background-highlighted;
	--suggestion-highlighted-bg-color: @color-suggestion-background-selected;
	--z-200: @z-index-two-hundred;
}
