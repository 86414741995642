@data-table-column-head-color: @color-text-hint;
@data-table-column-head-action-color: @color-text-default-on-light;

@data-table-grid-border-spacing: @spacing-micro;

@data-table-row-horizontal-padding: @spacing-s;
@data-table-cell-horizontal-padding: @spacing-s;

@data-table-row-bg-color: @color-white;

@data-table-link-color: @color-text-default-on-light;
@data-table-date-color: @color-text-default-on-light;

.data-table {
	width: 100%;
	text-align: left;
}

.tag.data-table__tag {
	margin-left: @spacing-s;

	&--admin {
		overflow: visible;
		margin-right: @spacing-s;
		margin-left: 0;
	}
}

.data-table__actions-pane {
	display: flex;
	align-content: center;
	justify-content: space-between;
	margin-bottom: @spacing-xl;
	transition: all @OLD_transition-250;
}

.data-table__actions {
	display: flex;
	align-items: center;

	& > * + .btn-wrapper {
		margin-left: @spacing-between-buttons-horizontal;
	}
}

/*
	[1] Enables to use ellipsis within a table
*/
.data-table__grid {
	width: 100%;
	border-collapse: separate;
	table-layout: fixed; /* [1] */

	&--no-items {
		display: none;
	}
}

.data-table__column {
	&--1 {
		width: (5 * @grid-unit);
	}

	&--2 {
		width: (10 * @grid-unit);
	}

	&--3 {
		width: (15 * @grid-unit);
	}

	&--3-and-half {
		width: (17.5 * @grid-unit);
	}

	&--4 {
		width: (20 * @grid-unit);
	}

	&--5 {
		width: (25 * @grid-unit);
	}

	&--6 {
		width: (30 * @grid-unit);
	}

	&--7 {
		width: (35 * @grid-unit);
	}

	&--9 {
		width: (45 * @grid-unit);
	}

	&--one-third {
		width: 33%;
	}

	&--growing-2 {
		width: 10%;
	}

	&--growing-3 {
		width: 15%;
	}

	&--growing-4 {
		width: 20%;
	}

	&--growing-7 {
		width: 35%;
	}

	&--growing-13 {
		width: 65%;
	}

	&--growing-15 {
		width: 75%;
	}
}

.data-table__column-head {
	.typography-styles-label-large();

	height: @size-l;
	color: @data-table-column-head-color;

	&:first-child {
		padding-left: @data-table-row-horizontal-padding;
		border-bottom-left-radius: @border-radius-m;
		border-top-left-radius: @border-radius-m;
	}

	&:last-child {
		padding-right: @data-table-row-horizontal-padding;
		border-bottom-right-radius: @border-radius-m;
		border-top-right-radius: @border-radius-m;
	}

	&--checkbox:first-child {
		width: (@size-s + @data-table-row-horizontal-padding);
		padding: 0;
		padding-left: @data-table-row-horizontal-padding;
	}

	&--is-clickable {
		cursor: pointer;

		&:hover {
			background-color: @color-background-hover;
			color: @data-table-column-head-action-color;
		}
	}

	&--is-selected {
		color: @data-table-column-head-action-color;
		font-weight: @font-weight-bold;
	}
}

.data-table__column-name {
	display: flex;
	width: 100%;
	min-width: 0;
	max-width: 100%;
	height: 100%;
	align-items: center;
	padding: 0 @spacing-s;
	white-space: nowrap;

	span {
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.data-table__ordering-mark {
	display: inline-block;
	margin-top: @spacing-xs;
	margin-left: @spacing-s;
	font-size: @icon-size-xs;

	&--hidden {
		visibility: hidden;

		.data-table__column-head:hover & {
			visibility: visible;
		}
	}
}

.data-table__ordering-mark-icon {
	font-size: @icon-size-xs;
}

.data-table__row {
	position: relative;

	&--is-disabled {
		cursor: not-allowed;
	}
}

.data-table__row:has(.data-table__row-select:focus-within) { 
	border-radius: @border-radius-m;
	 
	// Safari: Use solid outline since shadow and position relative aren't supported on <tr>
	outline: @width-focus solid  @color-focus;
	outline-offset: calc(-1 * @width-focus);
}

.data-table__cell-item {
	.typography-styles-label-large();

	overflow: hidden;
	height: @size-l;
	border-top: @border-width-default solid @color-border-low-emphasis;
	background-color: @data-table-row-bg-color;
	color: @color-text-default-on-light;
	text-overflow: ellipsis;
	transition: all @transition-bg-hover;
	white-space: nowrap;

	.data-table__row:not(.data-table__row--is-disabled):not(.data-table__row--is-selected):hover & {
		background-color: @color-table-row-background-hover;
	}

	.data-table__row--is-selected & {
		background-color: @color-background-selected;
	}

	.data-table__row--is-selected:hover & {
		background-color: @color-background-selected-hover;
	}

	.data-table__row--is-disabled & {
		background-color: @color-background-disabled;
	}

	&--checkbox {
		width: (@size-s + @data-table-row-horizontal-padding);
		padding: 0;
		padding-left: @data-table-row-horizontal-padding;
	}

	&--is-clickable {
		cursor: pointer;
	}

	&--is-disabled {
		cursor: not-allowed;
	}

	&--is-expandable {
		word-break: break-word;

		&:hover {
			overflow: visible;
			white-space: normal;
		}
	}

	&--multiline {
		overflow: visible;
		white-space: normal;
	}
}

.data-table__cell-item-content {
	width: 100%;
	height: 100%;
}

.data-table__link,
.data-table__cell-item-content {
	display: flex;
	width: 100%;
	min-width: 0;
	height: 100%;
	align-items: center;
	padding: 0 @data-table-cell-horizontal-padding;
	color: @data-table-link-color;
	text-decoration: none;

	.data-table__row--is-disabled &,
	.data-table__row--is-disabled &:hover {
		color: @color-text-disabled;
	}

	.data-table__cell-item:first-child & {
		padding-left: (@data-table-cell-horizontal-padding + @data-table-row-horizontal-padding);
	}

	.data-table__cell-item:last-child & {
		padding-right: (@data-table-cell-horizontal-padding + @data-table-row-horizontal-padding);
	}

	> span {
		overflow: hidden;
		text-decoration: none;
		text-overflow: ellipsis;

		+ i {
			margin-left: @spacing-s;
		}
	}

	&:hover,
	&:focus {
		color: @data-table-link-color;
		text-decoration: none;
	}

	.data-table__cell-item--is-expandable & {
		height: auto;
		min-height: 100%;
		padding-top: @spacing-s;
		padding-bottom: @spacing-s;
	}

	.data-table__cell-item--entity-webhooks-debugging & {
		padding-left: (@spacing-xs);
	}
}

.data-table__link--multiline {
	height: auto;
	min-height: 100%;
}

.data-table__link--outward {
	display: flex;
	justify-content: space-between;
}

.data-table__pagination {
	overflow: hidden;
}

.data-table__due-date {
	color: @data-table-date-color;

	&--almost-due {
		color: @color-warning-text;
	}

	&--overdue {
		color: @color-alert-text;
	}
}

.data-table__publish-date {
	&--is-unpublished,
	&--is-none {
		color: @data-table-date-color;
	}
}

.data-table__row-select:focus-within .data-table__row-select-link::after{
	.shadow-focus-styles();

	position: absolute;
	border-radius: @border-radius-s;
	content: "";
	inset: @width-focus;
}

// Workaround to apply styles only in Safari
// Disables the shadow focus style because Safari cannot properly clip the column focus shadow to the <tr> element 
// due to limited support for position: relative
// Selector Breakdown:
// 1. _::-webkit-full-page-media
//    - A CSS hack targeting Safari by using the proprietary ::-webkit-full-page-media pseudo-element with an invalid
//      prefix "_" to ensure it's ignored by other browsers.
// 2. _:future
//    - Another CSS hack that leverages the "_" prefix to target WebKit-based browsers like Safari, as other browsers
//      ignore invalid property prefixes.
// 3. :root .data-table__row-select:focus-within .data-table__row-select-link::after
//    - Targets the ::after pseudo-element of .data-table__row-select-link when its ancestor 
//      .data-table__row-select is focused within, ensuring the style only applies in the context of the root element
/* stylelint-disable-next-line selector-type-no-unknown */
_::-webkit-full-page-media, _:future, :root .data-table__row-select:focus-within .data-table__row-select-link::after{
		display: none;
}

.filter + .data-table {
	margin-top: @spacing-xl;
}
