.language-settings-form-fields {
	display: flex;
	flex-wrap: wrap;
	// use gap: 0 @spacing-xl here and remove side margin from language-settings-form-group once Safari finally implements it :facepalm:
	margin: 0 (@spacing-m * -1);
}

.language-settings-form-group {
	flex: 1 1 0;
	margin: @spacing-xl @spacing-m 0 @spacing-m;

	.form__group--clipboard {
		margin-bottom: 0;
	}

	&:empty {
		margin: 0 @spacing-m;
	}
}
