@guidelines-text-color: @color-text-low-emphasis;

/* ----------------------------------------------------------------------------------------------------------*\
		Component variables
\*---------------------------------------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------------------------------------------*\
		Components


		content-item-guidelines consists of
			__content..............................Guidelines as written in content type
\*---------------------------------------------------------------------------------------------------------- */

.content-item-guidelines {
	.bar-item__pane {
		background-color: @color-warning-background;
	}

	.bar-item__expansion--no-actions-bar {
		border-radius: @border-radius-m;
	}

	.content-item-element__wrapper--is-disabled & {
		border-radius: @border-radius-m;
	}
}

.content-item-guidelines__content .rte__content {
	.rte__typography(1);

	min-height: 5px;
	color: @guidelines-text-color;

	.rte__code {
		// The next prop is not background-color in order to mix with comment backgrounds.
		// see CommentThreadHighlighter and FocusedCommentThreadHighlighter
		background-image: linear-gradient(rgb(@color-gray50 0.35), rgb(@color-gray50 0.35));
	}

	*:last-child {
		margin-bottom: 0;
	}
}
