@my-project-padding: @spacing-card;
@my-project-margin: @spacing-card;
@my-project-width: (44 * @grid-unit);
@my-project-height: (42 * @grid-unit);
@my-project-archived-project-color: @color-text-disabled;
@my-project-failed-project-color: @color-alert-text;
@my-project-bg-color: @color-white;

@my-project-placeholder-bg-color: transparent;
@my-project-placeholder-border-color: @color-border-default;

@my-project-progress-indicator-after-bg-color: @color-primary;
@my-project-progress-indicator-bg-color: @color-gray20;

@my-project-subscription-name-color: @color-text-low-emphasis;

@my-project-separator-bg-color: @color-divider-default;

@my-project-loading-status-color: @color-warning-text;

@my-project-indicator-height: @border-width-default * 4;


.my-projects-status {
	display: flex;
	overflow: hidden;
	flex: 1 1 100%;
	align-items: center;
}


.my-projects-pane .row {
	margin-bottom: @spacing-l;
}


.my-projects {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: left;
}


.my-project {
	.border-low-emphasis();

	position: relative;
	overflow: hidden;
	width: @my-project-width;
	min-width: @my-project-width;
	height: @my-project-height;
	padding: @my-project-padding;
	border-radius: @border-radius-l;
	margin: 0 @my-project-margin @my-project-margin 0;
	background: @my-project-bg-color;
	cursor: default;

	&--is-archived {
		color: @my-project-archived-project-color;

		.my-project__title,
		.my-project__subscription-name,
		.my-project__user {
			color: @my-project-archived-project-color;
		}
	}

	&--is-failed::before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: @my-project-indicator-height;
		background-color: @my-project-failed-project-color;
		content: "";
	}

	&--is-placeholder {
		padding: @my-project-padding;
		border: @my-project-placeholder-border-color @border-width-default solid;
		background: @my-project-placeholder-bg-color;
		box-shadow: @box-shadow-xs;
		text-align: center;
	}
}


.my-project__progress-indicator {
	&::after {
		position: absolute;
		display: block;
		width: 30%;
		height: @my-project-indicator-height;
		border-radius: @border-radius-l;
		animation: progress-indicator-movement 1.2s infinite alternate ease-in-out;
		animation-direction: alternate;
		background-color: @my-project-progress-indicator-after-bg-color;
		content: "";
	}

	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
	width: 100%;
	height: @my-project-indicator-height;
	background-color: @my-project-progress-indicator-bg-color;
}


.my-project__actions-pane {
	position: absolute;
	bottom: @spacing-xl;
	display: flex;
	width: (@my-project-width - @my-project-padding - @my-project-margin);
	justify-content: space-between;
}

.my-project__message {
	display: flex;
	margin-bottom: @spacing-s;

	&--failed {
		.typography-styles-subheadline();

		margin-left: @spacing-s;
		color: @color-alert-text;
	}
}

.my-project__copying-status {
	.typography-styles-subheadline();

	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: @spacing-s;
	color: @color-text-default-on-light;
}

.my-project__loading-status {
	.typography-styles-subheadline();

	color: @my-project-loading-status-color;
}

.my-project__failed-icon {
	color: @color-alert-icon;
}

.my-project__loading-wrapper {
	& > i {
		margin-right: @spacing-s;
		color: @color-warning-icon;
		font-size: @icon-size-s;
	}
}

.my-project__title {
	.typography-styles-headline-large();

	position: relative;
	display: -webkit-box;
	overflow: hidden;
	width: 100%;
	height: (8 * @grid-unit);
	margin-bottom: @spacing-xl;
	-webkit-box-orient: vertical;
	color: @color-text-default-on-light;
	-webkit-line-clamp: 2;
	white-space: normal;
	word-wrap: break-word;
}

.my-project__title-hidden {
	position: absolute;
	visibility: hidden;
}

.my-project__subscription-name {
	.typography-styles-subheadline();

	overflow: hidden;
	height: @size-xs;
	color: @my-project-subscription-name-color;
	text-overflow: ellipsis;
	text-transform: uppercase;
	white-space: nowrap;
}


.my-project__separator {
	height: @border-width-default;
	margin: @spacing-xl 0;
	background-color: @my-project-separator-bg-color;
	text-transform: uppercase;
}


.my-project__user {
	.typography-styles-label-large();

	display: -webkit-box;
	overflow: hidden;
	max-height: (9 * @grid-unit);
	margin-bottom: @spacing-s;
	-webkit-box-orient: vertical;
	color: @color-black;
	-webkit-line-clamp: 3;
}

.my-project__status {
	display: flex;
	overflow: hidden;
	align-items: center;
}

.my-project__button-wrapper {
	margin-right: @spacing-between-buttons-horizontal;
}


.my-project__placeholder-image {
	width: 100%;
	height: 48%;
	margin-bottom: @spacing-s;
}


.my-project__placeholder-empty-project-image {
	background: url(@image-empty-project-placeholder) no-repeat center/contain;
}


.my-project__placeholder-sample-project-image {
	background: url(@image-sample-project-placeholder) no-repeat center/contain;
}


.my-project__placeholder-experimental-sample-project-image {
	background: url(@image-experimental-sample-project-placeholder) no-repeat center/contain;
}


.my-project__placeholder-title {
	.typography-styles-headline-large();

	margin-bottom: @spacing-s;
	color: @color-black;
}


.my-project__placeholder-text {
	.typography-styles-label-large();

	margin-bottom: @spacing-xl;
	color: @color-black;
}


