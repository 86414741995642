@content-item-usage-hover-bg-color: @color-primary-hover-inverse;
@content-item-usage-color: @color-text-default-on-light;

.content-item-usage {
	width: 100%;
	cursor: default;

	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
	}
}

.content-item-usage__primary-action {
	.typography-styles-label-large();

	position: relative;
	display: flex;
	width: 100%;
	align-items: center;
	padding: @spacing-xs @spacing-xxl;
	color: @color-text-low-emphasis;
	text-decoration: none;

	&:hover,
	&:active,
	&:focus {
		color: @content-item-usage-color;
		text-decoration: none;
	}

	&:focus {
		&::before {
			position: absolute;
			// border width
			top: 2px;
			right: 2px;
			bottom: 2px;
			left: 2px;
			border-radius: @border-radius-s;
			content: "";
			.shadow-focus-styles();
		}
	}

	&:focus-within {
		outline: 0;
	}

	&--is-clickable {
		&:hover {
			background: @content-item-usage-hover-bg-color;
			cursor: pointer;
			transition: all @OLD_transition-150;
		}
	}

	&--disabled {
		cursor: not-allowed;
	}
}


.content-item-usage__title {
	overflow: hidden;
	text-overflow: ellipsis;
	user-select: none;
	white-space: nowrap;

	&--redacted {
		overflow: visible;
		font-style: italic;
	}
}

.content-item-usage__additional-note {
	.typography-styles-label-small();

	padding: @spacing-xs @spacing-xxl;
	color: @content-item-usage-color;
}

.content-item-toolbar__show-more {
	.typography-styles-action-medium();

	padding: @spacing-s @spacing-xxl;
	color: @color-primary;
	text-decoration: none;

	&:hover {
		color: @color-primary-hover;
		text-decoration: underline;
	}
}
