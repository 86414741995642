@generator-error-message-color: @color-text-default-on-dark;
@generator-error-bg-color: @color-alert-background-inverse;

.generator__error-message {
	color: @generator-error-message-color;

	a {
		color: @generator-error-message-color;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}


.generator__pane {
	position: fixed;
	top: 50%;
	left: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transform: translate(-50%, -50%);

	a:hover {
		text-decoration: none;
	}
}


.generator__error {
	.typography-styles-ui-paragraph();

	padding: @spacing-s;
	margin: @spacing-s;
	background-color: @generator-error-bg-color;
	color: @color-text-default-on-light;
}
