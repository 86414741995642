/* ------------------------------------*\
		Component variables
\*------------------------------------ */
@status-bar-height: (@grid-unit * 6);

.status-bar__dropdown-text {
	.typography-styles-label-large();

	overflow: hidden;
	max-width: (30 * @grid-unit);
	text-overflow: ellipsis;
	text-wrap: none;
}

.status-bar__title-pane {
	display: flex;
	overflow: hidden;
	padding: 12px;
	text-overflow: ellipsis;
	white-space: nowrap;

	.status-bar__nav-pane + & {
		margin-left: @spacing-m;
	}
}

.status-bar__title {
	display: inline;
	color: @color-text-default-on-dark;
	font-weight: initial;
}

.status-bar__button {
	position: relative;
	display: flex;
	align-items: center;
}

.status-bar__left-pane {
	display: flex;
	min-width: 0;
	flex: 0 1 auto;
	align-items: center;
	gap: @spacing-xxl;

	&:empty {
		display: none;
	}
}

@status-bar-central-pane-min-width: 15 * @grid-unit;

.status-bar__central-pane {
	display: flex;
	min-width: @status-bar-central-pane-min-width;
	flex: 1 1 0;
	align-items: center;
	justify-content: flex-start;
	gap: @spacing-xxl;

	&--with-shrinkable-content {
		flex: 1 1 @status-bar-central-pane-min-width;
	}

	> .btn-wrapper {
		margin-left: @spacing-s;
	}
}

.status-bar__right-pane {
	display: flex;
	min-width: 0;
	flex: 0 0 auto;
	align-items: center;
	justify-content: center;
	padding-left: @spacing-s;
	margin-left: auto;
	gap: @spacing-l;


	&--with-shrinkable-content {
		flex: 0 1 auto;
	}

	.combo-box-wrapper {
		+ .btn-wrapper {
			margin-left: @spacing-l;
		}
	}
}
