@asset-thumbnail-height: (32 * @grid-unit);
@asset-thumbnail-in-asset-library-height: (35 * @grid-unit);
@asset-thumbnail-actions-height: 44px;
@asset-thumbnail-bottom-vertical-padding: @spacing-l;
@asset-thumbnail-bottom-horizontal-padding: @spacing-xl;
@asset-thumbnail-in-asset-library-summary-max-height: (@asset-thumbnail-in-asset-library-height - @asset-thumbnail-actions-height - (2 * @asset-thumbnail-bottom-vertical-padding));
@asset-thumbnail-border-width: @border-width-default;

// Adding 1px compensates incorrect antialiasing on rounder corner
@asset-thumbnail-border-radius: @border-radius-m;
@asset-thumbnail-preview-border-radius: (@asset-thumbnail-border-radius);

.asset-thumbnail {
	position: relative;
	width: 100%;
	height: @asset-thumbnail-height;
	border-radius: @asset-thumbnail-border-radius;
	background-color: @color-background-low-emphasis;
	cursor: pointer;
	transition: all @OLD_transition-150;

  .border-low-emphasis();

	&--has-comment {
		//we use this class modifier to indicate that asset-thumbnail was commented
	}

	&--is-not-clickable,
	&--is-invalid,
	&--is-not-selectable {
		cursor: default;
	}

	&--is-dragging {
		.drag-placeholder();
	}

	&--is-inline {
		height: inherit !important;
	}

	&--is-skelet {
		background-color: @color-skeleton-background;
		cursor: default;

		& .asset-thumbnail-skelet__summary {
			position: absolute;
			bottom: 0;
			width: 100%;
		}

		& .asset-thumbnail-skelet__text {
			.color-skeleton-content();

			display: block;
			width: 60%;
			height: @spacing-l;
			border-radius: @border-radius-pill;
			margin: 0 0 @spacing-s @spacing-xl;
			animation: skelet-loader 4s ease infinite;
			background-size: 200%;

			&:last-child {
				margin-bottom: @spacing-l;
			}

			&--is-short {
				width: 45%;
			}
		}
	}

	&--is-viewonly {
		border-width: @border-width-default;

		.asset-thumbnail__actions-pane {
			display: none;
		}

		&-focused,
		&:hover {
			.asset-thumbnail__actions-pane {
				display: flex;
			}
		}
	}

	// Prevent phantom hovers by disabling pointer events while something is dragging in the list
	.asset__list--is--dragging &,
	.rte--is-dragging & {
		pointer-events: none;
	}

	&--is-not-allowed {
		.forbidden-object();
	}

	&--is-focused {
		.shadow-focus-styles();
	}
}

.asset-thumbnail--is-in-asset-library {
	height: @asset-thumbnail-in-asset-library-height;

	.asset-thumbnail__bottom {
		z-index: @z-index-one;
	}

	.dialog__body &:not(.asset-thumbnail--is-not-clickable) .asset-thumbnail__preview-pane:hover + .asset-thumbnail__bottom {
		background-color: @color-primary-hover-inverse;
	}

	.asset-thumbnail__bottom:hover {
		background-color: @color-primary-hover-inverse;

		.asset-thumbnail--is-not-clickable& {
			background-color: @color-white;
		}
	}

	.asset-thumbnail__summary {
		display: flex;
		max-height: @asset-thumbnail-in-asset-library-summary-max-height;
		flex-flow: column;
	}

	.asset-thumbnail__title:hover {
		overflow: auto;

		.asset-thumbnail__name {
			overflow: visible;
			white-space: normal;
		}
	}

	.option {
		padding: @asset-thumbnail-bottom-vertical-padding @asset-thumbnail-bottom-horizontal-padding;
	}

	.option::before {
		margin: 0;
	}

	.option__label .asset-thumbnail__summary {
		margin-left: @spacing-l;
	}

	&.asset-thumbnail--has-focus {
		.asset-thumbnail__actions-pane,
		.asset-thumbnail__bottom {
			background-color: @color-background-selected;
		}

		.asset-thumbnail__bottom:hover,
		.dialog__body & .asset-thumbnail__preview-pane:hover + .asset-thumbnail__bottom {
			background-color: @color-background-selected-hover;
		}

		.option:not(option--is-disabled):hover {
			background-color: transparent;
		}
	}

	.asset-thumbnail__preview {
		position: relative;
		height: @asset-thumbnail-in-asset-library-height - (2 * @asset-thumbnail-border-width);

		&:hover::after {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: @color-background-hover;
			content: "";

			.dialog__body &,
			.asset-thumbnail--is-not-clickable& {
				content: none;
			}
		}
	}
}

.asset-thumbnail__progress-bar {
	position: absolute;
	z-index: @z-index-one;
	top: 0;
	overflow: hidden;
	width: 100%;
	height: @asset-thumbnail-actions-height;
	border-radius: @asset-thumbnail-preview-border-radius @asset-thumbnail-preview-border-radius 0 0;
	background: transparent;
}

.asset-thumbnail__progress-bar-line {
	height: 2px;
	background: @color-primary;
	transition: all 150ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
}

.asset-thumbnail__actions-pane {
	position: absolute;
	z-index: @z-index-two;
	top: 0;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: @asset-thumbnail-actions-height;
	align-items: center;
	justify-content: flex-end;
	padding: 0 @spacing-m;
	border-radius: @asset-thumbnail-border-radius  @asset-thumbnail-border-radius 0 0;
	background-color: fade(@color-white, 90%);
	cursor: default;

	&--has-comment,
	&--has-focused-comment {
		&::before {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			display: block;
			height: 0;
			content: "";
		}
	}

	&--has-comment {
		&::before {
			border-top: @border-width-commented-element solid @color-comment-background-highlighted;
		}
	}

	&--has-focused-comment {
		&::before {
			border-top: @border-width-commented-element solid @color-comment-background-selected;
		}
	}
}

.asset-thumbnail__actions-left {
	display: flex;
	flex-grow: 1;
	align-items: center;
	justify-content: flex-start;
}

.asset-thumbnail__action {
	border-radius: @border-radius-s;
	transition: all 150ms cubic-bezier(0.23, 1, 0.32, 1) 0s;

	&:focus {
		.shadow-focus-styles();
	}

	&:focus-visible {
		outline: none;
	}

	& i {
		padding: @spacing-xs;
		border-radius: @border-radius-s;
		color: @color-text-default-on-light;
		cursor: pointer;
		font-size: @font-size-l;

		&:hover {
			background-color: @color-primary-hover-inverse;
		}

		&:active {
			background-color: @color-background-selected;
			color: @color-primary;
		}

		.asset__list--is-dragging & {
			&,
			&:hover,
			&:active {
				background-color: transparent;
				color: @color-text-default-on-light;
			}
		}
	}

	&--is-disabled {
		cursor: default;
		pointer-events: none;

		&:hover {
			background-color: inherit;
			text-decoration: none;
		}

		[class^="icon-"],
		[class*=" icon-"] {
			opacity: 0.4;
		}
	}

	&--remove {
		&:hover {
			i {
				background-color: @color-alert-hover;
        color: @color-text-default-on-dark;
			}
		}

    .asset-thumbnail__alert & i {
      color: @color-text-default-on-dark;
    }

		.asset-thumbnail--is-disabled & {
			display: none;
		}
	}
}

.asset-thumbnail__alert {
	position: absolute;
	z-index: @z-index-one;
	top: 0;
	display: flex;
	width: 100%;
	height: @asset-thumbnail-actions-height;
	align-items: center;
	justify-content: space-between;
	padding-left: @spacing-card;
	background-color: @color-alert-background-inverse;
}

.asset-thumbnail__alert-message {
	.typography-styles-title-large();

	color: @color-text-default-on-dark;
}

.asset-thumbnail__preview {
	display: flex;
	overflow: hidden;
	height: (@asset-thumbnail-height - (2 * @asset-thumbnail-border-width));
	align-items: center;
	justify-content: space-around;
	border-radius: @asset-thumbnail-preview-border-radius;

	&--in-progress {
		.typography-styles-title-large();
		.pulse-500;

		color: @color-info-text;
	}

	&--is-inline {
		height: inherit !important;
	}

	&--with-icon {
		padding-bottom: @spacing-l;
	}

	&--no-access {
		background-color: @color-yellow10;
	}
}

.asset-thumbnail__image {
	max-width: 100%;
	max-height: 100%;
}

.asset-thumbnail__bottom {
	position: absolute;
	bottom: 0;
	left: 0;
	display: flex;
	width: 100%;
	border-radius: 0 0  @asset-thumbnail-border-radius  @asset-thumbnail-border-radius;
	background-color: @color-white;
	color: @color-text-default-on-light;
	transition: all @OLD_transition-150;

	&--with-padding {
		padding: @asset-thumbnail-bottom-vertical-padding @asset-thumbnail-bottom-horizontal-padding;
	}
}

.asset-thumbnail__asset-selection {
	width: 100%;
	align-items: center;
}

.asset-thumbnail__summary {
	min-width: 0; /* fix overflow if flex is used */

	&--hidden {
		display: none;
	}

	.asset-thumbnail:hover &--hidden,
	.asset-thumbnail--has-focus &--hidden {
		display: block;
	}
}

.asset-thumbnail__folder {
	display: flex;
	align-items: center;
	margin-top: @spacing-s;
}

.asset-thumbnail__folder-name {
	.typography-styles-label-medium();

	overflow: hidden;
	margin-left: @spacing-xs;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.asset-thumbnail__folder-tooltip {
	display: flex;
}

.asset-thumbnail__folder-ellipsis {
	transform: translateY(@spacing-xs);
}

.asset-thumbnail__folder-separator {
	padding: 0 @spacing-xs;
}

.asset-thumbnail__title {
	display: block;
	width: 100%;
	margin-bottom: @spacing-s;
	white-space: nowrap;
	word-break: break-word;

	.asset-thumbnail__deleted {
		display: block;
		margin-top: @spacing-xs;
		color: @color-alert-text;
	}
}

.asset-thumbnail__name {
	.typography-styles-title-large();

	overflow: hidden;
	text-overflow: ellipsis;

	&--is-invalid {
		color: @color-alert-text;
	}

	&--is-not-first {
		.typography-styles-title-medium();

		margin-top: @spacing-xs;
	}

	&:hover {
		overflow: visible;
		white-space: normal;
	}
}

.asset-thumbnail__tech-detail {
	&--is-invalid {
		color: @color-alert-text;
	}

	&::after {
		color: @color-text-low-emphasis;
		content: "\00a0\00b7\00a0";
	}

	&:last-of-type::after {
		content: "";
	}
}

.asset-thumbnail__file-extension {
	&--is-invalid {
		color: @color-alert-text;
	}
}

.asset-thumbnail__file-size {
	&--is-invalid {
		color: @color-alert-text;
	}
}
