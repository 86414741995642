.plan-selection__change-modal {
	color: @color-text-default-on-light;
	.typography-styles-ui-paragraph();

	p + p,
	p + ul {
		margin-top: @spacing-xl;
	}

	li + li {
		margin-top: @spacing-xs;
	}
}
