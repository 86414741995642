@filter-name-editor-action-button-pane-offset-top: @spacing-xl;
@filter-name-editor-action-button-cancel-offset-right: @spacing-xs;

.filter-name-editor {
	min-width: 256px;
}

.filter-name-editor__action-button-pane {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: @filter-name-editor-action-button-pane-offset-top;

	& > :only-child {
		width: 100%;
		text-align: right;
	}
}
