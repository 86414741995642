@avatar-chain-avatar-spacing: (@size-m / 3);

.avatar-chain {
	display: flex;
	flex: 1;
	flex-direction: row-reverse;
	margin-right: @spacing-l;

	&__overlap {
		.typography-styles-title-large();

		z-index: 2;
		display: flex;
		min-width: @size-xs;
		height: @size-xs;
		align-items: center;
		justify-content: center;
		padding: 0 @spacing-xs;
		border-radius: @border-radius-s;
		margin-right: 0;
		background-color: @color-white;
		box-shadow: @box-shadow-s;
		color: @color-text-default-on-light;
		cursor: default;
	}

	&__avatar {
		position: relative;
		z-index: 1;
		border: 2px solid @color-white;
		border-radius: 50%;
		margin-right: (@avatar-chain-avatar-spacing * -1);
		transition: all 0.1s ease-out;

		&:hover {
			z-index: 2;
			margin-right: ((@avatar-chain-avatar-spacing / 2) * -1);
		}
	}
}
