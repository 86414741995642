@checkbox-icon-padding-with-border: @border-width-default;
@checkbox-font-size-with-border: (@spacing-l - @border-width-default);
@option-checkbox-border-color: @color-border-default;
@option-disabled-color: @color-text-disabled;
@option-disabled-background-color: @color-background-disabled;
@option-padding: @spacing-s;

@checkbox-width: @icon-size-s;
@checkbox-label-space: @spacing-xs;

@card-pane-padding: @spacing-l;

.option__list {
	&--item-element {
		padding-left: 1px;
	}

	.card__content & {
		// We need to compensate for the option hover spacing of the first option
		margin-top: (@spacing-s * -1);
	}

	& + & {
		margin-top: @spacing-s;
	}
}

.option__pane {
	position: relative;
	display: block;

	&--inlined {
		display: inline-block;
	}
}

.option__input {
	&--hidden {
		position: absolute;
		left: -9999px;
		opacity: 0;
	}
}

.option {
	display: inline-flex;
	align-items: center;
	padding: @option-padding @option-padding @option-padding @option-padding;
	border-radius: @border-radius-s;
	cursor: pointer;

	&::before {
		.typography-styles-font-icon(@icon-size-s);

		margin-right: @checkbox-label-space;
		line-height: @spacing-l;
	}

	&:not(&--is-disabled):hover {
		background: @color-primary-hover-inverse;
		white-space: normal;
	}

	.toggle__input--hidden:focus + & {
		.shadow-focus-styles();

		white-space: normal;
	}

	.toggle__input--hidden:focus@{not-focus-visible} + & {
		.remove-shadow-focus-styles();
	}

	&--is-checkbox {
		&::before {
			.typography-styles-font-icon(@checkbox-font-size-with-border);

			width: @checkbox-width;
			min-width: @checkbox-width;
			height: @checkbox-width;
			min-height: @checkbox-width;
			box-sizing: border-box;
			border: @border-width-default solid @color-border-default;
			border-radius: @border-radius-s;
			background-color: @color-white;
			content: "";
		}
	}

	&--is-checkbox&--is-selected {
		&::before {
			padding: @checkbox-icon-padding-with-border 0 0 @checkbox-icon-padding-with-border;
			border: none;
			background-color: @color-primary;
			color: @color-white;
			content: @icon-cb-check-sign;
		}
	}

	&--is-checkbox&--is-disabled {
		&::before {
			border: @border-width-default solid @color-border-default;
			background-color: @color-background-disabled;
		}
	}

	&--is-checkbox&--is-selected&--is-disabled {
		&::before {
			padding: 0;
			color: @color-icon-disabled;
		}
	}

	&--is-radio-button {
		&::before {
			border-radius: @border-radius-pill;
			background-color: @color-white;
			color: @color-border-default;
			content: @icon-rb-uncheck;
		}
	}

	&--is-radio-button&--is-selected {
		&::before {
			color: @color-primary;
			content: @icon-rb-check;
		}
	}

	&--is-radio-button&--is-disabled {
		&::before {
			background-color: @color-background-disabled;
			color: @color-icon-disabled;
		}
	}

	&--is-fullsize {
		width: 100%;
	}

	&--is-disabled {
		color: @option-disabled-color;
		cursor: not-allowed;
	}

	&--content-type {
		&::before {
			margin-left: 0;
		}
	}
}


.option__label {
	.typography-styles-label-large();

	min-width: 0; /* fix overflow if flex is used */
	flex: 1 1 auto;
	color: @color-text-default-on-light;
	user-select: none;
	word-break: break-word;

	&--with-dropdown {
		width: auto;
		min-width: 25%;
		flex: none;
	}
}

.option__description {
	.typography-styles-caption();

	@space-taken-by-checkbox: (@option-padding + @checkbox-width + @checkbox-label-space);

	padding-left: @space-taken-by-checkbox;
	margin-top: @spacing-xs;
	margin-bottom: @spacing-l;
	color: @color-text-hint;
}


.option__description-list {
	margin-top: 0;
}
