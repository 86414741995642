[class^="icon-"],
[class*=" icon-"] {
	display: inline-block;
	.typography-styles-font-icon(@icon-size-s);
}

.icon {
	&--xs {
		font-size: @icon-size-xs;
	}
}

[class^="icon-"]::before {
	content: @icon-square;
}

.switch-icon-select:hover {
	content: @icon-check;
}
