.dropzone {
	position: relative;
	flex: 1 1 auto;
}


.dropzone__overlay {
	.typography-styles-headline-large();

	position: absolute;
	z-index: 1000;
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	justify-content: center;
	background-color: rgb(@color-ocean-blue80 0.8);
	color: @color-text-default-on-dark;
	// Fix the issue in Firefox where the dropzone doesn't hide when a file is dropped onto the text inside it.
	pointer-events: none;
	text-align: center;
}
