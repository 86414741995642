@rte-rich-text-element-padding: @content-item-element-horizontal-padding;

.rte--in-content-item-element > .rte__content {
	background-color: @content-element-guidelines-text-input-bg-color;

	.public-DraftEditor-content {
		padding-right: @rte-rich-text-element-padding;
		padding-left: @rte-rich-text-element-padding;
		margin-right: (@rte-rich-text-element-padding * -1);
		margin-left: (@rte-rich-text-element-padding * -1);
	}

	.public-DraftEditorPlaceholder-root {
		padding: 0;
	}
}
