@skeleton-loader-box-bg: linear-gradient(135deg, @color-gray30 0%, @color-gray20 100%);

.project-overview__section {
	padding: @spacing-card;
	border-radius: @border-radius-card;
	margin-bottom: @spacing-xl;
	background-color: @color-white;
	box-shadow: @box-shadow-xs;
}

.project-overview__section-title {
	.typography-card-headline();

	margin-bottom: @spacing-xl;
	color: @color-text-default-on-light;
}

/* ----------------------------------------------------------------------------*\
		#planning status
\*---------------------------------------------------------------------------- */

.planning-status__tiles {
	display: flex;
	min-height: (14 * @grid-unit);
	align-items: stretch;
	justify-content: stretch;
	padding: 0;
	margin: 0;
	list-style-type: none;
}

.planning-status__tile {
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: stretch;

	& + & {
		margin-left: @spacing-l;
	}
}

.planning-status-tile {
	display: flex;
	flex: 1;
	align-items: stretch;
	justify-content: center;
	padding: @spacing-card;
	border: @border-width-default solid @color-border-default;
	border-radius: @border-radius-m;
	cursor: pointer;

	&--enabled:hover {
		background-color: @color-background-hover;
	}

	&--loading {
		cursor: default;
	}

	&--disabled {
		cursor: not-allowed;
	}
}

.planning-status-tile__content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}


.planning-status-tile__caption {
	.typography-styles-label-medium();

	color: @color-text-default-on-light;
	text-align: center;
}

.planning-status-tile__number {
	.typography-styles-headline-large();

	color: @color-text-default-on-light;
	text-align: center;

	&--loading {
		width: @size-s;
		height: @size-s;
		border-radius: @border-radius-pill;
		background: @skeleton-loader-box-bg;
	}
}

/* ----------------------------------------------------------------------------*\
		#workflow overview
\*---------------------------------------------------------------------------- */

.workflow-overview__listing-loader {
	display: flex;
	align-content: stretch;
	padding: 0;
	margin: @spacing-xl 0 0 0;
	list-style-type: none;
}


.workflow-overview__listing-loader-item {
	height: @size-xs;
	flex-grow: 1;
	border-radius: @border-radius-pill;
	background: @skeleton-loader-box-bg;

	& + & {
		margin-left: @spacing-xl;
	}
}
