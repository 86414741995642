@number-input-invalid-color: @color-alert-text;
@number-input-invalid-border-color: @color-border-alert;
@number-input-is-disabled-bg-color: @color-background-disabled;

.number__input {
	width: 100%;
	box-sizing: border-box;
	border-radius: @border-radius-m;

	&--is-empty {
		color: @color-text-hint;
		font-size: inherit;
	}

	&--invalid {
		border: 1px solid @number-input-invalid-border-color;
		color: @number-input-invalid-color;
	}

	&--is-disabled {
		width: auto;
		min-height: (5 * @grid-unit);
		padding: @grid-unit;
		background-color: @number-input-is-disabled-bg-color;
		white-space: nowrap;
	}

	&::placeholder {
		.placeholder();
	}
}
