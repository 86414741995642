/* ----------------------------------------------------------------------------------------------------------*\
		Components

		column-settings
\*---------------------------------------------------------------------------------------------------------- */

.column-settings {
	display: flex;
	align-items: center;
	justify-content: center;

	.dropdown-options__pane {
		overflow: visible;
		margin: 0 @spacing-s;
	}

	.option__pane + .option__pane {
		margin-top: @spacing-s;
	}
}
