// ***********************************
// component variables
// *****************************************************************************
@tree-name-selected-color: @color-text-default-on-light;
@tree-name-hover-background-color: @color-primary-hover-inverse;
@tree-name-disabled-color: @color-text-disabled;
@tree-name-before-border-color: @color-border-default;

@tree-expand-action-color: @color-icon-default;
@tree-expand-action-hover-bg-color: @color-background-hover;

@icon-expand-size: @icon-size-s;

// ***********************************
// top component class
//
// tree-item consists of
// __list to comprise the list of nodes
// __node to represent a node of tree
//   __item to represent particular item in a node
//     __expand-area
//        __expand-action
//     __name
// *****************************************************************************
.tree {
	overflow: hidden;
	width: 100%;
}


.tree__list {
	padding-left: 0;
	margin: 0;
	list-style-type: none;

	.tree__list {
		padding-left: @spacing-xl;
	}

	&--is-collapsed {
		display: none;
		max-height: 0;
		visibility: hidden;
	}

	&--is-expanded {
		visibility: visible;
	}
}


.tree__node {
	.tree__node {
		margin-top: @spacing-xs;
	}

	+ .tree__node {
		margin-top: @spacing-xs;
	}
}


.tree__item {
	display: flex;
	align-items: center;
}


.tree__expand-area {
	margin: @spacing-xs @spacing-xs @spacing-xs 0;
	user-select: none;
}


.tree__expand-action {
	.typography-styles-font-icon(@icon-expand-size);

	display: flex;
	padding: @spacing-xs;
	border-radius: @border-radius-s;
	color: @tree-expand-action-color;
	cursor: pointer;
	transition: all @OLD_transition-150;

	&:hover {
		background-color: @tree-expand-action-hover-bg-color;
	}

	&:active {
		background-color: @color-background-selected;
		color: @color-primary;
	}

	&--is-collapsed {
		transform: rotate(-90deg);
	}

	&--is-disabled {
		&,
		&:hover {
			cursor: default;
			visibility: hidden;
		}
	}
}


.tree__name {
	.typography-styles-label-large();

	display: inline-flex;
	padding: @spacing-xs;
	border-radius: @border-radius-s;
	color: @color-text-default-on-light;
	cursor: pointer;
	transition: all @OLD_transition-150;
	user-select: none;
	word-break: break-all;

	&::before {
		.typography-styles-font-icon(@icon-size-s);

		margin-right: @spacing-xs;
	}

	&--is-selectable {
		&::before {
			.typography-styles-font-icon(@checkbox-font-size-with-border);

			width: @spacing-l;
			min-width: @spacing-l;
			height: @spacing-l;
			min-height: @spacing-l;
			box-sizing: border-box;
			padding-left: @spacing-s;
			border: @border-width-default solid @color-border-default;
			border-radius: @border-radius-s;
			background-color: @color-white;
			color: @tree-name-before-border-color;
			content: "";
		}
	}

	&--is-selected {
		color: @tree-name-selected-color;

		&::before {
			padding: @checkbox-icon-padding-with-border 0 0 @checkbox-icon-padding-with-border;
			border: none;
			background-color: @color-primary;
			color: @color-white;
			content: @icon-cb-check-sign;
		}
	}

	&:not(&--is-disabled):hover {
		background-color: @tree-name-hover-background-color;
		color: @tree-name-selected-color;
	}

	.toggle__input--hidden:focus + & {
		color: @tree-name-selected-color;
		.shadow-focus-styles();
	}

	.toggle__input--hidden:focus@{not-focus-visible} + & {
		.remove-shadow-focus-styles();
	}

	&--is-disabled {
		cursor: not-allowed;

		&::before {
			border: @border-width-default solid @color-border-default;
			background-color: @color-background-disabled;
		}
	}

	&--is-disabled&--is-selected {
		&::before {
			padding: 0;
			color: @color-icon-disabled;
		}
	}

	&&--is-deleted,
	&&--is-deleted:hover {
		color: @color-forbidden-content;
	}
}

.sidebar__section {
	.tree__node {
		.tree__node {
			margin-top: 0;
		}

		+ .tree__node {
			margin-top: 0;
		}
	}

	.tree__item {
		min-height: @size-s;
	}
}
