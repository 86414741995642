// Grid system
//
// Generate semantic grid columns with these mixins.

// Centered container element
.container-fixed(@gutter: @OLD_grid-gutter-width) {
	padding-right: (@gutter * 0.5);
	padding-left: (@gutter * 0.5);
	margin-right: auto;
	margin-left: auto;
	&:extend(.clearfix all);
}


// Creates a wrapper for a series of columns
.make-row(@gutter-width: @spacing-card, @gutter-height: @spacing-card) {
	margin-right: (@gutter-width * -1);
	margin-bottom: (@gutter-height * -1);
	&:extend(.clearfix all);
}


// Generate the extra small columns
.make-xs-column(@columns; @gutter: @OLD_grid-gutter-width) {
	position: relative;
	width: percentage((@columns / @OLD_grid-columns));
	min-height: 1px;
	padding-right: (@gutter * 0.5);
	padding-left: (@gutter * 0.5);
	float: left;
}


.make-xs-column-offset(@columns) {
	margin-left: percentage((@columns / @OLD_grid-columns));
}


.make-xs-column-push(@columns) {
	left: percentage((@columns / @OLD_grid-columns));
}


.make-xs-column-pull(@columns) {
	right: percentage((@columns / @OLD_grid-columns));
}


// Generate the small columns
.make-sm-column(@columns; @gutter: @OLD_grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-right: (@gutter * 0.5);
	padding-left: (@gutter * 0.5);

	@media (min-width: @OLD_screen-sm-min) {
		width: percentage((@columns / @OLD_grid-columns));
		float: left;
	}
}


.make-sm-column-offset(@columns) {
	@media (min-width: @OLD_screen-sm-min) {
		margin-left: percentage((@columns / @OLD_grid-columns));
	}
}


.make-sm-column-push(@columns) {
	@media (min-width: @OLD_screen-sm-min) {
		left: percentage((@columns / @OLD_grid-columns));
	}
}


.make-sm-column-pull(@columns) {
	@media (min-width: @OLD_screen-sm-min) {
		right: percentage((@columns / @OLD_grid-columns));
	}
}


// Generate the medium columns
.make-md-column(@columns; @gutter: @OLD_grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-right: (@gutter * 0.5);
	padding-left: (@gutter * 0.5);

	@media (min-width: @OLD_screen-md-min) {
		width: percentage((@columns / @OLD_grid-columns));
		float: left;
	}
}


.make-md-column-offset(@columns) {
	@media (min-width: @OLD_screen-md-min) {
		margin-left: percentage((@columns / @OLD_grid-columns));
	}
}


.make-md-column-push(@columns) {
	@media (min-width: @OLD_screen-md-min) {
		left: percentage((@columns / @OLD_grid-columns));
	}
}


.make-md-column-pull(@columns) {
	@media (min-width: @OLD_screen-md-min) {
		right: percentage((@columns / @OLD_grid-columns));
	}
}


// Generate the large columns
.make-lg-column(@columns; @gutter: @OLD_grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-right: (@gutter * 0.5);
	padding-left: (@gutter * 0.5);

	@media (min-width: @OLD_screen-lg-min) {
		width: percentage((@columns / @OLD_grid-columns));
		float: left;
	}
}


.make-lg-column-offset(@columns) {
	@media (min-width: @OLD_screen-lg-min) {
		margin-left: percentage((@columns / @OLD_grid-columns));
	}
}


.make-lg-column-push(@columns) {
	@media (min-width: @OLD_screen-lg-min) {
		left: percentage((@columns / @OLD_grid-columns));
	}
}


.make-lg-column-pull(@columns) {
	@media (min-width: @OLD_screen-lg-min) {
		right: percentage((@columns / @OLD_grid-columns));
	}
}
