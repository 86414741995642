/* ----------------------------------------------------------------------------------------------------------*\
		Components

		codename consists of
			.form__input-with-buttons-wrapper
				__action-button...........................Action buttons - edit and copy
				__copy-button.............................Copy to clipboard button
			__footnote?...................................Bottom message
			__oncopy-message?.............................Message shown after codename is copied


\*---------------------------------------------------------------------------------------------------------- */

@codename--dialog-width: (40 * @grid-unit);

.codename__action-button {
	margin-left: @spacing-between-buttons-horizontal;
}

.codename__copy-button {
	border-radius: @border-radius-pill;
}

.codename__footnote {
	.typography-styles-ui-paragraph();

	margin-top: @spacing-m;
	color: @color-text-low-emphasis;
}
