/* ----------------------------------------------------------------------------------------------------------*\
		your-content consists of
		__tab
		__item-name-wrapper?
			__item-name
			__item-language?
\*---------------------------------------------------------------------------------------------------------- */

.your-content {
	min-width: (88 * @grid-unit);
	max-width: (140 * @grid-unit);
	margin: 0 auto;
}

.your-content__item-name-wrapper {
	display: flex;
	overflow: hidden;
	width: 100%;
	height: 100%;
	align-items: center;
	text-overflow: ellipsis;
	white-space: nowrap;

	@media (min-width: @OLD_screen-md-min) {
		display: inline-flex;
		max-width: 100%;
	}
}

.your-content__item-name {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.your-content__item-language {
	margin-left: @spacing-xs;
	color: @color-text-low-emphasis;
}

.your-task__description {
	display: -webkit-box;
	overflow: hidden;
	margin-top: @spacing-s;
	margin-bottom: @spacing-s;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	word-wrap: break-word;
}
