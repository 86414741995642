@import "../mixins/component-loading-indicator.less";

@scroll-table-dropdown-options-max-width: (25.5 * @grid-unit);

@scroll-table-row-min-height: @size-l;
@scroll-table-base-column-width: (4 * @grid-unit);
@scroll-table-row-horizontal-padding: @spacing-s;
@scroll-table-row-padding-right: (@scroll-table-base-column-width + @scroll-table-row-horizontal-padding);
@scroll-table-row-border-radius: @border-radius-m;


.scroll-table {
	width: 100%;
	text-align: left;

	&--fill-available-space {
		display: flex;
		height: 100%;
		min-height: 0;
		flex-direction: column;
	}
}


.scroll-table__title-pane {
	display: flex;
	align-items: center;
	padding: @spacing-xs 0;
}

.scroll-table__undo {
	.scroll-table__title + & {
		margin-left: @spacing-s;
	}
}


.scroll-table__status {
	display: flex;
	align-content: center;
	justify-content: space-between;
	padding: @spacing-s 0 @spacing-l 0;
	transition: all @OLD_transition-150;

	&:empty {
		display: none;
	}
}


.scroll-table__actions {
	position: relative;
	display: flex;
}


.scroll-table__dropdown-option-name {
	max-width: @scroll-table-dropdown-options-max-width;
}


.scroll-table__ordering-mark {
	display: inline;
	margin-top: @spacing-xs;
	margin-left: @spacing-s;
	font-size: @icon-size-xs;

	&--hidden {
		visibility: hidden;

		.scroll-table__column-head:hover & {
			visibility: visible;
		}
	}
}

.scroll-table__ordering-mark-icon {
	font-size: @icon-size-xs;
}

.scroll-table__head-scroll {
	overflow-y: scroll;
	visibility: hidden;
}


.scroll-table__head {
	border-bottom: @border-width-default solid @color-border-low-emphasis;

	.column-settings {
		width: @scroll-table-base-column-width;
	}
}


.scroll-table__head,
.scroll-table__row {
	.typography-styles-label-large();

	display: flex;
	min-height: @size-l;
	flex: 0 0;
	flex-direction: row;
	justify-content: left;
	padding: 0 @scroll-table-row-horizontal-padding;

	a&,
	&--is-clickable {
		cursor: pointer;
	}
}

.scroll-table__row {
	// Important for the "pseudo-expanded" links
	position: relative;
	border-top: @border-width-default solid @color-border-low-emphasis;
	background-color: @color-white;
	overflow-x: hidden;
	transition: background-color @transition-bg-hover;

	&:hover:not(&--is-placeholder):not(&--is-disabled):not(&--is-skeleton) {
		background-color: @color-table-row-background-hover;

		&.scroll-table__row--is-selected {
			background-color: @color-background-selected-hover;
		}
	}

	&--is-highlighted {
		background-color: @color-background-highlighted;
	}

	&--is-selected {
		background-color: @color-background-selected;
	}

	&--is-placeholder,
	&--is-disabled {
		background: @color-background-disabled;
		color: @color-text-disabled;
	}

	&:focus {
		.shadow-focus-styles();
	}

	&--is-skeleton {
		background: @color-skeleton-background;
	}

	.scroll-table__body > :first-child & {
		border-top: none;
	}
}

.scroll-table__row-loading-indicator {
	.component__loading-indicator();
}

.scroll-table__row-select:focus ~ .scroll-table__row {
		position: relative;

		&::before {
			position: absolute;
			// border width
			top: 2px;
			right: 2px;
			bottom: 2px;
			left: 2px;
			border-radius: @border-radius-s;
			content: "";
			.shadow-focus-styles();
		}
}

.scroll-table__row-loading-indicator-pane {
	width: 100%;
	padding: @spacing-xs;
	background-color: @color-white;
}

.scroll-table__column-head {
	color: @color-text-hint;
	transition: background-color @transition-bg-hover, color @transition-bg-hover;

	&--is-clickable {
		border-radius: @border-radius-s;
		cursor: pointer;

		&:hover, &:focus {
			background-color: @color-background-hover;
			color: @color-text-hint-hover;
		}

		&:focus {
			.shadow-focus-styles();
		}
	}

	&--is-selected {
		color: @color-text-hint-selected;
		font-weight: @font-weight-bold;
	}
}


.scroll-table__column-head,
.scroll-table__cell {
	display: flex;
	overflow: hidden;
	width: 100%;
	min-width: @scroll-table-base-column-width;
	flex: 14;
	align-items: center;
	text-overflow: ellipsis;
	white-space: nowrap;

	&:focus-within {
		// Height of border, so shadow when focused is not cut
		margin: 2px 0;
	}

	&:has(&-item--is-checkbox) {
		overflow: unset;
	}
}

.scroll-table__cell {
	color: @color-text-default-on-light;
}


.scroll-table__column-name,
.scroll-table__cell-item {
	display: flex;
	width: 100%;
	max-width: 100%;
	height: 100%;
	align-items: center;
	padding: 0 @spacing-s;
	text-overflow: ellipsis;
	white-space: nowrap;

	&--is-clickable {
		cursor: pointer;
	}

	&--with-hover-effect {
		&:hover {
			background-color: @color-background-hover;
		}
	}

	&--is-disabled {
		color: @color-text-disabled;
		cursor: not-allowed;
	}

	&--is-link {
		text-decoration: none;

		&:focus {
			.shadow-focus-styles();
		}

		&,
		a {
			color: @color-text-default-on-light;

			&:hover,
			&:focus {
				color: @color-text-default-on-light;
				text-decoration: none;
			}
		}
	}

	&--is-disabled-link {
		&,
		a {
			color: @color-text-disabled;
			cursor: not-allowed;
			text-decoration: none;

			&:hover,
			&:focus {
				color: @color-text-disabled;
				cursor: not-allowed;
				text-decoration: none;
			}
		}
	}

	&--is-checkbox {
		padding: 0;
	}

	.scroll-table__column--1 & {
		justify-content: center;
	}
}

.scroll-table__value {
	overflow: hidden;
	text-overflow: ellipsis;
}

.scroll-table__deleted-label {
	display: inline-block;
	padding: 0 @spacing-xs;
	color: @color-alert-text;
	user-select: none;
}

.scroll-table__body-container {
	overflow-x: hidden;
	overflow-y: scroll;

	.scroll-table__row {
		.scroll-table--with-column-settings & {
			padding-right: @scroll-table-row-padding-right;
		}

		&--is-skeleton {
			padding: 0 @spacing-l;
		}
	}

	&--no-scroll {
		padding-right: 0;
		overflow-y: hidden;
	}
}

.scroll-table__body {
	display: flex;
	flex: 0 0;
	flex-direction: column;
}

.cell-item-skelet {
	width: 100%;
}

.cell-item-skelet__pane {
	.color-skeleton-content();

	width: 100%;
	height: @spacing-s;
	border-radius: @border-radius-pill;
}


.scroll-table__column {
	&--1 {
		min-width: @scroll-table-base-column-width;
		max-width: @scroll-table-base-column-width;
		flex: 1;
	}

	&--2 {
		min-width: (@scroll-table-base-column-width * 2);
		max-width: (@scroll-table-base-column-width * 2);
		flex: 2;
	}

	&--3 {
		width: (@scroll-table-base-column-width * 3);
		min-width: (@scroll-table-base-column-width * 3);
		flex: 3;
	}

	&--3-and-half {
		width: (@scroll-table-base-column-width * 3.5);
		min-width: (@scroll-table-base-column-width * 3.5);
		flex: 3.5;
	}

	&--4 {
		width: (@scroll-table-base-column-width * 4);
		min-width: (@scroll-table-base-column-width * 4);
		flex: 4;
	}

	&--4-and-half {
		width: (@scroll-table-base-column-width * 4.5);
		min-width: (@scroll-table-base-column-width * 4.5);
		flex: 4.5;
	}

	&--5 {
		width: (@scroll-table-base-column-width * 5);
		min-width: (@scroll-table-base-column-width * 5);
		flex: 5;
	}

	&--growing-4 {
		flex: 4;
	}

	&--growing-7 {
		flex: 7;
	}

	&--growing-10 {
		flex: 10;
	}

	&--growing-13 {
		flex: 13;
	}

	&--growing-15 {
		flex: 15;
	}
}


.scroll-table__publish-date {
	&--is-unpublished,
	&--is-none {
		color: @color-text-low-emphasis;
	}
}

.scroll-table__item-name {
	display: flex;
	max-width: 100%;
	align-items: center;
}

.scroll-table__item-name-text {
	overflow: hidden;
	text-overflow: ellipsis;

	&--placeholder {
		color: @color-text-disabled;
		font-style: italic;
	}
}

.scroll-table__item-no-workflow-status {
	.typography-styles-title-medium();
	.border-not-translated();

	overflow: hidden;
	padding: @spacing-xs @spacing-m;
	border-radius: @border-radius-pill;
	color: @color-text-default-on-light;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.scroll-table__icon {
	&--is-disabled {
		cursor: not-allowed;
	}
}
