// local variables
@comment-editor-options-border-color: @color-divider-default;
@comment-editor-guideline-color: @color-text-hint;

/* ------------------------------------*\
    Components

    comment-editor consists of
      __form..........................Wrapper for form
      __actions.......................Wrapper for actions
        __switcher....................Switcher between suggestion and comment
        __action......................Individual action

\*------------------------------------ */

.comment-editor__form {
	.typography-styles-body-medium();

	border-bottom-left-radius: inherit;
	border-bottom-right-radius: inherit;
	color: @color-text-default-on-light;

	&--is-on-discussion-tab {
		padding-bottom: @spacing-xl;
	}
}

.comment-editor__reply {
	padding-top: @spacing-l;
	border-top: 1px solid @comment-editor-options-border-color;
	margin-top: @spacing-l;
}

.comment-editor__options {
	padding-top: @spacing-l;
	border-top: 1px solid @comment-editor-options-border-color;
	margin-top: @spacing-l;
}

.comment-editor__guideline {
	.typography-styles-label-medium();

	overflow: hidden;
	margin: @spacing-l 0 @spacing-xs 0;
	color: @comment-editor-guideline-color;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.comment-editor__actions {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: @spacing-s;

	&--is-hidden {
		display: none;
	}

	.comment-editor__form & {
		margin-top: @spacing-l;
	}
}
