@import (inline) "../../../node_modules/react-datepicker/dist/react-datepicker.css";

/* local variables */
@horizontal-line: 1px solid @color-divider-default;
@calendar-wrapper-padding: @spacing-xl;

@datetime-input-icon-datetime-is-disabled-bg-color: @color-background-disabled;

@react-datepicker-header-border-color: @color-divider-default;

@react-datepicker-day-color: @color-text-default-on-light;
@react-datepicker-day-hover-color: @color-text-default-on-light;
@react-datepicker-day-hover-bg-color: @color-background-hover;
@react-datepicker-day-selected-color: @color-text-default-on-dark;
@react-datepicker-day-selected-bg-color: @color-primary;
@react-datepicker-day-selected-hover-bg-color: @color-primary-hover;
@react-datepicker-day-today-border-color: @color-border-default;
@react-datepicker-day-size: (5 * @grid-unit);

@react-datepicker-current-month-color: @color-text-default-on-light;

@react-datepicker-day-name-color: @color-text-hint;

@react-datepicker-day-outside-month-color: @color-text-low-emphasis;

@react-datepicker-navigation-hover-bg-color: @color-background-hover;

@datetime-input-invalid-border-color: @color-alert-active;

@datetime-timezone-color: @color-primary;

.datetime-picker {
	&--inline {
		.datetime-picker__title, .datetime-picker__input {
			padding-bottom: @spacing-l;
		}

		.react-datepicker {
			padding: 0;
			box-shadow: unset;
			
			&__current-month {
				margin-bottom: @spacing-xl;
			}

			&__navigation {
				top: -@spacing-s;
			}
		}
	}
}

.datetime-picker__title {
	.typography-card-headline();

	padding-bottom: @spacing-xl;
	color: @color-text-default-on-light;
	text-align: left;
}

.datetime-picker__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: @spacing-xl;
}

.datetime-picker__navigation {
	display: flex;
	width: @size-xs;
	height: @size-xs;
	align-items: center;
	justify-content: center;
	padding: 0;
	border: none;
	border-radius: @border-radius-s;
	background: transparent;
	cursor: pointer;

	&:hover {
		background-color: @react-datepicker-navigation-hover-bg-color;
	}

	&--previous {
		&::before {
			.typography-styles-font-icon(@icon-size-s);

			content: @icon-chevron-left;
		}
	}

	&--next {
		&::before {
			.typography-styles-font-icon(@icon-size-s);

			content: @icon-chevron-right;
		}
	}

	&[disabled] {
		color: @color-icon-disabled;
		cursor: not-allowed;

		&:hover {
			background-color: @color-transparent;
		}
	}
}


.datetime-picker__current-month {
	.typography-styles-title-large();

	color: @color-text-default-on-light;
}


.datetime-picker__calendar-dropdown-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: @spacing-xl;
}

.datetime-picker__time {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

/**
 * temp hack, because there is a space between guidelines and text, that is removed with default styles. needs investigation, why it is happening during refactoring
 */
.content-item-element__guidelines + .datetime {
	margin-top: -2px;
}

/* react-datepicker overrides */
.react-datepicker-popper {
	z-index: 1000;
}

.react-datepicker-popper[data-placement^="bottom"] {
	margin-top: 0;
}

.react-datepicker__month-container {
	float: none;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
	display: block;
}

.react-datepicker__triangle {
	display: none;
}

.react-datepicker {
	.typography-styles-label-large();

	padding: @calendar-wrapper-padding;
	border: none;
	border-radius: @border-radius-l;
	box-shadow: @box-shadow-l;
}

.react-datepicker__header {
	padding-top: 0;
	padding-bottom: @spacing-s;
	border-bottom: @horizontal-line;
	background-color: transparent;
}

.react-datepicker__day-names,
.react-datepicker__week {
	display: table-row;
	width: 100%;
}

.react-datepicker__day-name {
	.typography-styles-label-medium();

	display: table-cell;
	width: @react-datepicker-day-size;
	color: @react-datepicker-day-name-color;
}

.react-datepicker__month {
	padding-top: @spacing-s;
	margin: 0;
}

.react-datepicker__day {
	.typography-styles-label-large();

	display: table-cell;
	width: @react-datepicker-day-size;
	height: @react-datepicker-day-size;
	border-radius: 50%;
	margin: 0;
	color: @react-datepicker-day-color;
	vertical-align: middle;

	&:hover {
		border-radius: 50%;
		background-color: @react-datepicker-day-hover-bg-color;
		color: @react-datepicker-day-hover-color;
	}

	&--disabled {
		color: @color-text-disabled;
		cursor: not-allowed;

		&:hover {
			background-color: transparent;
			color: @color-text-disabled;
		}
	}
}

.react-datepicker__day--today {
	.typography-styles-label-large();

	border: 1px solid @react-datepicker-day-today-border-color;
}

.react-datepicker__day--outside-month {
	color: @react-datepicker-day-outside-month-color;

	&:hover {
		color: @react-datepicker-day-outside-month-color;
	}
}

.react-datepicker__day--highlighted,
.react-datepicker__day--selected {
	.typography-styles-headline-small();

	border: 1px solid transparent;
	background: @react-datepicker-day-selected-bg-color;
	color: @react-datepicker-day-selected-color;

	&:hover {
		background-color: @react-datepicker-day-selected-hover-bg-color;
		color: @color-white;
	}
}

.react-datepicker__children-container {
	width: auto;
	padding: 0;
	margin: 0;
}