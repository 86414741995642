@content-type-elements-pane-message-color: @color-text-hint;

.vertically-stretched() {
	display: flex;
	min-height: 0;
	flex: 1 0 auto;
	flex-direction: column;
	align-content: stretch;
	align-items: stretch;
}

.centered() {
	align-items: center;
	justify-content: center;
}

.elements-pane-border() {
	.dashed-border(@border-width-default, @color-border-default, @border-radius-element, 10, 3);
}

.content-type-elements-pane {
	.vertically-stretched();

	position: relative;
}

.content-type-elements-pane__elements-list {
	.vertically-stretched();
}

.content-type-elements-pane__bottom-space {
	.vertically-stretched();

	position: relative;

	// We extend the area horizontally across the whole screen width to properly position new element dragged from element library
	&::before {
		position: absolute;
		left: -50vw;
		width: 200vw;
		height: 100%;
		content: "";
	}
}

.content-type-elements-pane__bottom-space-message {
	.typography-styles-action-large();
	.vertically-stretched();
	.centered();

	margin-top: @spacing-xxl;
	margin-bottom: @spacing-l;
	color: @content-type-elements-pane-message-color;
	text-transform: uppercase;

	img {
		max-width: 100%;
	}
}

.content-type-elements-pane__group {
	.vertically-stretched();
	.elements-pane-border();

	padding: @spacing-card;
	border-radius: @border-radius-s;

	.content-group-pane + .content-type-elements-pane & {
		margin-top: @spacing-l;

		&--is-in-group {
			padding-top: (@spacing-card + @spacing-s);
			border-radius: 0 0 @border-radius-m @border-radius-m;
			border-top: none;
			margin-top: (@spacing-l * -1);
		}
	}
}

.content-type-elements-pane__initial-hint-pane {
	.elements-pane-border();
	.vertically-stretched();
	.centered();

	padding: @spacing-card;
	border-radius: @border-radius-m;
}


.content-type-elements-pane__initial-hint-message {
	.typography-styles-action-large();

	margin-bottom: @spacing-xxl;
	color: @content-type-elements-pane-message-color;
	text-align: center;
	text-transform: uppercase;
}
