@content-item-pane-comment-list-width: (42 * @grid-unit); // Keep in sync with ContentItemPaneCommentListWidth in uiConstants.ts
@full-sized-editor-comments-pane-margin: @spacing-xl; // Keep in sync with FullSizedEditorCommentsPaneMargin in uiConstants.ts
@content-item-pane-comment-thread-bottom-margin: @spacing-xxl;

@content-item-pane-border-width: 1px;

// Keep in sync with SpaceTakenByActions in uiConstants.ts
@space-taken-by-actions: (12 * @grid-unit);

.content-item-pane {
	display: grid;
	flex-grow: 1;
	padding: 0 @spacing-main-layout-right 0 @spacing-main-layout-left;
	grid-template-areas: "editing-actions comments scroll" "editing comments scroll";
	grid-template-columns: minmax(0, 1000px) auto auto;
	grid-template-rows: auto 1fr auto;

	@media print {
		max-width: 100%;
	}

	&--in-revisions {
		padding: @spacing-main-layout-top @spacing-main-layout-right @spacing-main-layout-bottom @spacing-main-layout-left;
	}

	&--is-small:not(&--is-canvas-scrollable) {
		&::after {
			// Reserve fake scroll-bar space width because of KCL-8777.
			content: " ";
			grid-area: scroll;
			opacity: 0;
			overflow-y: scroll;
		}
	}

	&--top-spacing {
		margin-top: @spacing-l;
	}

	&--has-reduced-spacing {
		padding: 0;
	}
}

.content-item-pane__canvas {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	align-items: center;
	grid-area: editor;
	overflow-x: hidden;
	overflow-y: auto;
	// Extra top offset for scrolling to prevent caret hiding under the item actions while navigating with the keyboard (key up, page up)
	scroll-padding-top: @space-taken-by-actions;
	//stylelint-disable-next-line
	border: none !important; // canvas element shouldn't have any vertical borders because of the scrollbar detection, see KCL-8777 for more info

	// When detached scrollbars are used, the scrolling is handled by a container higher in the hierarchy
	.canvas__workspace--detached-scrollbars & {
		overflow-x: initial;
		overflow-y: initial;
	}

	@media print {
		overflow: auto;
	}
}

.content-item-pane__revision-pane {
	width: 100%;
	max-width: 1000px;
	margin: 0 auto @spacing-l auto;
}

.content-item-pane__revision-pane-callout {
	display: inline-block;
	margin-bottom: @spacing-3xl;
}

.content-item-pane__actions-pane {
	grid-area: editing-actions;
}

.content-item-pane__main-pane {
	min-width: 0;
	padding-bottom: @spacing-main-layout-bottom;
	grid-area: editing;

	.content-item-pane--has-reduced-spacing & {
		padding-bottom: 0;
	}
}

.content-item-pane__elements-list {
	position: relative;

	&--top-spacing {
		margin-top: @spacing-card;
	}
}

.content-item-pane__comment-thread-list-pane {
	max-width: (@content-item-pane-comment-list-width + @full-sized-editor-comments-pane-margin);

	@media print {
		display: none;
		width: 0;
		max-width: 0;
	}
}

.content-item-pane__comment-thread-list {
	width: @content-item-pane-comment-list-width;

	/* Need to set the bottom margin by adding a pseudo-element
	   so that it works with transform: translate in Firefox and Opera. */

	&::after {
		display: block;
		height: @content-item-pane-comment-thread-bottom-margin;
		content: "";
	}
}


.content-item-pane__comment-pane {
	grid-area: comments;
}

.comment-pane {
	z-index: @z-index-one;
	padding-top: @spacing-main-layout-top;
}
