.language-selection {
	min-width: (30 * @grid-unit);
}

.language-selection-option__tag {
	display: flex;
	height: @dropdown-option-trailing-size;
	align-items: center;
	margin-left: @dropdown-option-trailing-spacing;
}
