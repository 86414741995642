.capability-rules__pane {
	display: flex;
	flex-direction: column;

	&--expanded {
		animation-duration: 800ms;
		animation-name: capability-rules-expansion;
		animation-timing-function: ease-in-out;
	}
}

.capability-rules__title {
	margin-bottom: @spacing-m;
}

.capability-rules__section {
	display: flex;
	flex-direction: column;
}

.capability-rules__rule {
	display: inline-flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: space-between;
	margin-bottom: @spacing-m;
	pointer-events: none;

	&--expanded {
		animation-duration: 800ms;
		animation-name: capability-rules-expansion;
	}

	& > * {
		pointer-events: all;
	}

	@media (max-width: @OLD_screen-sm-max) {
		pointer-events: all;
	}
}

.capability-rules__rule-part {
	display: block;
	max-width: calc(((100% - @btn-square-width) / 3));
	flex-basis: calc(((100% - @btn-square-width) / 3));
	padding-right: @spacing-m;
	margin-bottom: @spacing-m;

	@media (max-width: @OLD_screen-sm-max) {
		max-width: 46%;
		flex-basis: 46%;
	}

	@media (max-width: @OLD_screen-xs-max) {
		max-width: 90%;
		flex-basis: 90%;
	}
}

.capability-rules__empty-message {
	.typography-styles-ui-paragraph();

	margin-bottom: @spacing-l;
	color: @color-text-hint;
}

@keyframes capability-rules-expansion {
	from {
		max-height: 0;
		opacity: 0;
	}

	to {
		max-height: 5000px;
		opacity: 1;
	}
}

.role-editor__cards-pane {
	display: flex;
	flex-wrap: wrap;
	margin-right: (@spacing-card * -1);
	margin-bottom: (@spacing-card * -1);
}

.role-editor__card {
	display: flex;
	flex: 0 0 100%;
	padding-right: @spacing-card;
	padding-bottom: @spacing-card;

	&--has-full-width {
		min-width: 100%;
	}

	@media (min-width: @OLD_screen-sm-min) {
		flex: 0 0 50%;
	}
}

.role-editor__message {
	margin-bottom: @spacing-l;
}
