.trial-activation {
	display: flex;
	flex-direction: column;
	row-gap: @spacing-l;
}

.trial-activation__contact-us {
	.typography-styles-ui-paragraph();

	color: @color-text-default-on-light;
}
