.subscription-accept-terms-of-service {
	max-width: (105 * @grid-unit);
	padding-top: (15 * @grid-unit);
	padding-bottom: @spacing-main-layout-bottom;
	margin: 0 auto;
	color: @color-text-default-on-light;
}

.subscription-accept-terms-of-service__header {
	.typography-styles-headline-large();

	margin: 0;
}

.subscription-accept-terms-of-service__message {
	.typography-styles-ui-paragraph();

	overflow-wrap: break-word;
}

.subscription-accept-terms-of-service__error-message {
	.typography-styles-ui-paragraph();

	color: @color-alert-text;
}
