@tag-bg-color: @color-gray60;

.tag {
	.typography-styles-title-medium();

	display: inline-block;
	overflow: hidden;
	max-width: 100%;
	height: @size-tag-height;
	padding: @spacing-xs @spacing-m;
	border-radius: @border-radius-pill;
	background-color: @tag-bg-color;
	color: @color-text-default-on-dark;
	cursor: inherit;
	text-decoration: none;
	text-overflow: ellipsis;
	transition: all @OLD_transition-150;
	user-select: none;
	vertical-align: bottom;
	white-space: nowrap;

	& + & {
		margin-left: @spacing-xs;
	}

	.tag--color(@colorname, @color, @background-color) {
		&--@{colorname},
		&--@{colorname}:focus {
			background-color: @background-color;
			color: @color;
			outline: none;
			text-decoration: none;
		}

		&--@{colorname}.tag--is-clickable {
			&:hover {
				background-color: darken(@background-color, 7%);
				color: @color;
			}
		}
	}

	.foreach-tag-color({
		.tag--color(@colorname, @color, @background-color)
	});

	&--is-clickable {
		cursor: pointer;
		text-decoration: none;
	}

	&--is-sticky {
		flex: none;
		margin-left: @spacing-s;
	}
}

.tags {
	display: flex;
	overflow: hidden;
	width: 100%;
	max-width: 100%;
	align-items: center;
	text-overflow: ellipsis;
	white-space: nowrap;

	&--in-popover-link {
		display: inline; // with display:flex the texts inside of the tags inherit text-decoration that cannot be removed
	}
}

.tag + .tag-icon {
	margin-left: @spacing-xs;
}

.beta-tag {
	.typography-styles-caption();

	position: relative;
	top: (@spacing-xs * -1);
	height: @font-size-xs;
	padding: 0 @spacing-xs;
	text-transform: uppercase;
}
