// local variables
@workflow-step--color-label-size: @spacing-l;
@workflow-step--color-picker-size: @size-xs;
@workflow-step--header-height: @size-xl;


.workflow-step__color-label-pane {
	display: flex;
	align-items: center;

	&:first-child {
		margin-left: @spacing-3xl;
	}
}


.workflow-step__color-label {
	display: flex;
	width: @workflow-step--color-label-size;
	height: @workflow-step--color-label-size;
	border-radius: @border-radius-pill;

	.workflow-step__color-label-pane & {
		width: @icon-size-s;
		height: @icon-size-s;
	}
}


.workflow-step__color-picker {
	padding-top: @spacing-xl;
}


.workflow-step__color-picker-list {
	padding-top: @spacing-s;
	padding-left: 0;
	margin: 0;
}

.workflow-step__color-picker-item {
	display: inline-flex;
	width: @workflow-step--color-picker-size;
	height: @workflow-step--color-picker-size;
	align-items: center;
	justify-content: center;
	border-radius: @border-radius-pill;
	margin-right: @spacing-xs;
	margin-bottom: @spacing-xs;
	cursor: pointer;
	list-style-type: none;

	&.workflow-step__color-picker-item--is-selected::before {
		.typography-styles-font-icon(@spacing-l);

		position: absolute;
		content: @icon-cb-check-sign;
		cursor: default;
	}

	&:hover {
		// Leveraging multiple backgrounds to overlay @color-background-hover on top of the background-color
		background-image: linear-gradient(0deg, @color-background-hover, @color-background-hover);
	}
}

.workflow-step__text-label {
	.typography-styles-title-medium();

	display: flex;
	align-items: center;
	color: @color-text-default-on-light;
}
