@content-item-sidebar-form-vertical-rhythm: @spacing-s;
@content-item-sidebar-horizontal-padding: @spacing-xxl;
@content-item-sidebar-background-color: @color-white;

.content-item-pane__sidebar-placeholder {
	position: relative;
	width: @size-sidebar-placeholder-width;
	background: @color-white;
	border-top-left-radius: @border-radius-sidebar;
	box-shadow: @box-shadow-s;
	cursor: pointer;
	grid-area: sidebar;
}

.content-item-pane__sidebar {
	.border-low-emphasis();

	position: absolute;
	z-index: @z-index-eight-hundred;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	border-right: none;
	border-bottom: none;
	background-color: @content-item-sidebar-background-color;
	border-top-left-radius: @border-radius-sidebar;
	box-shadow: @box-shadow-sidebar;
	grid-area: sidebar;
}

.content-item-pane__sidebar-pane {
	width: @size-sidebar-width;
	padding: @spacing-xl 0 @sidebar-padding-bottom;
	overflow-x: hidden;
	overflow-y: auto;
}

.content-item-pane__sidebar-btn() {
	position: absolute;
	border: none;
	background: @color-white;
	box-shadow: @box-shadow-s;
	transform: translate(-50%, -50%);
}

.content-item-pane__sidebar-btn-toggle {
	.content-item-pane__sidebar-btn();

	z-index: @z-index-seven-hundred;
	top: 50%;
}

.content-item-pane__sidebar-btn-pin {
	.content-item-pane__sidebar-btn();

	z-index: @z-index-eight-hundred;
	top: calc((50% + @spacing-s + @size-s));
}

.content-item-sidebar__icon-edit {
	.typography-styles-font-icon(@icon-size-s);

	display: flex;
	min-width: @size-xs;
	min-height: @size-xs;
	align-items: center;
	justify-content: center;
	border-radius: @border-radius-s;
	margin-right: (@spacing-xl * -1);
	margin-left: auto;
	background-color: @color-background-hover;
	color: @color-text-default-on-light;
	visibility: hidden;

	&:active {
		background: @color-background-selected;
		color: @color-primary;
	}
}

.sidebar__section-item--is-clickable:hover,
.sidebar__section-item--is-clickable:focus {
	.content-item-sidebar__icon-edit {
		visibility: visible;
	}
}

.sidebar__section-item--is-disabled {
	cursor: not-allowed;
}

/* ----------------------------------------------------------------------------------------------------------*\
		#content-item-sidebar__task
\*---------------------------------------------------------------------------------------------------------- */
.content-item-sidebar__tasks-list {
	padding: 0;
	margin: @spacing-s 0 @spacing-xs 0;
	list-style-type: none;
}

.content-item-sidebar__task {
	display: flex;
	width: 100%;
	cursor: pointer;
	transition: background-color @transition-bg-hover;

	&:hover:not(:has(.content-item-sidebar__task-checkbox:hover)) {
		background: @content-item-usage-hover-bg-color;

		.content-item-sidebar__icon-edit {
			visibility: visible;
		}
	}

	&--highlighted {
		animation-delay: 1s;
		animation-direction: normal;
		animation-duration: 0.5s;
		animation-fill-mode: both;
		animation-iteration-count: 1;
		animation-name: task-highlighting;
		animation-timing-function: ease-in;
	}
}


@keyframes task-highlighting {
	0% {
		background-color: @color-background-highlighted;
	}

	50% {
		background-color: @color-yellow30;
	}

	100% {
		background-color: @color-background-highlighted;
	}
}

.content-item-sidebar__edit-task-form {
	width: 100%;
	padding: 0 @content-item-sidebar-horizontal-padding;
}

.content-item-sidebar__task-description {
	display: flex;
	width: 100%;
	align-items: flex-start;
	padding: @spacing-s @spacing-xxl @spacing-s 0;
	cursor: pointer;
}

.content-item-sidebar__task-description-text {
	.typography-styles-body-medium();

	color: @color-text-default-on-light;

	&--completed {
		color: @color-text-low-emphasis;
		text-decoration: line-through;
	}
}

.content-item-sidebar__tasks-control {
	padding: 0 @content-item-sidebar-horizontal-padding;
	margin-bottom: @spacing-l;
}
