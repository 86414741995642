/* ----------------------------------------------------------------------------------------------------------*\
		Components

		linked-item consists of
			__elements...................Wrapper for expanded linked item elements
\*---------------------------------------------------------------------------------------------------------- */

.linked-item {
	overflow: hidden;
	border-radius: @border-radius-bar-item;
	margin-left: @rte-sleeve-caret-block-width;
	background-color: @color-background;

	&--is-expanded {
		box-shadow: inset 0 0 0 1px @color-border-low-emphasis;

		// We only handle larger expanded linked item spacing for linked item in modular content element
		// RTE handles it differently in ExpandedBlockStyles.tsx
		.modular-content__items &:not(.linked-item--is-dragging) {
			margin-bottom: @spacing-xl;
		}
	}

	&--is-dragging {
		.drag-placeholder();
	}

	&:not(&--is-top-level) {
		width: calc(100% + @rte-linked-item-extra-space - @rte-sleeve-caret-block-width);

		.content-item-pane--is-small &,
		.floating-editor & {
			width: calc(100% + @rte-linked-item-extra-space-small - @rte-sleeve-caret-block-width);
		}
	}

	&--is-not-allowed {
		.forbidden-object();
	}
}

.expanded-linked-item {
	overflow: hidden;
	border-radius: 0 0 @border-radius-bar-item @border-radius-bar-item;

	.rte--has-focus &--is-highlighted {
		background-color: @color-background-block-selection;

		.content-item-guidelines,
		.content-item-element__wrapper,
		.content-group-tab,
		.bar-item__bar,
		.content-component,
		.asset-thumbnail__actions-pane,
		.asset-thumbnail__bottom {
			background: @color-background-block-selection;
		}

		.content-item-element--is-disabled {
			background: transparent;
		}
	}

	.content-component {
		&--is-top-level {
			border-right: none;
		}
	}
}

.expanded-linked-item__elements {
	background-color: @color-background-low-emphasis;
}
