.status {
	display: flex;
	margin-left: @spacing-m;
	.typography-styles-label-large();

	color: @color-text-low-emphasis;

	&:first-child {
		margin-left: 0;
	}

	&::before {
		.typography-styles-font-icon(@font-size-l);

		padding-right: @spacing-s;
		color: @color-warning-icon;
		content: @icon-small-dot-centered;
	}

	&--is-ready,
	&--filled-correctly {
		color: @color-success-text;

		&::before {
			color: @color-success-icon;
			content: @icon-check-circle;
		}
	}

	&--is-all-done {
		color: @color-text-low-emphasis;

		&::before {
			color: @color-text-low-emphasis;
			content: @icon-cb-check-preview;
		}
	}

	&--filled-correctly {
		color: @color-success-text;

		&::before {
			color: @color-success-icon;
			content: @icon-cb-check-preview;
		}
	}

	&--is-missing {
		color: @color-warning-text;

		&::before {
			content: @icon-exclamation-triangle;
		}
	}

	&--filled-incorrectly {
		color: @color-warning-text;

		&::before {
			content: @icon-exclamation-triangle;
		}

		&.status--validation-failed {
			color: @color-alert-text;
		}
	}

	&--validation-failed {
		color: @color-alert-text;

		&::before {
			color: @color-alert-icon;
			content: @icon-exclamation-triangle-inverted;
		}
	}
}
