/* ----------------------------------------------------------------------------------------------------------*\
		Components


		callout is composed of:
			__title................................................Title describing message type
			__title-icon...........................................Title icon
			__subtitle.............................................Message title
			__message..............................................The Message
\*---------------------------------------------------------------------------------------------------------- */

.callout {
	.typography-styles-ui-paragraph();

	max-width: 1000px;
	padding: @spacing-card;
	border-radius: @border-radius-l;
	color: @color-text-default-on-light;

	@media print {
		display: none;
	}

	& + & {
		margin-top: @spacing-xl;
	}

	&--is-info {
		border: 1px solid @color-info-icon;
		background: @color-info-background;
	}

	&--is-warning {
		border: 1px solid @color-warning-icon;
		background: @color-warning-background;
	}

	.comment-thread-list + & {
		margin-top: @spacing-l;
	}

	&--is-in-projects {
		margin-bottom: @spacing-xl;
	}
}

.callout__title {
	.typography-styles-subheadline();

	display: inline-flex;
	align-items: center;
	margin-bottom: @spacing-l;
	color: @color-text-default-on-light;

	.callout--is-info & {
		color: @color-info-text;
	}

	.callout--is-warning & {
		color: @color-warning-text;
	}
}

.callout__title-icon {
	margin-right: @spacing-s;
}

.callout__message {
	.typography-styles-ui-paragraph();

	color: @color-text-default-on-light;

	.btn-wrapper {
		margin-top: @spacing-l;
	}
}

.callout__subtitle {
	.typography-styles-headline-small();

	margin-bottom: @spacing-l;
	color: @color-text-default-on-light;

	// Override global p + p selector
	& + p {
		margin-top: 0;
	}
}

.callout__text-block + .callout__text-block {
	margin-top: @spacing-l;
}

.callout__user {
	.typography-styles-label-large();

	padding: @spacing-s @spacing-m;
	border-radius: @border-radius-pill;
	background-color: @color-primary;
	color: @color-text-default-on-dark;
	line-height: @line-height-xxl;
}
