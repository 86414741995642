@transactions-summary-bg-color: @color-white;
@transactions-title-color: @color-text-default-on-light;
@transactions-value-negative-color: @color-alert-text;

.transactions__summary {
	.border-low-emphasis();

	display: inline-flex;
	overflow: auto;
	padding: @spacing-card;
	border-radius: @border-radius-card;
	margin-bottom: @spacing-xl;
	background: @transactions-summary-bg-color;
}


.transactions__period-container {
	margin-right: @spacing-6xl;
}


.transactions__summary-title {
	.typography-styles-label-large();

	margin-bottom: @spacing-s;
	color: @transactions-title-color;
}


.transactions__balance-title {
	.typography-styles-label-large();

	margin-bottom: @spacing-s;
	color: @transactions-title-color;
}


.transactions__summary-balance-content {
	.typography-styles-headline-large();

	display: flex;
	justify-content: flex-end;
	color: @color-text-default-on-light;
}


.transactions__summary-content {
	.typography-styles-headline-large();

	color: @color-text-default-on-light;
}

.transactions__value--negative .data-table__cell-item-content {
	color: @transactions-value-negative-color;
}


.transactions__item-value .data-table__cell-item-content {
	justify-content: flex-end;
}


.transactions__amount .data-table__column-name {
	justify-content: flex-end;
}
