// ***********************************
// component variables
// *****************************************************************************
@header-width: (24 * @grid-unit);

// ***********************************
// main component class
// *****************************************************************************
.header__logo {
	width: @header-width;
	margin-top: (@spacing-l + @spacing-xl);
	margin-bottom: @spacing-l;
}
