@subscription-gap: @spacing-xl;

.subscription-billing-information {
	display: flex;
	flex-grow: 0;
	flex-wrap: wrap;
	align-items: flex-start;
	gap: @subscription-gap;
;

	.card__pane {
		flex-basis: calc(60% - (1/2 * @subscription-gap));
	}
}

.credit-card {
	flex-basis: calc(40% - (1/2 * @subscription-gap));
}

.credit-card__message {
	margin-bottom: @spacing-xl;
}

.credit-card__quick-tip {
	margin-top: @spacing-l;
	margin-bottom: @spacing-l;
}

.billing-information-details__quick-tip {
	margin-top: @spacing-l;
	margin-bottom: @spacing-l;
}

.payment-method__update-button {
	margin-top: @spacing-between-buttons-vertical;
}
