@import "../mixins/component-loading-indicator.less";

@bar-item-expansion-actions-top-padding: (@spacing-xxl - @spacing-bar-item-expansion);
@bar-item-expansion-box-shadow-m-bottom-mask: 0 @grid-unit @color-bar-item-background-expansion;
@bar-item-expansion-box-shadow-m-top-mask: 0 (@grid-unit * -1) @color-bar-item-background-expansion;

.bar-item-pseudo-element-border() {
	.border-low-emphasis();

	position: absolute;
	border-radius: @border-radius-bar-item;
	content: "";
	inset: 0;
	pointer-events: none;
}

/* ----------------------------------------------------------------------------------------------------------*\
		Components


		bar-item consists of
			ul.&__list...............................................Wrapper for list of bar items
				li.&__section-title?.................................Section title could be used to divide the list into sections
				li.&__node+..........................................Individual node of a bar-item
					__wrapper........................................Wrapper for the individual bar-item
						__pane.......................................Contains the bar-item and gives it the look
							__bar-loader-pane?.......................Contains bar-loader and ensures correct positioning
								__bar-loader?........................Loader that is displayed bellow the bar item
							__bar....................................Ensures correct positioning and size
								__bar-pane*..........................Defines columns with the same width within the bar item list
									__actions-pane?..................Left side actions
										__action+....................Individual action
									__title-pane.....................Section between action panes
										__title......................Title
											__title-name.............Text section wrapper
												__title-name-text....Text section - Layers necessary for ellipsis to work
										__title-label?...............Label section
										__tags-pane?.................Section between action panes
											__tag+...................Contains the labels
									__actions-pane?..................Right hand side actions
										__action+....................Individual action
								__skelet-pane?.......................Shows skeleton
							__alert-pane?............................Alerts and errors
								__alert-message......................One alert/error message
						__outer-actions-pane?........................Actions ouf of the physical bar-item
							__outer-action+..........................Individual action
				ul.&__list?..........................................Wrapper for a nested list of bar items
\*---------------------------------------------------------------------------------------------------------- */

.bar-item__list {
	padding-left: 0;
	margin: 0;
	list-style-type: none;

	.bar-item__list,
	.bar-item + & {
		padding-top: @spacing-bar-item-vertical;
		padding-left: @spacing-l;
	}

	&:not(:empty) + & { //:not(:empty) is there because component renders extra empty div above
		margin-top: @spacing-bar-item-vertical;
	}
}


.bar-item__list-actions {
	margin-top: @spacing-xl;
}


.bar-item__drag-block {
	& + & {
		margin-top: @spacing-bar-item-vertical;
	}
}


.bar-item__section-title {
	.typography-styles-headline-medium();

	margin: @spacing-xl 0 @spacing-m 0;
	color: @color-text-default-on-light;
}


.bar-item__node {
	.bar-item__node + & {
		margin-top: @spacing-bar-item-vertical;
	}

	&--with-top-margin {
		margin-top: @spacing-bar-item-vertical;
	}
}


.bar-item__wrapper {
	line-height: 1;

	&--is-flexible {
		display: flex;
	}

	// Prevent phantom hovers by disabling pointer events while something is dragging in the list
	.bar-item__list--is-dragging &,
	.rte--is-dragging & {
		pointer-events: none;
	}
}


.bar-item__skelet-pane {
	width: 100%;
	height: @spacing-s;
	border-radius: @border-radius-pill;
	animation: skelet-loader 4s ease infinite;
	.color-skeleton-content();
}

.bar-item__tag {
	width: fit-content;
	min-width: 0;
	max-width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.bar-item__tag-not-translated-item {
	.typography-styles-title-medium();
	.border-not-translated();

	overflow: hidden;
	padding: @spacing-xs @spacing-m;
	border-radius: @border-radius-pill;
	color: @color-text-default-on-light;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.bar-item__tags-pane {
	display: flex;
	min-width: 0;
	flex-basis: 0;
	flex-grow: 6;
	flex-shrink: 1;

	&--in-modular-item {
		flex-grow: 1;
	}
}


.bar-item__badges-pane {
	display: flex;
	min-width: @size-bar-item-bar;
	min-height: @size-bar-item-bar;
	align-items: center;
	justify-content: flex-end;
	white-space: nowrap;

	&--size-s,
	&--size-xs {
		min-width: initial;
	}
}

.bar-item__status {
	width: 10%;
	min-width: 0;
	flex: 0.5 0 auto;
}

.bar-item__pane {
	position: relative;
	overflow: hidden;
	min-width: 0;
	flex-grow: 1;
	border-radius: @border-radius-bar-item;
	background-color: @color-bar-item-background;
	cursor: default;

	&--has-border-alert {
		.bar-item__expansion {
			border-radius: @border-radius-bar-item;
		}

		.bar-item__expansion--no-actions-bar {
			border-radius: @border-radius-bar-item;
		}
	}

	&--is-dragging {
		.drag-placeholder();
	}

	&--is-expanded {
		box-shadow: inset 0 0 0 1px @color-border-low-emphasis;

		.bar-item__bar-loader-pane {
			height: @size-bar-item-bar-expanded;

			.bar-item__bar-loader {
				height: @size-bar-item-bar-expanded;

				&::after {
					height: @size-bar-item-bar-expanded;
				}
			}
		}
	}
}

.bar-item__expansion {
	padding: @spacing-bar-item-expansion;
}

.bar-item__bar {
	z-index: @z-index-one;

	&::before {
		.bar-item-pseudo-element-border();

		z-index: -1;
		transition: background-color @transition-250;
	}

	&--is-clickable {
		cursor: pointer;

		&:hover {
			&::before {
				background-color: @color-bar-item-background-hover;
			}

			.bar-item__list--is-dragging &,
			.rte--is-dragging & {
				background-color: @color-bar-item-background;
			}
		}
	}

	&--is-disabled {
		cursor: not-allowed;
	}

	&--has-comment {
		&::before {
			border-top: @border-width-commented-element solid @color-comment-background-highlighted;
		}
	}

	&--has-focused-comment {
		&::before {
			border-top: @border-width-commented-element solid @color-comment-background-selected;
		}
	}

	&--is-skeleton {
		align-items: center;
		padding: 0 @spacing-l;
		background: @color-skeleton-background;
	}
}

.bar-item__bar-loader-pane {
	position: absolute;
	top: @spacing-xs;
	width: 100%;
	height: @size-bar-item-bar;
	border-radius: @border-radius-bar-item;

	.bar-item__bar-loader {
		.component__loading-indicator();

		height: @size-bar-item-bar;
		border-radius: @border-radius-bar-item;
		background-color: @color-white;

		&::after {
			height: @size-bar-item-bar;
		}
	}
}

.bar-item__expansion-actions-bar {
	padding: @bar-item-expansion-actions-top-padding @spacing-bar-item-expansion @spacing-bar-item-expansion @spacing-bar-item-expansion;
}


.bar-item__alert-pane {
	padding: @spacing-s @spacing-l;
	border-radius: @border-radius-m;
	margin-top: @spacing-xs;
	background-color: @color-alert-background-inverse;
}


.bar-item__alert-message {
	.typography-styles-label-medium();

	display: block;
	color: @color-text-default-on-dark;
}


.bar-item__outer-actions-pane {
	display: inline-flex;
	height: @size-bar-item-bar;
	margin-left: @spacing-l;
}


.bar-item__outer-action {
	display: flex;
	flex-direction: column;
	justify-content: center;

	& + & {
		margin-left: @spacing-l;
	}

	&--is-hidden {
		visibility: hidden;
	}
}
