// Source: https://stackoverflow.com/questions/12228548/finding-equivalent-color-with-opacity/20747908

// Calculates color which looks like desired_color at the given background, but has the given opacity to allow see through other content
// If real background is different, the result color may be slightly altered as the opacity blends the result from both
.transparent_background_image(@desired_color, @desired_alpha, @background_color: white) {
	@r3: red(@desired_color);
	@g3: green(@desired_color);
	@b3: blue(@desired_color);

	@r2: red(@background_color);
	@g2: green(@background_color);
	@b2: blue(@background_color);

	// r1 = ((r3 - r2 + r2 * a1) / a1)
	@r1: ((@r3 - @r2 + (@r2 * @desired_alpha)) / @desired_alpha);
	@g1: ((@g3 - @g2 + (@g2 * @desired_alpha)) / @desired_alpha);
	@b1: ((@b3 - @b2 + (@b2 * @desired_alpha)) / @desired_alpha);

	// Output result
	background-image: linear-gradient(rgb(@r1 @g1 @b1 / @desired_alpha), rgb(@r1 @g1 @b1 / @desired_alpha));
}
