@onboarding-width-breakpoint: 1100px;

.quickstart__title {
	.typography-styles-headline-large();

	margin-bottom: @spacing-xl;
	color: @color-text-default-on-light;
}

.quickstart__section-title {
	.typography-card-headline();

	margin-bottom: 0;
	color: @color-text-default-on-light;
}

.quickstart__section-subtitle {
	.typography-styles-headline-medium();

	margin-bottom: 0;
	color: @color-text-default-on-light;
}

.quickstart__demo-section {
	margin-bottom: @spacing-xl;
}

.quickstart__onboarding-cards-section {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: @spacing-xl;

	@media screen and (max-width: @onboarding-width-breakpoint) {
		flex-direction: column;
		align-items: center;
	}
}

.quickstart__onboarding-cards-delimiter {
	display: flex;
	min-width: @size-xl;
	align-items: center;
	justify-content: center;

	@media screen and (max-width: @onboarding-width-breakpoint) {
		min-height: @size-xl;
	}
}

.quickstart__onboarding-cards-delimiter-icon {
	.typography-styles-font-icon(@icon-size-m);

	color: @color-icon-default;

	@media screen and (max-width: @onboarding-width-breakpoint) {
		transform: rotate(90deg);
	}
}

.sample-app-section {
	.typography-styles-ui-paragraph();

	justify-content: center;
	color: @color-text-default-on-light;

	&__link {
		.typography-styles-label-large();
	}

	&__icon {
		.typography-styles-font-icon(@icon-size-s);

		margin-right: @spacing-s;
		vertical-align: text-bottom;
	}

	&__sample-site {
		position: relative;
		display: flex;
		width: 100%;
		height: auto;
		justify-content: center;

		&::before {
			content: url(@image-open-sample-website);

			@media screen and (max-width: @OLD_screen-sm-max) {
				content: url(@image-open-sample-website-md);
			}
		}
	}
}

.sample-mobile-app {
	display: flex;
	justify-content: center;
}

.sample-mobile-app__iframe {
	width: 291px;

	&--collapsed {
		height: (40 * @grid-unit);
		transition: height 1s;
	}

	&--expanded {
		height: (76 * @grid-unit);
		transition: height 1s;
	}
}
