@current-usage-selection-bg-color: @color-white;
@current-usage-plan-overview-title-color: @color-text-default-on-light;
@current-usage-table-row-color: @color-text-low-emphasis;
@current-usage--table-border-color: @color-divider-default;
@current-usage-total-price-box-color: @color-white;
@current-usage-total-price-box-bg-color: @color-primary;
@current-usage-plan-overview-price-color: @color-text-default-on-light;
@current-usage-faq-color: @color-text-low-emphasis;

.current-usage__content-is-small {
	max-width: (60 * @grid-unit);
}

.current-usage__section {
	.border-low-emphasis();

	display: flex;
	border-radius: @border-radius-l;
	margin-top: @spacing-xl;
	background-color: @current-usage-selection-bg-color;
}

.current-usage__section-title {
	.typography-styles-headline-large();

	margin-bottom: @spacing-xl;
	color: @color-text-default-on-light;
}

.current-usage__section-paragraph {
	.typography-styles-ui-paragraph();

	color: @color-text-default-on-light;
}

.current-usage__section-redirect {
	margin-top: @spacing-xl;
}


.current-usage__plan-overview-title {
	.typography-styles-label-large();

	padding-bottom: @spacing-l;
	color: @current-usage-plan-overview-title-color;
}


.current-usage__section-content {
	margin: @spacing-card;
}

.current-usage__table {
	width: 100%;
	text-align: left;
}

.current-usage__table-row th {
	.typography-styles-label-medium();

	width: 150px;
	padding: @spacing-xs;
	color: @current-usage-table-row-color;
	text-align: right;
}


.current-usage__table-row + .current-usage__table-row {
	border-top: 1px solid @current-usage--table-border-color;
}


.current-usage__table-head {
	border-bottom: 1px solid @current-usage--table-border-color;
}


.current-usage__table-row {
	.typography-styles-label-medium();

	color: @color-text-default-on-light;
}


.current-usage__row-name {
	display: flex;
	align-items: center;
	padding: @spacing-xs 0;
	white-space: nowrap;
}


.current-usage__row-name-tag {
	margin-left: @spacing-s;
}


.current-usage__row-value {
	padding: @spacing-s @spacing-xs;
	text-align: right;
}


.current-usage__total-price-box {
	width: (19 * @grid-unit);
	flex: 0 1 auto;
	flex-direction: column;
	background-color: @current-usage-total-price-box-bg-color;
	border-bottom-right-radius: @border-radius-l;
	border-top-right-radius: @border-radius-l;
	color: @current-usage-total-price-box-color;
	text-align: center;
}


.current-usage__total-price-label {
	.typography-styles-label-large();
}


.current-usage__total-price-value {
	.typography-styles-headline-large();

	margin-top: @spacing-l;
}


.current-usage__plan-overview-box {
	flex: 1 1 auto;
	flex-direction: column;
	padding: @spacing-card;
}


.current-usage__plan-overview-container {
	display: flex;
	flex-flow: wrap;
	margin-right: (@spacing-l * -1);
	margin-bottom: (@spacing-xl * -1);
}


.current-usage__plan-overview-inner-box {
	flex: 1 1 auto;
	flex-direction: column;
	margin-right: @spacing-l;
	margin-bottom: @spacing-xl;
}


.current-usage__plan-overview-content {
	.typography-styles-title-large();

	color: @color-text-default-on-light;
}


.current-usage__plan-overview-content + .current-usage__plan-overview-content {
	margin-top: @spacing-s;
}

.current-usage__plan-overview-content-action {
	margin-top: @spacing-l;
}


.current-usage__plan-overview-price {
	.typography-styles-title-large();

	margin-left: @spacing-l;
	color: @current-usage-plan-overview-price-color;
	font-weight: @font-weight-regular;
}


.current-usage__faq {
	.typography-styles-label-medium();

	margin-top: @spacing-xl;
	color: @current-usage-faq-color;
	text-align: center;
}


.current-usage__suspended-subscription-information-wrapper {
	margin-right: auto;
	margin-left: auto;
	text-align: center;
}

.current-usage__suspended-subscription-information {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: @spacing-xl;
	margin-bottom: @spacing-xl;
}

.current-usage__suspended-subscription-empty-state {
	min-height: auto;
}

.current-usage__suspended-subscription-questions {
	.typography-styles-label-medium();

	margin-top: @spacing-l;
	color: @color-text-low-emphasis;
}
