@rte-linked-item-extra-space: @content-item-element-horizontal-padding;
@rte-linked-item-extra-space-small: @content-item-element-horizontal-padding-small;
@rte-block-vertical-space: @spacing-xl;

@rte-block-placeholder-border-color: @color-divider-default;

.rte__new-block-placeholder {
	height: @size-l;
	border: dashed @border-width-default @color-border-default;
	border-radius: @border-radius-m;
	margin-left: @rte-sleeve-caret-block-width;
	user-select: none;
	vertical-align: top;
}


.rte__content-module {
	user-select: none;
	vertical-align: top;

	// Prevent selection of content inside the block
	// This must be applied also to all nested elements otherwise FF allows positioning of the caret between nested DIVs
	* {
		user-select: none;
	}

	.bar-item {
		position: relative;
	}
}

.rte__content-component {
	user-select: none;

	// Prevent selection of content inside the block
	// This must be applied also to all nested elements otherwise FF allows positioning of the caret between nested DIVs
	* {
		user-select: none;
	}
}

.content-component__element & {
	width: auto;
	margin-right: -1px;

	&:not(.rte__content--is-disabled) {
		&.rte__content--has-focus {
			border-right: 1px solid @rte-content-focus-border-color;
		}
	}
}

.rte__content-image {
	margin-left: @rte-sleeve-caret-block-width;
	user-select: none;
	vertical-align: top;

	// Prevent selection of content inside the block
	// This must be applied also to all nested elements otherwise FF allows positioning of the cursor between nested DIVs
	* {
		user-select: none;
	}

	.rte__inline-image {
		position: relative;
		display: flex;
	}

	.asset-thumbnail {
		margin: 0;
	}
}

.rte__inline-image .drag-source-element {
	width: 100%;
}
