// ****
// Readonly secret input
///////////////////////////

.readonly-secret-input__message {
	display: flex;
	justify-content: center;
	line-height: @size-m;

	&--is-loading,
	&--is-internal {
		color: @color-text-low-emphasis;
	}
}
