.listing-language-switcher-dropdown {
	.typography-styles-label-large();

	display: inherit;
	height: @size-m;
	box-sizing: border-box;
	align-items: center;
	padding: 0 @spacing-xl;
	appearance: none;
	color: @color-text-default-on-light;
	cursor: pointer;
	outline: 0;
	transition: border @OLD_transition-250;
	user-select: none;
	white-space: nowrap;

	&:hover {
		background-color: @color-background-hover;
	}

	& .dropdown-selected__pane {
		padding: 0;
	}
}
