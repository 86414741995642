// Default (enabled)
@text-field-bg-color: transparent;
@text-field-value-color: @color-text-default-on-light;
@text-field-placeholder-color: @color-text-hint;

@text-field-button-pane-size: @size-input-height;
@text-field-button-bg-color: transparent;
@text-field-button-color: @color-text-default-on-light;
@text-field-button-hover-bg-color: @color-background-hover;
@text-field-button-hover-color: @color-text-default-on-light;

// Focused (enabled)
@text-field-focus-button-bg-color: transparent;
@text-field-focus-button-color: @color-text-default-on-light;
@text-field-focus-button-hover-bg-color: @color-background-hover;
@text-field-focus-button-hover-color: @color-text-default-on-light;

// Readonly
@text-field-readonly-button-bg-color: transparent;
@text-field-readonly-button-color: @color-text-default-on-light;
@text-field-readonly-button-hover-color: @color-text-default-on-light;

.text-field {
	position: relative;
	display: flex;
	width: 100%;
	background-color: @text-field-bg-color;

	&--is-growing {
		display: inline-flex;
		width: auto;
		max-width: 100%;
	}
}


.text-field__input {
	.form-text-field();

	&--is-multiline {
		height: auto;
		min-height: @size-m;
		resize: none;
	}

	&::placeholder {
		.placeholder();
	}

	&:focus,
	.text-field--has-focus & {
		.form-text-field-focus();
	}

	&:disabled,
	.text-field--is-disabled & {
		border-color: @color-border-disabled;
		background-color: @color-background-disabled;
		color: @color-text-disabled;
		cursor: not-allowed;
		opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
	}

	&:disabled,
	.text-field--is-disabled.text-field--is-disabled-in-content-editor & {
		color: @color-text-default-on-light;
	}

	&[readonly] {
		.form-text-field-readonly();
	}

	.text-field--has-error &,
	.text-field--has-error &:focus {
		border: 1px solid @form-text-field-error-border-color;
		color: @color-alert-text;

		&:hover,
		&:focus {
			border-color: @form-text-field-error-border-color;
		}
	}

	.text-field--has-button & {
		display: flex;
		width: 100%;
		align-items: center;
		padding-right: (@text-field-button-pane-size + @spacing-m);
	}
}

.text-field__button-pane {
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	min-width: @text-field-button-pane-size;
	min-height: @text-field-button-pane-size;
	align-items: center;
}

.text-field__button-icon {
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: @spacing-xs;
	border-radius: @border-radius-s;
	background: @text-field-button-bg-color;
	color: @text-field-button-color;
	cursor: pointer;

	&:hover {
		background-color: @text-field-button-hover-bg-color;
		color: @text-field-button-hover-color;
	}

	.text-field__input:focus + .text-field__button-pane &,
	&:active {
		background-color: @color-background-selected;
		color: @color-primary;
	}

	.text-field__input:focus + .text-field__button-pane &:hover {
		background-color: @text-field-button-hover-bg-color;
	}

	//stylelint-disable-next-line
	.text-field--has-error .text-field__input:focus + .text-field__button-pane & {
		box-shadow: none;
	}

	//stylelint-disable-next-line
	.text-field--is-disabled .text-field__input + .text-field__button-pane &,
	&--is-disabled {
		background-color: transparent;
		color: @color-icon-disabled;
		cursor: not-allowed;
	}
}

.content-component__element {
	.text-field__input {
		max-width: unset;
		flex: auto;
		margin-right: -1px;
	}

	.text-field.text-field--has-button .text-field__input {
		margin-right: 0;
	}
}
