@filter-actions-menu-shadow: @box-shadow-l;
@filter-actions-menu-icon-right-offset: @spacing-s;
@filter-actions-menu-icon-size: @size-menu-item-leading-element;
@filter-actions-menu-leaf-form-bottom-spacing: @spacing-s;
@filter-actions-menu-leaf-form-spacing: @spacing-l;
@filter-actions-menu-leaf-form-width: (38 * @grid-unit);

.filter-actions-menu {
	overflow: visible;
	box-shadow: @filter-actions-menu-shadow;

	& .dropdown-options__pane {
		overflow: visible;
	}
}

.filter-actions-menu__leaf-form {
	.typography-styles-ui-paragraph();

	overflow: visible;
	min-width: @filter-actions-menu-leaf-form-width;
	max-width: @filter-actions-menu-leaf-form-width;
	color: @color-text-default-on-light;
}

.filter-actions-menu-item {
	display: flex;
	align-items: center;
}

.filter-actions-menu-item__icon {
	display: flex;
	width: @filter-actions-menu-icon-size;
	height: @filter-actions-menu-icon-size;
	align-items: center;
	justify-content: center;
	margin-right: @filter-actions-menu-icon-right-offset;
}
