@forbidden-object-border-width: (@border-width-default * 2);

.forbidden-background() {
	background-image: linear-gradient(@color-forbidden-formatting-background, @color-forbidden-formatting-background);
}

.forbidden-object() {
	&::after {
		position: absolute;
		z-index: @z-index-two;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border: solid @forbidden-object-border-width @color-forbidden-content;
		border-radius: @border-radius-m;
		content: "";
		pointer-events: none;

		.drag-preview & {
			content: none;
		}
	}
}

.rte__content {
	&--text-forbidden .rte__top-level-block.rte__text-block {
		&.rte__text-block--is-empty span[data-offset-key] {
			.forbidden-background();
		}

		span[data-text="true"],
		.rte__link {
			color: @color-forbidden-content;
		}
	}

	&--images-forbidden .rte__top-level-block.rte__content-image,
	&--table-images-forbidden .rte__table-cell .rte__content-image {
		.asset-thumbnail {
			.forbidden-object();
		}
	}

	&--tables-forbidden {
		.rte__table-row,
		.rte__table-cell {
			border-color: @color-forbidden-content !important;
		}
	}

	&--components-and-items-forbidden {
		.content-component,
		.bar-item__pane {
			.forbidden-object();
		}
	}

	&--bold-forbidden:not(&--text-forbidden) .rte__top-level-block .rte__bold,
	&--table-bold-forbidden:not(&--table-text-forbidden) .rte__table-cell .rte__bold,
	&--italic-forbidden:not(&--text-forbidden) .rte__top-level-block .rte__italic,
	&--table-italic-forbidden:not(&--table-text-forbidden) .rte__table-cell .rte__italic,
	&--superscript-forbidden:not(&--text-forbidden) .rte__top-level-block .rte__superscript,
	&--table-superscript-forbidden:not(&--table-text-forbidden) .rte__table-cell .rte__superscript,
	&--subscript-forbidden:not(&--text-forbidden) .rte__top-level-block .rte__subscript,
	&--table-subscript-forbidden:not(&--table-text-forbidden) .rte__table-cell .rte__subscript,
	&--code-forbidden:not(&--text-forbidden) .rte__top-level-block .rte__code,
	&--table-code-forbidden:not(&--table-text-forbidden) .rte__table-cell .rte__code,
	&--link-forbidden:not(&--text-forbidden) .rte__top-level-block .rte__link,
	&--table-link-forbidden:not(&--table-text-forbidden) .rte__table-cell .rte__link {
		.forbidden-background();
	}

	&--paragraph-forbidden .rte__top-level-block.rte__paragraph,
	&--table-paragraph-forbidden .rte__table-cell .rte__paragraph {
		span[data-offset-key] {
			.forbidden-background();
		}
	}

	&--heading-one-forbidden .rte__top-level-block.rte__heading-one,
	&--table-heading-one-forbidden .rte__table-cell .rte__heading-one,

	&--heading-two-forbidden .rte__top-level-block.rte__heading-two,
	&--table-heading-two-forbidden .rte__table-cell .rte__heading-two,

	&--heading-three-forbidden .rte__top-level-block.rte__heading-three,
	&--table-heading-three-forbidden .rte__table-cell .rte__heading-three,

	&--heading-four-forbidden .rte__top-level-block.rte__heading-four,
	&--table-heading-four-forbidden .rte__table-cell .rte__heading-four,

	&--heading-five-forbidden .rte__top-level-block.rte__heading-five,
	&--table-heading-five-forbidden .rte__table-cell .rte__heading-five,

	&--heading-six-forbidden .rte__top-level-block.rte__heading-six,
	&--table-heading-six-forbidden .rte__table-cell .rte__heading-six,

	&--ordered-list-forbidden .rte__top-level-block.rte__olist-item,
	&--table-ordered-list-forbidden .rte__table-cell .rte__olist-item,

	&--unordered-list-forbidden .rte__top-level-block.rte__ulist-item,
	&--table-unordered-list-forbidden .rte__table-cell .rte__ulist-item {
		span[data-offset-key] {
			.forbidden-background();
		}
	}
}
