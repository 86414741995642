@import "../tokens/decision/spacing.less";

/* ----------------------------------------------------------------------------------------------------------*\
		Component variables
\*---------------------------------------------------------------------------------------------------------- */
// Chevron-down font-icon in base64 format.
@chevron-down-svg-icon: "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj4KPHBhdGggZD0iTTE2LjAxNiAyNC41MjRsLTE0LjAxNC0xNC4wMTQgMi41MDItMi41MDIgMTEuNTExIDExLjUxMSAxMS41MTEtMTEuNTExIDIuNTAyIDIuNTAyeiI+PC9wYXRoPgo8L3N2Zz4K";
@chevron-down-svg-icon-white: "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iODk2cHgiIGhlaWdodD0iNTI4cHgiIHZpZXdCb3g9IjAgMCA4OTYgNTI4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MC4xICg4ODEzMykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+Y2hldnJvbi1kb3duPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9ImNoZXZyb24tZG93biIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTY0LjAwMDAwMCwgLTI0OC4wMDAwMDApIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNjQuMDAwMDAwLCAyNDguMDAwMDAwKSIgaWQ9IlBhdGgiPgogICAgICAgICAgICAgICAgPHBvbHlnb24gcG9pbnRzPSI0NDggNTI4IDAgODAgODAgMCA0NDggMzY4IDgxNiAwIDg5NiA4MCI+PC9wb2x5Z29uPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=";
@form-dropdown-padding-left: @spacing-s;
@form-text-field-disabled-bg-color: @color-background-disabled;
@form-text-field-error-border-color: @color-alert-active;
@form-label-color: @color-text-default-on-light;
@form-alert-color: @color-alert-text;
@form-group-overlay-bg-color: @color-white;
@form-section-status-failed-color-text: @color-alert-text;
@form-section-status-failed-color-icon: @color-alert-icon;
@form-section-guideline-color: @color-text-low-emphasis;

/* ----------------------------------------------------------------------------------------------------------*\
		Components

		form consists of
			__section...............................Form groups wrapper
				__section-meta........................Wrapper for section identifiers
					__section-title...................Section title
						__section-status..............Section validation result
					__section-guideline...............Section guideline
				__group...............................Wrapper for label and input
					__label...........................Label
					__message.........................Message
					__text-field......................Input text
					__checkbox........................Input check box
					__alert...........................Error message

			__action............................Wrapper for POST action

\*---------------------------------------------------------------------------------------------------------- */

.form {
	.typography-styles-ui-paragraph();

	color: @color-text-default-on-light;
}

.form__group {
	.typography-styles-label-large();

	margin-top: @spacing-xl;
	color: @color-text-default-on-light;

	&--clipboard {
		position: relative;
	}

	&--medium-width {
		min-width: (36 * @grid-unit);
	}

	&:first-child {
		margin-top: 0;
	}

	&--is-compact {
		margin-top: @spacing-s;
	}
}

.form__input-with-buttons-wrapper {
	position: relative;
	display: flex;
	align-items: center;

	&--is-edited {
		flex-wrap: wrap;
	}

	&--message-is-centered {
		justify-content: center;
	}
}


.form__group-overlay {
	.typography-styles-ui-paragraph();

	position: absolute;
	z-index: @z-index-two;
	top: 0;
	left: 0;
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	background: @form-group-overlay-bg-color;
	color: @color-text-default-on-light;
}

.form__section-title {
	display: inline;
	.typography-styles-headline-medium();

	color: @color-text-default-on-light;

	// you can find nested .form__section in Create new webhook screen
	.form__section .form__section & {
		.typography-styles-headline-small();
	}
}

.form__section-status {
	display: inline-flex;
	margin-left: @spacing-s;
	.typography-styles-subheadline();

	&--failed {
		color: @form-section-status-failed-color-text;

		&::before {
			.typography-styles-font-icon(@icon-size-s);

			padding-right: @spacing-s;
			color: @form-section-status-failed-color-icon;
			content: @icon-exclamation-triangle;
		}
	}
}

.form__section-guideline {
	.typography-styles-body-medium();

	margin-top: @spacing-xl;
	color: @form-section-guideline-color;
}

.form__section + .form__section {
	margin-top: @spacing-xl;
}

.form__section-meta {
	margin-bottom: @spacing-xl;
}

.form__label {
	.typography-styles-label-large();

	display: block;
	padding-left: 0;
	margin-bottom: @spacing-element-label;
	color: @form-label-color;

	&--is-bold {
		.typography-styles-headline-small();
	}
}

.form__text-field {
	.form-text-field();

	&:focus {
		.form-text-field-focus();
	}

	&[disabled],
	fieldset[disabled] & {
		border-color: @color-border-disabled;
		background-color: @color-background-disabled;
		color: @color-text-disabled;
		cursor: not-allowed;
		opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
	}

	&[readonly] {
		.form-text-field-readonly();
	}

	// Reset height for `textarea`s
	textarea& {
		height: auto;
	}

	&--has-error {
		border: @border-width-default solid @form-text-field-error-border-color;

		&:hover,
		&:focus {
			border-color: @form-text-field-error-border-color;
		}
	}

	// Use: Suggestions
	&--300 {
		min-height: @size-m;
		resize: none;
	}

	&--not-editable[disabled] {
		border: none;
		background-color: @form-text-field-disabled-bg-color;
		cursor: not-allowed;
	}

	&--with-placeholder {
		&:focus::placeholder {
			.placeholder();

			content: attr(placeholder);
		}
	}
}

input[type="checkbox"]:disabled {
	cursor: not-allowed;
}

.form__alert {
	.typography-styles-title-medium();

	margin-top: @spacing-element-caption;
	color: @form-alert-color;
	overflow-wrap: break-word;
}

.form__info {
	.typography-styles-caption();

	margin-top: @spacing-s;
	color: @color-text-low-emphasis;
}

.form__message {
	.typography-styles-ui-paragraph();

	margin: @spacing-xl 0;
	color: @color-black;
}
