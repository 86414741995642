.change-workflow-step-modal__schedule-date {
	display: flex;
	align-items: center;
}

.change-workflow-step-modal__schedule-info-wrapper {
	display: flex;
	width: @spacing-xxl;
	height: @spacing-xxl;
	align-items: center;
	justify-content: center;
	margin-left: @spacing-s;
}

.change-workflow-step-modal__schedule-info-icon {
	color: @color-text-low-emphasis;
}

.change-workflow-step-modal__workflow-status-selector-section {
	color: @color-text-default-on-light;

	.multi-select__option-name {
		max-width: (26 * @grid-unit);
	}

	.tag {
		height: unset;
		padding: @spacing-s @spacing-m;
		.typography-styles-label-large();
	}
}

.change-workflow-step-modal__schedule-method {
	padding-bottom: @spacing-xs;
	margin-right: @spacing-s;
}

.change-workflow-step-modal__schedule-method-error {
	margin-top: @spacing-xl;
}

.change-workflow-step-modal__change-workflow-step-section {
	display: flex;
	max-width: 100%;
	align-items: center;
	margin-right: @spacing-s;
}

.change-workflow-step-modal__scheduling {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
}
