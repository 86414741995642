.asset-listing {
	display: flex;
	min-width: 0;
	min-height: 0;
	flex: 1 1 auto;
	flex-direction: column;
}

.asset-library {
	display: flex;
	min-width: 0;
	min-height: 0;
	flex: 1 1 auto;
	flex-direction: column;
}

.asset-library__search-phrase,
.asset-library__sections-pane {
	/* Prevent background and focus shadows from getting cropped without any overflow: visible, source: https://stackoverflow.com/a/56579404 */
	padding-right: @spacing-xl;
	padding-left: @spacing-xl;
	margin-right: -@spacing-xl;
	margin-left: -@spacing-xl;
}

.asset-library__search-phrase {
	flex-shrink: 0;
}

.asset-library__asset-content {
	display: flex;
	width: 100%;
	min-height: 0;
	flex-direction: column;
	flex-shrink: 1;
}

.asset-library__sections-pane {
	display: flex;
	flex-direction: column;
	flex-shrink: 1;
	padding-top: @spacing-l;
	padding-bottom: @spacing-xl;
	overflow-x: hidden;
	overflow-y: auto;

	&--no-top-padding {
		padding-top: 0;
	}

	.asset-listing-container & {
		/* Make scrollbar appear at the right edge of screen */
		padding-right: @spacing-main-layout-right;
		margin-right: -@spacing-main-layout-right;
	}
}

.asset-library__section {
	display: flex;
	flex: 1 0 auto;
	flex-direction: column;
	margin: @spacing-l 0 0;

	&:first-child {
		margin-top: 0;
	}
}

.asset-library__section-title {
	.typography-styles-label-large();

	margin-bottom: @spacing-l;
	color: @color-text-default-on-light;
}

.asset-library__section-tiles {
	display: block;
}

.asset-library__uploader-tile {
	.dashed-border(@border-width-default, @color-border-low-emphasis, @border-radius-m, 10, 6);
	.typography-styles-body-medium();

	display: flex;
	height: @asset-thumbnail-in-asset-library-height;
	box-sizing: border-box;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 @spacing-l;
	border-radius: @border-radius-m;
	color: @color-text-default-on-light;
	text-align: center;

	& [class^="icon-"],
	[class*=" icon-"] {
		margin-bottom: @spacing-xl;
		color: @color-ocean-blue70;
		font-size: @icon-size-xl;
	}
}
