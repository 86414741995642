.shadow-content-element {
	width: 100%;
	border-radius: @content-element-border-radius;

	.content-element-wrapper &:not(:last-child) {
		margin-bottom: @spacing-l;
	}

	.content-element-wrapper + .content-type-elements-pane__bottom-space & {
		margin-top: @spacing-l;
	}
}

.shadow-content-element__object {
	.drag-placeholder();

	display: flex;
	width: 100%;
	height: @content-element-height;
	align-items: center;
	justify-content: center;
	padding: @spacing-l @spacing-xl;
	border-radius: @content-element-border-radius;
}

.shadow-content-element__message {
	.typography-styles-action-large();

	margin: 0;
	color: @color-text-hint;
	opacity: 1;
	text-transform: uppercase;
}
