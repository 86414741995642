/* ------------------------------------*\
    Animations
\*------------------------------------ */

/* ------------------------------------*\
    pulse-500

    use for loading states for panes
\*------------------------------------ */

@keyframes pulse-500 {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0.5;
	}
}


.pulse-500 {
	animation-delay: 0s;
	animation-direction: alternate;
	animation-duration: 1.75s;
	animation-iteration-count: infinite;
	animation-name: pulse-500;
	animation-timing-function: ease-in;
}


/* ------------------------------------*\
    pulse-700

    use for ui blocking messages
\*------------------------------------ */
@keyframes pulse-700 {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0.7;
	}
}


@keyframes pulse-700 {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0.7;
	}
}


.pulse-700 {
	animation-delay: 0s;
	animation-direction: alternate;
	animation-duration: 1.75s;
	animation-iteration-count: infinite;
	animation-name: pulse-700;
	animation-timing-function: ease-in;
}


/* --------------------------------------------*\
    spin-500

    use for loading states for inline elements
\*-------------------------------------------- */
@keyframes spin-500 {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(359deg);
	}
}


@keyframes spin-500 {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(359deg);
	}
}


.spin-500 {
	animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-name: spin-500;
	animation-timing-function: linear;
}


.slide-in-50 {
	animation: slide-in-50 0.5s forwards;
}


.slide-out-50 {
	animation: slide-out-50 0.5s forwards;
}


@keyframes slide-in-50 {
	0% {
		transform: translateY(-100%);
	}

	100% {
		transform: translateY(0%);
	}
}


@keyframes slide-out-50 {
	0% {
		max-height: 50px;
		transform: translateY(0%);
	}

	100% {
		max-height: 0;
		transform: translateY(-100%);
	}
}

.action-scaling-animation {
	animation: action-scaling-animation 0.2s ease;
}


@keyframes action-scaling-animation {
	0% {
		transform: scale(0.01);
	}

	100% {
		transform: scale(1);
	}
}


@keyframes skelet-loader {
	0% {
		background-position: 0 50%;
	}

	50% {
		background-position: 100% 50%;
	}

	100% {
		background-position: 0 50%;
	}
}

@keyframes progress-indicator-movement {
	0% {
		left: -22%;
	}

	100% {
		left: 92%;
	}
}

.animation-nudge {
	animation-delay: 4s;
	animation-duration: 6s;
	animation-iteration-count: 2;
	animation-name: shuffle;
	animation-timing-function: linear;
	float: left;
	transform-origin: center center;
}

@keyframes shuffle {
	0% {
		transform: translate(0, 0) scale(1);
	}

	2% {
		transform: translate(2px, -3px) scale(1) rotate(5deg);
	}

	4% {
		transform: translate(0, 0) scale(1) rotate(0deg);
	}

	6% {
		transform: translate(-2px, -3px) scale(1) rotate(-5deg);
	}

	8% {
		transform: translate(0, 0) scale(1);
	}

	100% {
		transform: translate(0, 0);
	}
}


