.ie-warning {
	position: fixed;
	right: 16px;
	bottom: 100px;
	left: 16px;
}


.ie-warning__content {
	position: relative;
	max-width: 700px;
	box-sizing: border-box;
	padding: 16px 100px 16px 16px;
	margin: 0 auto;
	background: @color-alert-background-inverse;
	box-shadow: @box-shadow-m;
	color: @color-text-default-on-dark;
}


.ie-warning__closer {
	position: absolute;
	top: 16px;
	right: 16px;
	display: inline-block;
	width: 20px;
	height: 20px;
	color: white;
	cursor: pointer;
	font-size: 26px;
	line-height: 20px;
	text-align: center;
}
