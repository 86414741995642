.asset-breadcrumbs {
	display: inline-flex;
	align-items: center;
	padding: 0 @spacing-s;
	border-radius: @border-radius-m;
	background-color: @color-white;
	box-shadow: @box-shadow-m;
}

.asset-breadcrumbs__item {
	.typography-styles-label-large();

	display: flex;
	max-width: (25 * @grid-unit);
	align-items: center;
	padding: @spacing-m @spacing-s;
	color: @color-text-low-emphasis;
	cursor: default;

	&--is-clickable {
		cursor: pointer;

		&:hover {
			background-color: @color-background-hover;
		}

		&:active {
			background-color: @color-background-selected;
		}

		&:focus {
			.shadow-focus-styles();
		}
	}

	&--is-first {
		.asset-breadcrumbs__item-icon {
			color: @color-text-low-emphasis;
		}
	}

	&--is-last {
		.typography-styles-label-large-emphasis();

		color: @color-text-default-on-light;

		.asset-breadcrumbs__item-icon {
			color: @color-folder-icon-active;
		}
	}

	&--is-in-tooltip {
		max-width: 100%;
		padding: @spacing-s @spacing-m;
	}
}

.asset-breadcrumbs__item-icon {
	color: @color-folder-icon;
}

.asset-breadcrumbs__item-icon + .asset-breadcrumbs__item-name {
	overflow: hidden;
	padding-left: @spacing-s;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.asset-breadcrumbs__item-separator {
	display: flex;

	i {
		.typography-styles-font-icon(@icon-size-xs);

		color: @color-icon-default;
	}
}

.asset-breadcrumbs__ellipsis-item {
	.typography-styles-font-icon(@icon-size-s);

	padding: @spacing-s;
}

.asset-breadcrumbs__ellipsis-icon {
	color: @color-text-low-emphasis;
	transform: translateY(4px);
}

.asset-breadcrumbs__clickable-tooltip-item {
	cursor: pointer;

	&:hover {
		background-color: @color-background-hover;
	}

	.asset-breadcrumbs__item--is-clickable:hover {
		background: transparent;
	}
}
