.feedback-modal {
	display: flex;
	flex-direction: column;

	&__question {
		margin-bottom: @spacing-s;
		color: @color-text-default-on-light;
		.typography-styles-headline-medium();
	}

	&__question-optional {
		.typography-styles-label-medium();

		margin-left: @spacing-xs;
		color: @color-text-low-emphasis;
	}

	&__supplemental {
		.typography-styles-label-medium();
	}

	&__feedback-section {
		& + & {
			margin-top: @spacing-l;
		}
	}

	&__input {
		.form-text-field();

		&:focus {
			.form-text-field-focus();
		}
	}

	&__thanks-text {
		p {
			margin-bottom: @spacing-xl;
		}

		&--is-embedded {
			text-align: left;
		}
	}
}

.feedback-modal-wrapper {
	width: 100%;
}
