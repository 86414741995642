.web-spotlight-preview {
	width: 100%;
	height: 100%;
}

.web-spotlight-preview__content {
	width: 100%;
	height: 100%;
	text-align: center;
}

.web-spotlight-preview__sandbox {
	width: 100%;
	height: 100%;
	border: none;
}
