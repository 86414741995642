@gradient-angle-to-left: 270deg;
@gradient-angle-to-right: 90deg;
@gradient-angle-to-bottom: 180deg;
@gradient-angle-to-bottom-right: 135deg;


.create-linear-gradient-to-bottom(@color-from, @color-to) {
	background-image: linear-gradient(@gradient-angle-to-bottom, @color-from, @color-to);
}

.create-linear-gradient-to-right(@color-from, @color-to) {
	background-image: linear-gradient(@gradient-angle-to-right, @color-from, @color-to);
}

.create-linear-gradient-to-bottom-right(@color-from, @color-to) {
	background-image: linear-gradient(@gradient-angle-to-bottom-right, @color-from, @color-to);
}

.gradient-gray-dark() {
	.create-linear-gradient-to-bottom(@color-gray70, @color-gray60);
}

.gradient-desk() {
	.create-linear-gradient-to-bottom-right(@color-gray10, @color-ocean-blue20);
}

.gradient-red-dark() {
	.create-linear-gradient-to-bottom(@color-red70, @color-red60);
}

.gradient-yellow-dark() {
	.create-linear-gradient-to-bottom(@color-yellow70, @color-yellow60);
}

.gradient-neon-green-dark() {
	.create-linear-gradient-to-bottom(@color-neon-green70, @color-neon-green60);
}

.gradient-persian-green-dark() {
	.create-linear-gradient-to-bottom(@color-persian-green70, @color-persian-green60);
}

.gradient-sky-blue-dark() {
	.create-linear-gradient-to-bottom(@color-sky-blue70, @color-sky-blue60);
}

.gradient-purple-dark() {
	.create-linear-gradient-to-bottom(@color-purple70, @color-purple60);
}

.gradient-rose-dark() {
	.create-linear-gradient-to-bottom(@color-rose70, @color-rose60);
}

.gradient-gray-light() {
	.create-linear-gradient-to-bottom(@color-gray40, @color-gray30);
}

.gradient-red-light() {
	.create-linear-gradient-to-bottom(@color-red40, @color-red30);
}

.gradient-yellow-light() {
	.create-linear-gradient-to-bottom(@color-yellow40, @color-yellow30);
}

.gradient-neon-green-light() {
	.create-linear-gradient-to-bottom(@color-neon-green40, @color-neon-green30);
}

.gradient-persian-green-light() {
	.create-linear-gradient-to-bottom(@color-persian-green40, @color-persian-green30);
}

.gradient-sky-blue-light() {
	.create-linear-gradient-to-bottom(@color-sky-blue40, @color-sky-blue30);
}

.gradient-purple-light() {
	.create-linear-gradient-to-bottom(@color-purple40, @color-purple30);
}

.gradient-rose-light() {
	.create-linear-gradient-to-bottom(@color-rose40, @color-rose30);
}

.gradient-gray-very-light() {
	.create-linear-gradient-to-right(@color-gray30, @color-gray20);
}

