/* ----------------------------------------------------------------------------------------------------------*\
		Components

		listing-message is composed of
			__title................................................Title describing message type
\*---------------------------------------------------------------------------------------------------------- */

.listing-message {
	display: flex;
	align-items: center;
	padding: @spacing-xs 0;
	padding-right: @spacing-micro;

	.relations-pane & {
		overflow: hidden;
	}
}

.listing-message__title {
	.typography-styles-subheadline-large();

	margin-left: @spacing-l;
	color: @color-text-default-on-light;
	word-break: break-word;

	&--is-headline {
		.typography-styles-headline-large();

		margin: 0 @spacing-l 0 0;
	}

	&--is-selected {
		color: @color-primary;
	}

	&--is-failed,
	&--is-deleted {
		color: @color-alert-text;
	}

	&--is-updated {
		color: @color-success-text;
	}

	.relations-pane & {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.listing-message__undo {
	margin-left: @spacing-s;

	.relations-pane & {
		margin-left: @spacing-l;
	}
}

.listing-message__clear-filter {
	margin-left: @spacing-xl;
}
