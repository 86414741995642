///////////////////////////////////////////////////////////////////
/////////////////////// Used in other files ///////////////////////
///////////////////////////////////////////////////////////////////

@image-open-sample-website-md: "/images/content/website-md.png";
@image-open-sample-website: "/images/content/website.png";

///////////////////////////////////////////////////////////////////
/////////////////// Used in styles further down ///////////////////
///////////////////////////////////////////////////////////////////

@image-email-verified: "/images/warnings/Email-verified.svg";
// @image-uri-access-denied: "/images/warnings/Chest.svg";
@image-welcome-info: "/images/content/welcome-info.svg";
@image-empty-project-placeholder: "/images/rocket.svg";
@image-sample-project-placeholder: "/images/content/plant.svg";
@image-experimental-sample-project-placeholder: "/images/content/icon-experimental.svg";


/* Temporary comment because of KCL-2721, old illustrations will soon be replaced

.warning-notification__image:before {
	content: url(@image-uri-access-denied);
} */

.paper__image--email-verified {
	content: url(@image-email-verified);
}

.react-logo {
	content: url("/images/quickstart/react.svg");
}

.dotnet-logo {
	content: url("/images/quickstart/dotnet.svg");
}

.android-logo {
	content: url("/images/quickstart/android.svg");
}

.swift-logo {
	content: url("/images/quickstart/swift.svg");
}

.js-logo {
	content: url("/images/quickstart/javascript.svg");
}

.vuejs-logo {
	content: url("/images/quickstart/vuejs.svg");
}

.java-logo {
	content: url("/images/quickstart/java.svg");
}

.php-logo {
	content: url("/images/quickstart/php.svg");
}

.angular-logo {
	content: url("/images/quickstart/angular.svg");
}

.ruby-logo {
	content: url("/images/quickstart/ruby.png");
}
