/* ----------------------------------------------------------------------------------------------------------*\
		Component variables
\*---------------------------------------------------------------------------------------------------------- */
@toolset-element-color: @color-text-default-on-light;
@toolset-element-bg-color: @color-white;
@toolset-element-hover-bg-color: overlay(@color-background-hover, @color-white);
@toolset-disabled-element-color: @color-text-disabled;
@toolset-element-icon-color: @color-icon-default;

/* ----------------------------------------------------------------------------------------------------------*\
		Components


		toolset is composed of:
			__title..............................................Title describing toolset elements
			__element-list.......................................Wrapper container around elements
					__element....................................Toolbox element
							__element-pane.......................Toolbox element pane
									__element-icon...............Element icon
									__element-text...............Text describing the element
									__element-tag?...............Placeholder for FeatureState tag
\*---------------------------------------------------------------------------------------------------------- */
.toolset {
	padding: @spacing-l;
	border-radius: @border-radius-m;
	background: @color-background-low-emphasis;
	.border-low-emphasis();

	& + & {
		margin-top: @spacing-l;
	}
}


.toolset__title {
	margin-bottom: @spacing-l;
}

.toolset__element-wrapper {
	& + & {
		margin-top: @spacing-s;
	}
}

.toolset__element {
	.typography-styles-label-large();
	.border-low-emphasis();

	padding: @spacing-s @spacing-m @spacing-s @spacing-m;
	border-radius: @border-radius-m;
	background-color: @toolset-element-bg-color;
	color: @toolset-element-color;
	cursor: pointer;
	text-align: left;
	transition: background-color @OLD_transition-250;
	user-select: none;
	white-space: nowrap;

	&:hover:not(&--disabled) {
		.typography-styles-label-large-emphasis();

		background-color: @toolset-element-hover-bg-color;
		color: @toolset-element-color;
	}

	&--disabled,
	&--disabled:hover {
		background-color: @toolset-element-bg-color;
		color: @toolset-disabled-element-color;
		cursor: not-allowed;

		.toolset__element-icon {
			color: @toolset-disabled-element-color;
		}
	}
}

.toolset__element-pane {
	display: flex;
	width: 100%;
	align-items: center;
}


.toolset__element-icon {
	margin-right: @spacing-s;
	color: @toolset-element-icon-color;
}
