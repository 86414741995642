/* ----------------------------------------------------------------------------------------------------------*\
		Component variables
\*---------------------------------------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------------------------------------------*\
		Components


		taxonomy-element consists of
      __action-pane..............................Wrapper for Expand/Collapse actions
      __list.....................................Wrapper for list of taxonomy terms
      __message..................................Wrapper for messages concerning taxonomy group status
\*---------------------------------------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------------------------------------------*\
		#taxonomy-element
\*---------------------------------------------------------------------------------------------------------- */
.taxonomy-element {
  max-width: 100%;

	.content-component__element & {
		border-right: 0;
	}
}

/* ----------------------------------------------------------------------------------------------------------*\
		#taxonomy-element__message
\*---------------------------------------------------------------------------------------------------------- */
.taxonomy-element__message {
	padding: @spacing-s 0;
	color: @color-text-low-emphasis;

	&--is-loading {
		.pulse-500;
	}
}
