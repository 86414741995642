.role-builder__headers {
	display: flex;
}

.role-builder__header-item {
	flex: 1;
	margin-bottom: @spacing-m;

	&:last-child {
		padding-right: @spacing-3xl;
	}
}

.role-builder__add-button {
	margin-top: @spacing-xl;
}
