.highlighted-flag() {
	--highlighted: 1;
}

.highlighted-flag-reset() {
	--highlighted: unset;
}

.rte__content .rte__entity--is-selected {
	&,
	& + .caret-point {
		background-color: @color-background-text-selection-no-focus;
		.highlighted-flag();
	}
}

.rte--has-focus .rte__content {
	caret-color: @color-text-default-on-light;

	&.rte__content--is-disabled,
	.rte--is-locked & {
		caret-color: transparent;
	}

	.rte__entity--is-selected {
		&,
		& + .caret-point {
			background-color: @color-background-text-selection;
			.highlighted-flag();
		}
	}

	*::selection {
		background-color: transparent;
	}

	span[data-offset-key] *::selection {
		background-color: @color-background-text-selection;
		.highlighted-flag();
	}

	.rte__custom-block-sleeve span[data-offset-key] *::selection {
		background-color: transparent;
		.highlighted-flag-reset;
	}

	.rte__link {
		*::selection {
			color: @color-link;
		}

		&:hover *::selection {
			color: @color-link-hover;
			text-decoration: underline;
		}

		&--is-broken {
			*::selection {
				color: @color-alert-text;
			}

			&:hover {
				*::selection {
					color: @color-alert-hover;
				}
			}
		}
	}
}

// Style for highlighting selection starting or ending at the edge of a block, are activated by just adding content to pseudo-elements
.rte__text-block > div[data-offset-key] {
	&::before,
	&::after {
		.highlighted-flag();

		position: absolute;
		display: inline;
		overflow: hidden;
		width: 0;
		outline: solid 1px @color-background-text-selection;
	}
}

.rte__custom-block-sleeve > div[data-offset-key] {
	position: relative;

	.rte__custom-block-sleeve--is-before&::before,
	.rte__custom-block-sleeve--is-after&::after {
		.highlighted-flag();

		position: absolute;
		top: 0;
		width: 0;
		height: 100%;
		outline: solid 1px @color-background-text-selection;
	}
}

// Chrome may not refresh selection background when DOM selection doesn't change
// This prevents RichTextHighlighter to reset nested selection styles dynamically as highlight is applied asynchronously
// We use a trick with unnoticeable animations to force it to redraw the selected content when needed
// We use two separate animations (even though they look the same) to ensure refresh in both directions
.rte__content {
	.rte__table-cell {
		animation: force-show-nested-selection 1ms;
	}
}

@keyframes force-hide-nested-selection {
	0% { transform: translateZ(0); }
	50% { transform: translateZ(1px); }
	100% { transform: translateZ(0); }
}

@keyframes force-show-nested-selection {
	0% { transform: translateZ(0); }
	50% { transform: translateZ(1px); }
	100% { transform: translateZ(0); }
}
