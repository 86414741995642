@welcome-page-aside-color: @color-text-default-on-dark;
@welcome-page-aside-bg-color: @color-gray90;

.welcome-page {
	width: 100%;
	min-height: 100%;
	transition: transform 670ms ease-out;

	&--project-selector-step-active {
		transform: translateX(-100%);
	}
}

.welcome-page__user-info-step {
	display: grid;
	min-height: 100vh;
	grid-template-columns: 3fr 2fr;

	@media (max-width: @OLD_screen-sm-max) {
		grid-template-columns: 1fr;
	}

	@media (min-width: @OLD_screen-lg-min) {
		grid-template-columns: 2fr 1fr;
	}
}

.welcome-page__project-selector-step {
	position: fixed;
	z-index: @z-index-nine-hundred;
	top: 0;
	left: 0;
	display: none;
	width: 100%;
	height: 100%;
	align-items: flex-start;
	justify-content: center;
	transform: translateX(100%);

	.welcome-page--project-selector-step-active & {
		display: flex;
	}
}

.welcome_page__form-content {
	max-width: (63 * @grid-unit);
	margin: 0 auto @spacing-xl;
}

.welcome_page__form {
	width: 100%;
}


.welcome-page__main {
	padding: 0 @spacing-xl;
}

.welcome-page__aside {
	display: flex;
	justify-content: center;
	padding: (35 * @grid-unit) @spacing-xl 0;
	background-color: @welcome-page-aside-bg-color;
	color: @welcome-page-aside-color;

	@media (max-width: @OLD_screen-md-max) {
		padding-top: (25 * @grid-unit);
	}

	@media (max-width: @OLD_screen-sm-max) {
		display: none;
	}
}

.welcome-page__project-selector-step-background {
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url("/images/content/welcome-info-background-v2.jpg");
	background-repeat: no-repeat;
	background-size: cover;
}
