@floating-editor-vertical-offset: @spacing-s;
@floating-editor-horizontal-offset: @spacing-xl;
@floating-editor-width: 400px;
@floating-comments-pane-margin: @spacing-l; // Keep in sync with FloatingCommentsPaneMargin in uiConstants.ts
@floating-comments-list-width: (@content-item-pane-comment-list-width + @floating-comments-pane-margin);  // Keep in sync with FloatingCommentsListWidth in uiConstants.ts

.floating-editor {
	position: absolute;
	z-index: @z-index-two;
	top: @floating-editor-vertical-offset;
	bottom: @floating-editor-vertical-offset;
	width: @floating-editor-width;

	&--is-on-left {
		left: @floating-editor-horizontal-offset;

		.content-item-pane__comment-thread-list {
			margin-left: @floating-comments-pane-margin;
		}
	}

	&--is-on-right {
		right: @floating-editor-horizontal-offset;
	}

	&--is-hidden {
		opacity: 0;
		pointer-events: none;
	}

	.content-item-element__wrapper:not(.content-item-element__wrapper--is-guideline):not(.content-item-element__wrapper--is-disabled):first-child,
	.content-item-element__wrapper:not(.content-item-element__wrapper--is-disabled):last-child {
		&,
		&::before,
		.content-item-element {
			border-radius: 0;
		}
	}

	.content-item-element__wrapper:has(.content-item-element--is-name) {
		&::before {
			border-top: none;
		}
	}

	.content-item-pane__elements-group > .content-item-element__wrapper:last-child {
		&::before {
			border-bottom: none;
		}
	}
}

.floating-editor__pane {
	.border-low-emphasis();

	display: flex;
	overflow: hidden;
	width: 100%;
	height: 100%;
	flex-direction: column;
	border-radius: @border-radius-m;
	background: @color-background;
	box-shadow: @box-shadow-m;
}

.floating-editor__header-actions {
	display: flex;
	height: (6 * @grid-unit);
	align-items: center;
	justify-content: space-between;
	padding: 0 @spacing-m;
	border-bottom: solid 1px @color-divider-default;
	background-color: @color-white;

	&:last-child {
		border-bottom: none;
	}
}

.floating-editor__header-actions-group {
	display: flex;
	align-items: center;
	gap: @spacing-between-buttons-horizontal;
}

.floating-editor__content-pane {
	flex: 1 1 auto;
	overflow-x: hidden;
	overflow-y: auto;
}

.floating-editor__content {
	margin: 0 (-1 * @border-width-default);

	&:not(&--is-scrollable) {
		border-bottom: solid 1px @content-item-element-border-color;
	}

	> .content-item-element__wrapper:first-child {
		border-top: 0;
	}
}

.floating-editor__comments-pane {
	width: @floating-comments-list-width;

	.comment-thread {
		pointer-events: all;
	}
}
