/* ----------------------------------------------------------------------------------------------------------*\
		Component variables
\*---------------------------------------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------------------------------------------*\
		Components


		taxonomy consists of
			__toolbar...........................Toolbar with list actions
			__list..............................Wrapper for list of taxonomy terms
\*---------------------------------------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------------------------------------------*\
		#taxonomy
\*---------------------------------------------------------------------------------------------------------- */
.taxonomy {
	.border-low-emphasis();

	padding: @spacing-card;
	border-radius: @border-radius-card;
	background-color: @color-white;
}

/* ----------------------------------------------------------------------------------------------------------*\
		#taxonomy__list
\*---------------------------------------------------------------------------------------------------------- */
.taxonomy__list-title {
	.typography-styles-headline-large();

	margin-bottom: @spacing-m;
	color: @color-text-default-on-light;
}
