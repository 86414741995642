.technology-pane__section {
	display: flex;
	flex-direction: column;
	margin-right: (8 * @grid-unit);

	&--last {
		margin-right: 0;
	}
}

.technology-pane__section-title {
	.typography-styles-headline-small();

	margin-bottom: @spacing-l;
	color: @color-text-default-on-light;
}

.technology-pane__link {
	.typography-styles-label-large();

	& + & {
		margin-top: @spacing-m;
	}
}

.technology-pane__container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: stretch;
	justify-items: flex-start;
}

.technology-pane__code {
	&-pane-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: stretch;
		justify-items: flex-start;
	}

	&-pane {
		position: relative;
		display: flex;
		align-items: center;
		padding: @spacing-l;
		border: @border-width-default solid @color-border-disabled;
		border-radius: @border-radius-input;
		margin-right: @spacing-l;
		background-color: @color-background-disabled;
		color: @color-text-default-on-light;

		code {
			font-family: "Source Code Pro";
			font-size: @font-size-m;
			font-weight: normal;
			line-height: @line-height-m;
		}
	}
}

.technology-pane__resources-pane {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-items: center;
}

.technology-pane__code-data {
	opacity: 1;
	transition: opacity 0.3s ease-out;
	vertical-align: middle;
	white-space: pre;

	&--is-hidden {
		opacity: 0;
	}
}

.technology-pane__code-action-label {
	.typography-styles-ui-paragraph();

	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	color: @color-text-default-on-light;
}

