@multi-select-option-hover-bg-color: @color-background-hover;
@option-vertical-padding: (@spacing-s - 1px);

.multi-select {
	display: flex;
	min-height: @size-input-height;
	align-items: center;
	border: @border-width-input solid @color-input-border;
	border-radius: @border-radius-input;
	background-color: @color-input-background;
	cursor: text;
	transition: border @OLD_transition-250;

	&--is-disabled {
		border-color: @color-border-disabled;
		background-color: @color-background-disabled;
		cursor: not-allowed;
	}

	.content-item-element__content &--is-disabled {
		cursor: default;
	}

	&--has-focus {
		border-color: @color-border-active;
		color: @color-text-default-on-light;
		outline: none;
	}

	&--has-error {
		border-color: @color-border-alert;
	}
}

.multi-select__search-field {
	display: flex;
	min-width: 0;
	align-items: center;
	padding-left: @spacing-m;
	border: none;
	word-break: break-all;
}

.multi-select__search-field-placeholder {
	overflow: hidden;
	margin: @spacing-micro @spacing-s 0 (@spacing-s * -1);
	color: @color-text-hint;
	text-overflow: ellipsis;
	white-space: nowrap;

	.multi-select--is-disabled & {
		color: @color-text-disabled;
	}
}

.multi-select__search-text {
	.typography-styles-body-medium();

	min-width: 1px; // Needed to display caret even when there is no text
	margin: @spacing-micro @spacing-s 0 @spacing-xs;
	color: @color-text-default-on-light;

	.content-item-element__content & {
		margin-left: 0;
	}
}


.multi-select__dropdown {
	overflow: hidden;
	max-width: (64 * @grid-unit);
	border-radius: @border-radius-m;
	background-color: @color-white;
	box-shadow: @box-shadow-l;
}

.multi-select__dropdown-options {
	max-height: 40vh;
	padding: @spacing-s 0;
	border-radius: @border-radius-m;
	overflow-x: hidden;
	overflow-y: auto;
}

.multi-select__dropdown-option {
	.typography-styles-label-large();

	display: flex;
	min-width: (25 * @grid-unit);
	align-items: center;
	padding: @spacing-s @spacing-l;
	background-color: @color-white;
	color: @color-text-default-on-light;
	cursor: pointer;

	&--is-highlighted {
		background-color: @multi-select-option-hover-bg-color;
	}

	&--is-selected {
		background-color: @color-background-selected;
		color: @color-primary-selected;

		&.multi-select__dropdown-option--is-highlighted {
			background-color: @color-background-selected-hover;
		}
	}

	&--is-category { // where two options appear highlighted
		.typography-styles-headline-medium();

		padding: @spacing-l @spacing-l @spacing-s @spacing-l;
		color: @color-text-default-on-light;
		cursor: not-allowed;

		&:first-child {
			padding-top: @spacing-s;
		}

		&:not(:first-child) {
			border-top: solid 1px @color-divider-default;
			margin-top: @spacing-s;
		}
	}

	&--is-disabled {
		color: @color-background-disabled;
		cursor: default;
	}

	.multi-select__dropdown-option-color(@colorname, @color, @background-color) {
		&--@{colorname}:before {
			width: @icon-size-m;
			height: @icon-size-m;
			border-radius: 50%;
			margin: @spacing-xs @spacing-m @spacing-xs @spacing-xs;
			background-color: @background-color;
			color: @color;
			content: "";
		}
	}

	.foreach-tag-color({
		.multi-select__dropdown-option-color(@colorname, @color, @background-color)
	});
}

.multi-select__dropdown-user-option {
	flex-direction: column;
	align-items: flex-start;
}

.multi-select__collapsible-dropdown-option {
	display: flex;
	flex-direction: row;
}

@caret-padding: @spacing-s;
@caret-width: @icon-size-s;

.multi-select__dropdown-option-caret {
	padding-right: @caret-padding;
	padding-left: @caret-padding;
	margin-left: (@caret-padding * -1); // make the clicking action area bigger

	&--is-disabled {
		color: @color-text-disabled;
		cursor: not-allowed;
	}
}

.multi-select__dropdown-option-caret-placeholder {
	min-width: (@caret-padding + @caret-width);
}

.multi-select__dropdown-option-name {
	overflow: hidden;
	flex-grow: 1;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.multi-select__dropdown-option-aux {
	.typography-styles-label-small();

	overflow: hidden;
	margin-top: @spacing-xs;
	color: @color-text-low-emphasis;
	text-overflow: ellipsis;

	.multi-select__dropdown-option--is-selected & {
		color: @color-primary-selected;
	}
}


.multi-select__option-wrapper {
	max-width: 100%;
}

.multi-select__option {
	.typography-styles-label-large();

	display: flex;
	max-width: calc((100% - @spacing-between-tags));
	flex-wrap: nowrap;
	border-radius: @border-radius-pill;
	margin: (@spacing-between-tags - @border-width-default) @border-width-default @border-width-default (@spacing-between-tags - @border-width-default);
	background: @color-primary;
	color: @color-text-default-on-light;

	&--is-disabled {
		background-color: @color-background-disabled-complementary;
		cursor: default;
	}

	.multi-select--is-disabled & {
		background-color: @color-background-disabled-complementary;
		cursor: not-allowed;

		&.multi-select__option--red {
			background-color: @color-red60;
			color: #fff;
		}
	}

	.multi-select__option-color(@colorname, @color, @background-color) {
		&--@{colorname} {
			background-color: @background-color;
			color: @color;

			& > .multi-select__option-remove-button {
				padding: @option-vertical-padding @spacing-m @option-vertical-padding @spacing-s;
				color: @color;

				&:hover {
					background-color: darken(@background-color, 10%);
					border-bottom-right-radius: @border-radius-pill;
					border-top-right-radius: @border-radius-pill;
				}
			}

			& > .multi-select__option-name--is-clickable {
				padding: @option-vertical-padding @spacing-m @option-vertical-padding @spacing-l;
				color: @color;

				&:hover {
					background-color: darken(@background-color, 10%);
					border-bottom-left-radius: @border-radius-pill;
					border-top-left-radius: @border-radius-pill;
				}
			}
		}

		&--@{colorname}:hover {
			color: @color;
			transition: all @OLD_transition-150;
		}
	}

	.foreach-tag-color({
		.multi-select__option-color(@colorname, @color, @background-color)
	});
}

.multi-select__option-name {
	.typography-styles-label-large();

	overflow: hidden;
	padding: @option-vertical-padding @spacing-m @option-vertical-padding @spacing-l;
	cursor: default;
	text-overflow: ellipsis;
	vertical-align: middle;
	white-space: nowrap;
	word-wrap: break-word;

	&:last-child {
		padding-right: @spacing-l;
	}

	&--is-clickable {
		cursor: pointer;
	}

	.multi-select__option--is-disabled & {
		cursor: not-allowed;
	}
}


.multi-select__option-remove-button {
	display: inline-flex;
	align-items: center;
	padding: @spacing-xs;
	border-radius: 0 @border-radius-pill @border-radius-pill 0;
	cursor: pointer;
	font-size: @icon-size-s;
}


.multi-select__option-area {
	.typography-styles-body-medium();

	position: relative;
	display: flex;
	width: 100%;
	min-width: 0;
	flex-wrap: wrap;
	padding-bottom: (@spacing-between-tags - (2 * @border-width-default));
	resize: vertical;

	// If there is no expand / collapse action, add padding instead of it to prevent layout changes in case there are no more results matching the search
	&:last-child {
		padding-right: (@icon-size-s + (2 * @spacing-s));
	}

	.content-item-element__content & {
		.typography-styles-body-medium();
	}
}

.multi-select__drop-down-actions {
	align-self: start;
}

.multi-select__drop-down-expand {
	display: flex;
	height: @size-input-height;
	align-items: center;
	justify-content: center;
	padding: 0 (@spacing-input-large + @border-width-input) 0 @spacing-s;
	margin: (@border-width-input * -1) (@border-width-input * -1) (@border-width-input * -1) 0;
	cursor: pointer;

	.multi-select--is-disabled & {
		cursor: not-allowed;
	}
}

.multi-select__drop-down-expand-icon {
	color: @color-icon-default;
	font-size: @icon-size-s;

	.multi-select--is-disabled & {
		color: @color-text-disabled;
	}
}
