@component-loading-indicator-bg-color: @color-divider-default;
@component-loading-indicator-after-bg-color: @color-primary;

.component__loading-indicator() {
	&::after {
		position: absolute;
		display: block;
		width: 30%;
		height: 4px;
		animation: loading-indicator-movement 1.2s infinite alternate ease-in-out, loading-indicator-color 1.2s infinite ease-in-out;
		animation-direction: alternate;
		background-color: @component-loading-indicator-after-bg-color;
		content: "";
	}

	position: relative;
	overflow: hidden;
	width: 100%;
	height: @loader-height;
	border-radius: @border-radius-s;
	background-color: @component-loading-indicator-bg-color;

	@keyframes loading-indicator-movement {
		0% {
			left: -22%;
		}

		100% {
			left: 92%;
		}
	}

	@keyframes loading-indicator-color {
		0% {
			opacity: 1;
		}

		50% {
			opacity: 0.4;
		}

		100% {
			opacity: 1;
		}
	}
}
