.webhook-listing-empty-state__title {
	padding: @spacing-card;
}

.webhook-listing__title {
	padding-bottom: @spacing-xl;
}

.webhook-listing__misconfigured-webhook-callout {
	padding-bottom: @spacing-xl;
}

.webhook-listing__tip {
	max-width: (@canvas-inner-section-restricted-width / 2);
}

.webhook-status {
	cursor: default;
}
