.onboarding-card {
	display: flex;
	overflow: hidden;
	min-width: (35 * @grid-unit);
	max-width: (40 * @grid-unit);
	flex: 1 1 auto;
	flex-direction: column;
	align-items: stretch;
	justify-content: stretch;
	padding: @spacing-card;
	border: @border-width-default solid @color-border-low-emphasis;
	border-radius: @border-radius-card;
	background-color: @color-white;

	@media screen and (max-width: @onboarding-width-breakpoint) {
		width: 100%;
		max-width: 100%;
		flex-direction: row;
	}
}

.onboarding-card__image {
	flex: 0 0 (23 * @grid-unit);
	background-position: center;
	background-repeat: no-repeat;

	@media screen and (max-width: @onboarding-width-breakpoint) {
		flex-basis: (37 * @grid-unit);
	}

	&--model-content {
		background-image: url("/images/quickstart/model-content-card.svg");
	}

	&--create-content {
		background-image: url("/images/quickstart/create-content-card.svg");
	}

	&--deliver {
		background-image: url("/images/quickstart/deliver-card.svg");
	}

	&--find-content {
		background-image: url("/images/quickstart/find-content-card.svg");
	}

	&--create-business-content {
		background-image: url("/images/quickstart/create-content-card.svg");
	}

	&--preview-content {
		background-image: url("/images/quickstart/preview-content-card.svg");
	}
}

.onboarding-card__title {
	.typography-styles-headline-medium();

	margin-bottom: @spacing-l;
	color: @color-text-default-on-light;
}

.onboarding-card__text {
	.typography-styles-ui-paragraph();

	margin-bottom: @spacing-xl;
	color: @color-text-default-on-light;
}

.onboarding-card__content {
	display: flex;
	flex-direction: column;
}

.onboarding-card__body {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: space-between;
	margin-top: @spacing-xl;

	@media screen and (max-width: @onboarding-width-breakpoint) {
		margin-top: 0;
	}
}
