@filter-input-spacing: @spacing-s;
@event-content-padding: @spacing-card;
@event-property-spacing: @spacing-l;

.audit-log__filter__search-area {
	display: flex;
	margin-bottom: @spacing-xxl;
}

.audit-log__filter__clear-button {
	margin-left: @filter-input-spacing;
}

.audit-log__filter__search-phrase {
	flex-grow: 1;
}

.audit-log__filter__selector {
	display: flex;
	overflow: hidden;
	flex: 1;
	flex-flow: column nowrap;
	justify-content: flex-start;
	margin-right: @filter-input-spacing;

	&:last-child {
		margin-right: 0;
	}
}

.audit-log__filter__selector-title {
	.typography-styles-label-large();

	color: @color-text-default-on-light;
	transition: all @OLD_transition-250;
	user-select: none;
	word-break: break-all;
}

.audit-log__event__property {
	margin-bottom: @event-property-spacing;
}

.audit-log__event-properties__table {
	width: 100%;
	text-align: left;
}

.audit-log__event-properties__table + .audit-log__event-properties__table {
	margin-top: @spacing-l;
}

.audit-log-event-properties__row {
	border-bottom: 1px solid @color-divider-default;
}

.audit-log__event-properties__title {
	.typography-styles-headline-small();

	padding-bottom: @spacing-l;
}

.audit-log__event__property-title {
	.typography-styles-label-large();

	width: (20 * @grid-unit);
	padding: @spacing-xs 0;
	color: @color-text-low-emphasis;
}

.audit-log__event__property-value {
	.typography-styles-label-large();

	padding: @spacing-xs 0;
	word-break: break-word;
}

.audit-log__event__property-old-value {
	.typography-styles-label-large();

	padding-left: @spacing-l;
	color: @color-text-low-emphasis;
}

.audit-log__event-title__description-truncated-part {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.audit-log__listing__quick-tip {
	margin-top: @spacing-bar-item-vertical;
}
