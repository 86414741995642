.sample-app-preview-popup-form {
	.typography-styles-headline-medium();

	min-width: 400px;
	color: @color-text-default-on-light;
}

.sample-app-preview-popup-form__steps {
	margin: 0;

	.sample-app-preview-popup-form__step {
		.typography-styles-label-large();

		margin-top: @spacing-l;
		color: @color-text-default-on-light;
		list-style: decimal;
	}
}

.sample-app-preview-popup-form__copy-input {
	margin-top: @spacing-element-label;
}

.sample-app-preview-popup-form__key-copied-label {
	.typography-styles-ui-paragraph();

	display: flex;
	width: 100%;
	height: @size-input-height;
	align-items: center;
	justify-content: space-around;
}
