.name-editor {
	.border-low-emphasis();

	width: 100%;
	padding: @spacing-xl;
	border-radius: @border-radius-card;
	margin-bottom: @spacing-card;
	background-color: @color-white;

	&--is-plain {
		padding: 0;
		border: none;
		border-radius: 0;
		margin-bottom: 0;
		box-shadow: inherit;
	}

	.content-models-name-editor&,
	.snippet-name-editor& {
		position: relative;
		overflow: hidden;

		.content-name-element-border();
	}
}

.name-editor__title {
	.typography-card-headline();

	margin-bottom: @spacing-xl;
	color: @color-text-default-on-light;

	.content-models-name-editor &,
	.snippet-name-editor & {
		.content-name-element-label();

		margin-bottom: @spacing-s;
	}
}

.name-editor__text-field {
	.form-text-field();

	&:focus {
		.form-text-field-focus();
	}

	&--is-disabled {
		.form-text-field-readonly();
	}

	.content-models-name-editor &,
	.snippet-name-editor & {
		.content-name-element-title();

		height: @spacing-xxl;
		border-radius: initial;
	}
}
