@border-radius-input: (@size-m / 2);
@border-width-default: 1px;
@border-width-high-emphasis: (@grid-unit * 0.25);
@border-width-input: @border-width-default;
@border-width-commented-element: (@border-width-default * 4);
@border-width-high-emphasis: (@grid-unit * 0.25);
@border-radius-card: @border-radius-l;
@border-radius-bar-item: @border-radius-m;
@border-radius-sidebar: @border-radius-l;
@border-radius-element: @border-radius-m;

.border-not-translated() {
	.dashed-border(1, @color-gray70, 12, "4 3", 0);
}

.border-low-emphasis() {
	border: @border-width-default solid @color-border-low-emphasis;
}
