.relations-root-item__title {
	font-weight: bold;

	&--is-redacted {
		font-style: italic;
		font-weight: initial;
	}
}

.relations-pane {
	.scroll-table__row,
	.scroll-table__item-name-text {
		&--is-placeholder,
		&--is-disabled,
		&--placeholder {
			background: @color-transparent;
			color: @color-text-disabled;
			cursor: not-allowed;
			font-style: normal;
		}
	}
}
