.rte__content {
	.rte__ai {
		.transparent_background_image(@color-suggestion-background-highlighted, 0.3);

		.rte--is-disabled & {
			.transparent_background_image(@color-suggestion-background-highlighted, 0.3, mix(@color-gray10, @color-ocean-blue20));
		}
	}

	.rte__bold {
		font-weight: bold;
	}

	.rte__italic {
		font-style: italic;
	}

	.rte__code {
		// We use box-shadow to colorize background of the code inline style to allow combination with other backgrounds
		// See HighlightStyle in RichTextHighlighter for more details
		box-shadow: inset 0 0 0 100vw @color-monospace-background;

		& > * {
			font-size: 90%;
		}

		& span[data-text="true"] {
			font-family: "Source Code Pro", monospace;
		}
	}

	.rte__superscript > * {
		position: relative;
		top: -0.25em;
		font-size: 75%;
		letter-spacing: 0.2px;
		line-height: 0;
		vertical-align: baseline;
	}

	.rte__subscript > * {
		position: relative;
		bottom: -0.25em;
		font-size: 75%;
		letter-spacing: 0.2px;
		line-height: 0;
		vertical-align: baseline;
	}
}
