.dialog-viewer {
	position: relative;
	z-index: @z-index-ten-thousand-one-hundred;
	min-height: 100%;
}

.dialog {
	position: fixed;
	z-index: @z-index-nine-hundred;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	padding: @spacing-element-edge-vertical @spacing-element-edge-horizontal;

	& .modal-dialog__header--with-divider {
		border-bottom: @border-width-default solid @color-border-low-emphasis;
	}
}


.dialog__inner {
	display: flex;
	max-width: 100%;
	flex: 1 1 auto;
	flex-flow: column;
	border-radius: @border-radius-l;
	background: @color-white;
	box-shadow: @box-shadow-l;

	.dialog--narrow & {
		width: (70 * @grid-unit);
		flex: 0 1 auto;
		align-self: center;
		margin: 0 auto;
	}
}


.dialog__bar {
	position: relative;
	z-index: @z-index-eight-hundred;
	flex: 0 0 auto;
	padding: @spacing-card;

	&--with-divider {
		border-top: @border-width-default solid @color-border-low-emphasis;
	}
}


.dialog__notification-bar:not(:empty) {
	padding-bottom: @spacing-card;
}


.dialog__actions-pane {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	column-gap: @spacing-m;
}


.dialog__body {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	background-color: @color-white;
	overflow-y: auto;

	.popover__dialog--is-over-column-settings {
		right: @spacing-l;
	}
}
