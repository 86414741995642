.user-modal__title {
	display: flex;
	align-items: center;
}

.user-modal__tag {
	margin-left: @spacing-m;
	line-height: 1;
}

.user-modal__body {
	display: flex;
	min-height: 0;
	flex-direction: column;
}

.user-modal__content {
	position: relative;
	display: flex;
	min-height: 0;
	flex: 1 1 auto;
	flex-direction: column;
}

.user-modal__form {
	display: flex;
	overflow: auto;
	width: 960px;
	max-width: 90vw;
	flex: 1 1 auto;
	flex-direction: column;
	padding: @spacing-xxl;
}

.user-modal__form-inputs-pane {
	padding: @spacing-card;
	border-radius: @border-radius-m;
	background-color: @color-white;
	.border-low-emphasis();

	+ .callout {
		margin-top: @spacing-l;
	}
}

.user-modal__form-inputs {
	display: flex;
	flex-wrap: wrap;
	margin-top: (@spacing-l * -1);
	margin-right: (@spacing-m * -1);
}

.user-modal__form-item {
	width: 50%;
	flex: 0 0 auto;
	padding-right: @spacing-m;

	.user-modal__form-inputs & {
		margin-top: @spacing-l;
	}
}

.invite-modal__invalid-search-text {
	color: @color-alert-text;
}

.user-modal__role-builder-pane + .callout {
	margin-top: @spacing-xl;
}

.user-modal__footer {
	padding: @spacing-xl;
	padding-bottom: 0;
	background-color: @color-white;

	&:empty {
		display: none;
	}

	.callout + .notification-bar {
		margin-top: @spacing-l;
	}
}
