.create-environment-dialog.confirmation-dialog {
	& .modal-dialog__header,
	& .confirmation-dialog__body {
		max-width: none;
	}

	& .modal-dialog__pane {
		min-width: (80 * @grid-unit);
		max-width: (90 * @grid-unit);
	}
}

.create-environment-dialog__content {
	display: grid;
	gap: @spacing-xl;
	grid-template-columns: 1fr 1fr;
}

.create-environment-dialog__form {
	min-width: 0;
}

.create-environment-dialog__form-group {
	& + & {
		margin-top: @spacing-l;
	}
}

.create-environment-dialog__field-description {
	.typography-styles-label-small();

	margin-top: @spacing-s;
}

.create-environment-dialog__message {
	grid-column-end: -1;
	grid-column-start: 1;
}

.create-environment-dialog__hint-message {
	.typography-styles-label-medium();

	text-align: right;
}
