@saved-filters-color: @color-toggle-text;
@saved-filters-horizontal-spacing: @spacing-element-edge-horizontal;
@saved-filters-transition: @transition-bg-hover;
@saved-filters-offset-top: @spacing-l;

@saved-filters-list-vertical-spacing: @spacing-xs;

@saved-filters-list-item-hover-background-color: @color-background-hover;
@saved-filters-list-item-horizontal-spacing: @spacing-between-buttons-horizontal;


@saved-filters-list-item-text-border-radius: @border-radius-m;
@saved-filters-list-item-text-disabled-color: @color-text-disabled;
@saved-filters-list-item-text-horizontal-spacing: @spacing-s;
@saved-filters-list-item-text-selected-bg-color: @color-white;
@saved-filters-list-item-text-selected-color: @color-toggle-text-selected;
@saved-filters-list-item-text-vertical-spacing: @spacing-s;

@saved-filters-show-all-filters-vertical-spacing: @spacing-l;

.saved-filters__list {
	padding: 0;
	margin: @saved-filters-offset-top 0 0;
	list-style: none;

	li + li {
		margin-top: @saved-filters-list-vertical-spacing;
	}
}

.saved-filters__list-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0 @saved-filters-horizontal-spacing;
	color: @saved-filters-color;
	cursor: pointer;
	gap: @saved-filters-list-vertical-spacing;

	&--disabled {
		cursor: not-allowed;
	}

	&:not(&--active):not(&--disabled) {
		&:hover,
		&:focus-within {
			.saved-filters__list-item-text {
				background-color: @saved-filters-list-item-hover-background-color;
			}
		}
	}
}

.saved-filters__list-item-text {
	.typography-styles-label-large();

	position: relative;
	display: inline-block;
	overflow: hidden;
	padding: @saved-filters-list-item-text-vertical-spacing @saved-filters-list-item-text-horizontal-spacing;
	border-radius: @saved-filters-list-item-text-border-radius;
	margin-left: (-1 * @saved-filters-list-item-text-horizontal-spacing);
	color: @saved-filters-color;
	text-overflow: ellipsis;
	transition: background-color @saved-filters-transition;
	white-space: nowrap;

	&::after {
		position: absolute;
		bottom: 0;
		border-bottom: @border-width-high-emphasis solid transparent;
		content: "";
		inset-inline: @saved-filters-list-item-text-horizontal-spacing;
		pointer-events: none;
		transition: border-color @transition-250;
	}

	&--active {
		color: @saved-filters-list-item-text-selected-color;

		&::after {
			border-color: @color-accent;
		}
	}

	&--disabled {
		color: @saved-filters-list-item-text-disabled-color;
	}
}

.saved-filters__edit-item {
	opacity: 0;
	transition: opacity @saved-filters-transition;

	.saved-filters__list-item:focus-within &,
	.saved-filters__list-item:hover &,
	&--active {
		opacity: 1;
	}
}

.saved-filters__show-all-filters {
	padding: 0 @saved-filters-horizontal-spacing;
	margin-top: @saved-filters-show-all-filters-vertical-spacing;
}
