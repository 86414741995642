@mention-presentation-background-color: @color-primary;
@mention-presentation-color: @color-orange60;
@mention-currentUser-color: @color-white;
@mention-position-left: 2px;
@mention-inner-spacing: @spacing-s;

@comment-input-horizontal-padding: @spacing-input-large;
@task-inner-width-in-item-sidebar: (@content-item-pane-comment-list-width - (@spacing-xxl * 2) - @spacing-m);

.mention {
	position: relative;
	display: inline-block;
	margin-left: -4px;
	transform: translateX(2px);
	vertical-align: bottom;

	&--task {
		display: flex;
		margin-left: 0;
		transform: initial;
	}
}

.mention-presentation {
	.typography-styles-body-medium-emphasis();

	top: 0;
	left: @mention-position-left;
	display: block;
	max-width: @task-inner-width-in-item-sidebar;
	color: @mention-presentation-color;
	text-overflow: ellipsis;
	user-select: none;
	white-space: nowrap;

	&--task {
		.typography-styles-body-medium();

		overflow: hidden;
		margin-right: @spacing-s;
		color: @color-primary;
	}

	&--current-user {
		.typography-styles-body-medium();

		padding: 0 (@mention-inner-spacing - (@mention-position-left * 2));
		border-radius: @border-radius-pill;
		background-color: @mention-presentation-background-color;
		color: @mention-currentUser-color;

		&::before,
		&::after {
			content: "\00a0";
		}
	}
}

.mention-presentation--task.mention-presentation--current-user {
	&::before,
	&::after {
		content: "";
	}

	padding: 0 @mention-inner-spacing;
}

.new-mention {
	position: relative;
	color: @mention-presentation-background-color;
}

.new-mention-users {
	max-width: @content-item-pane-comment-list-width;
	user-select: none;

	&:hover {
		cursor: pointer;
	}
}
