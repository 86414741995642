@limitation-category-error-box-shadow: 0 0 0 @border-width-default @color-red60;

.limitation-category {
	width: 100%;
	border-radius: @border-radius-bar-item;
	background-color: @color-white;

	&--has-errors {
		box-shadow: @limitation-category-error-box-shadow, @box-shadow-xs;
	}

	&--is-expanded {
		box-shadow: inset 0 0 0 1px @color-border-low-emphasis;

		&.limitation-category--has-errors {
			box-shadow: @limitation-category-error-box-shadow, @box-shadow-s;
		}
	}

	& + & {
		margin-top: @spacing-bar-item-vertical;
	}
}

.limitation-category__header {
	position: relative;
	z-index: @z-index-one;
	display: flex;
	height: @size-bar-item-bar;
	align-items: center;
	padding: 0 @spacing-l;
	border-radius: @border-radius-bar-item;
	background-color: @color-bar-item-background;

	&::before {
		.bar-item-pseudo-element-border();

		z-index: -1;
		transition: background-color @transition-250;
	}

	&--is-expandable {
		cursor: pointer;

		&:hover {
			background-color: @color-bar-item-background-hover;
		}
	}

	.limitation-category--is-expanded & {
		height: @size-bar-item-bar-expanded;
	}
}

.limitation-category__title {
	display: flex;
	height: 100%;
	flex: 2;
	align-items: center;
	margin-left: @spacing-m;

	&--is-expandable {
		cursor: pointer;
	}
}

.limitation-category__title-icon {
	display: flex;
	align-items: center;
	padding-right: @spacing-s;
	margin: 0 @spacing-micro;
	color: @color-icon-default;
}

.limitation-category__title-text {

	.typography-styles-label-large();

	flex: 2;
	color: @color-text-default-on-light;
	user-select: none;
}

.limitation-category__content {
	padding: 0 @content-element-base-inner-spacing @spacing-xl @content-element-base-inner-spacing;
	cursor: default;
}
