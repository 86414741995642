@facet-title-height: @size-xs;

@facet-between-spacing: @spacing-l;
@facet-horizontal-spacing: @spacing-element-edge-horizontal;
@facet-horizontal-gap: @spacing-s;
@facet-vertical-spacing: @spacing-element-edge-vertical;

@facet-color: @color-text-default-on-light;
@facet-title-deleted-color: @color-alert-text;

@facet-transition: @OLD_transition-250;

/* ----------------------------------------------------------------------------------------------------------*\
		Components


		facet consists of
      __title........................A title to name a facet component
        __title-text.................Text of the title
        __title-category.............An action in the title
      __category.....................Facet category to group similar filters together
\*---------------------------------------------------------------------------------------------------------- */

.facet {
	& + & {
		padding-top: @facet-between-spacing;
	}
}


/* ----------------------------------------------------------------------------------------------------------*\
		#facet__title
\*---------------------------------------------------------------------------------------------------------- */
.facet__title {
	.typography-styles-subheadline-large();

	display: flex;
	height: @facet-title-height;
	align-items: center;
	justify-content: space-between;
	padding: 0 @facet-horizontal-spacing;
	color: @facet-color;
	gap: @facet-horizontal-gap;
	white-space: nowrap;
}


.facet__title-text {
	&--is-deleted {
		color: @facet-title-deleted-color;
	}
}

.facet__title-actions {
	display: flex;
	gap: @spacing-s;
}

/* ----------------------------------------------------------------------------------------------------------*\
		#facet__category
\*---------------------------------------------------------------------------------------------------------- */
.facet__category {
	padding: @facet-vertical-spacing @facet-horizontal-spacing 0;

	&--no-horizontal-padding {
		padding: @facet-vertical-spacing 0 0;
	}
}
