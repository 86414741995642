// local variables
@combo-box-color: @color-text-default-on-light;
@combo-box-bg-color: @color-input-background;
@combo-box-border-color: @color-input-border;
@combo-box-focus-border-color: @color-border-active;
@combo-box-has-error-border-color: @color-alert-background-inverse;
@combo-box-focus-has-error-border-color: @color-alert-focus;
@combo-box-disabled-color: @color-text-disabled;
@combo-box-disabled-bg-color: @color-background-disabled;
@combo-box-editing-inner-left-spacing: @spacing-l;
@combo-box-editing-inner-left-spacing-small: @spacing-m;

/* ----------------------------------------------------------------------------------------------------------*\
		#combo-box
\*---------------------------------------------------------------------------------------------------------- */
.combo-box {
	.typography-styles-body-medium();

	display: flex;
	overflow: hidden;
	width: 100%;
	height: @size-input-height;
	box-sizing: border-box;
	align-items: center;
	border: @border-width-input solid @combo-box-border-color;
	border-radius: @border-radius-pill;
	background-color: @color-input-background;
	color: @combo-box-color;
	outline: 0;
	transition: border @OLD_transition-250;

	.dropdown-selected {
		min-width: 100%;

		.dropdown-selected__pane {
			display: flex;
			min-width: 100%;
			justify-content: space-between;
			padding: 0 @spacing-l;
		}

		.dropdown-selected__item-icon {
			margin-left: @spacing-s;
			color: @color-icon-default;
			font-size: @icon-size-s;
		}
	}

	&::placeholder {
		.placeholder();
	}

	&:focus:not(&--is-disabled) {
		border-color: @combo-box-focus-border-color;
		outline: none;

		&::placeholder {
			color: transparent;
		}
	}

	&--has-error {
		border: 1px solid @combo-box-has-error-border-color;
	}

	&--has-error:focus:not(.combo-box--is-disabled) {
		border: 1px solid @combo-box-focus-has-error-border-color;
	}

	&--with-default-option {
		color: @color-text-hint;
		font-size: inherit;
	}

	&[disabled],
	&[readonly],
	fieldset[disabled] &,
	&--is-disabled,
	&--is-disabled .dropdown-selected__pane {
		border-color: @color-border-disabled;
		background-color: @color-background-disabled;
		color: @combo-box-disabled-color;
		cursor: not-allowed;

		.dropdown-selected__item-icon {
			color: @combo-box-disabled-color;
		}
	}
}
