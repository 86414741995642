@rte-empty-block-width: @spacing-xs;
@rte-block-margin: @spacing-xl;

.block-container() {
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	// We need this explicit align-items so that the inline image size is properly forced to the defined size
	align-items: flex-start;

	// Firefox has a bug with flex in content editable, we need to fallback to block layout, see KCL-4313
	@supports (-moz-appearance:none) {
		& {
			display: block;
		}
	}
}

.inline-block() {
	display: inline-flex;
	flex: 0 0 auto;
	flex-wrap: wrap;
	align-content: flex-start;

	// Firefox has a bug with flex in content editable, we need to fallback to inline-block, see KCL-4313
	@supports (-moz-appearance:none) {
		& {
			display: inline-block;
		}
	}

	> * {
		width: 100%;
	}
}

.rte__content {
	div[data-contents] {
		.block-container();
	}

	.rte__table-cell-content {
		.block-container();
	}

	.rte__heading-one,
	.rte__heading-two,
	.rte__heading-three,
	.rte__heading-four,
	.rte__heading-five,
	.rte__heading-six,
	.rte__paragraph,
	.rte__list-item {
		width: 100%;
	}

	.rte__custom-block-sleeve {
		&--is-before {
			width: 100%;
			height: 0;
			max-height: 0;
		}

		&--is-after:not(.rte__previous--is-image):not(.rte__previous--is-table-cell) {
			width: 100%;
			height: 0;
		}

		&.rte__previous--is-image {
			.inline-block();

			flex-grow: 1;
		}
    }

	.rte__table:not(.rte__table--reached-max-width) + .rte__custom-block-sleeve {
		.inline-block();

		flex-grow: 1;

		> div {
			display: inline-block;
		}
	}

	.rte__table--reached-max-width + .rte__custom-block-sleeve {
		width: 100%;
		height: 0;
		max-height: 0;
	}

	.rte__table {
		.inline-block();

		max-width: calc((100% - (@rte-sleeve-caret-block-width * 2)));

		&--reached-max-width {
			width: calc((100% - (@rte-sleeve-caret-block-width * 2)));
		}
	}

	.rte__content-module,
	.rte__content-component {
		.inline-block();

		width: 100%;
	}

	.rte__content-image {
		.inline-block();

		max-width: calc((100% - (@rte-sleeve-caret-block-width * 2)));
	}

	.rte__new-block-placeholder {
		.inline-block();

		width: calc((100% - (@rte-sleeve-caret-block-width * 2)));
	}
}

// Unifies highlight visual of the empty blocks
// Prevents empty text block having 0px width which sometimes causes hidden caret in Chrome
.rte__text-block--is-empty {
	div[data-offset-key] {
		max-width: @rte-empty-block-width;
	}

	span[data-offset-key] {
		display: block;
		overflow: hidden;
		width: @rte-empty-block-width;
	}
}

.rte__droptarget {
  position: absolute;
  z-index: 1;
  display: none;
  width: 100%;
  height: (4 * @grid-unit);
  margin-top: (-2 * @grid-unit);
  user-select: none;

  .rte--is-dragging & {
	display: block;
  }
}

// When dropping external files, we need to extend the top and bottom of the editor area
// to capture the drag events at the edges of the editor where the dragged files preview is placed
.rte--accepts-files .rte__text-block.rte__top-level-block {
	&.rte__block--is-first {
		position: relative;

		&::after {
			bottom: 100%;
			.rte-block-drop-extension-area();
		}
	}

	&.rte__block--is-last {
		position: relative;

		&::after {
			.rte-block-drop-extension-area();

			top: 100%;
		}
	}
}
