// local variables
@move-to-asset-folder-dialog-width: (50 * @grid-unit);

.move-to-asset-folder-dialog {
	min-width: @move-to-asset-folder-dialog-width;
	max-width: @move-to-asset-folder-dialog-width;
}

.move-to-asset-folder-dialog__label {
	.typography-styles-ui-paragraph();

	margin-bottom: @spacing-micro;
}

.move-to-asset-folder-dialog__folder-selector {
	display: flex;
	min-height: @size-input-height;
	align-items: center;
	padding: 0 @spacing-l;
	border: @border-width-input solid @color-input-border;
	border-radius: @border-radius-pill;
	cursor: pointer;

	&--has-focus {
		border-color: @color-border-active;
		color: @color-text-default-on-light;
	}
}

.move-to-asset-folder-dialog__folder-selector-name {
	.typography-styles-body-medium();

	overflow: hidden;
	margin: 0 @spacing-s;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.move-to-asset-folder-dialog__folder-selector-icon {
	margin-left: auto;
	color: @color-icon-default;
	font-size: @font-size-l;
}

.move-to-asset-folder-dialog__folder-selector-option {
	display: flex;
	align-items: center;
}

.move-to-asset-folder-dialog__folder-selector-option-name {
	margin-left: @spacing-s;
}
