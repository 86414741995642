.content-item-toolbar__incomplete-notification {
	display: flex;

	&[data-incomplete-elements-count]::before {
		.typography-styles-caption();

		display: inline-flex;
		min-width: @spacing-l;
		height: @spacing-l;
		align-items: center;
		justify-content: center;
		padding: 0 @spacing-micro;
		border-radius: @border-radius-pill;
		margin-right: @spacing-xs;
		background: @color-alert-background-inverse;
		color: @color-text-default-on-dark;
		content: attr(data-incomplete-elements-count);
	}
}

.content-item-toolbar__contributors-list {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

.sidebar__section-item.content-item-toolbar__note-author {
	.typography-styles-label-medium();

	color: @color-text-low-emphasis;
}

.sidebar__section-title-contributors__icon-right {
	margin-left: @spacing-s;
}
