//
// Scaffolding
// --------------------------------------------------

// Reset the box-sizing
//
// Heads up! This reset may cause conflicts with some third-party widgets.
// For recommendations on resolving such conflicts, see
// http://getbootstrap.com/getting-started/#third-box-sizing
* {
	box-sizing: border-box;
	outline: none; //we will try how it goes
}


*::before,
*::after {
	box-sizing: border-box;
}


body {
	.typography-styles-body-medium();

	overflow: hidden;
	box-sizing: border-box;
	background-color: @color-white;
	color: @color-text-default-on-light;
	outline: none;
	text-decoration: none;
	white-space: normal;
	word-wrap: normal;
}


// Reset fonts for relevant elements
input,
button,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}


// Router links
a {
	color: @color-link;
	cursor: pointer;
	text-decoration: underline;

	&:hover,
	&:focus-visible {
		color: @color-link-hover;
		text-decoration: underline;
	}

	&:focus-visible {
		border-radius: @border-radius-xs;
		outline: 2px solid @color-focus;
		outline-offset: @offset-focus;
	}
}


// Images

img {
	vertical-align: middle;
}


// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/

.sr-only {
	position: absolute;
	overflow: hidden;
	width: 1px;
	height: 1px;
	padding: 0;
	border: 0;
	clip: rect(0, 0, 0, 0);
}


// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
// Credit: HTML5 Boilerplate

.sr-only-focusable {
	&:active,
	&:focus {
		position: static;
		overflow: visible;
		width: auto;
		height: auto;
		margin: 0;
		clip: auto;
	}
}


// iOS "clickable elements" fix for role="button"
//
// Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
// for traditionally non-focusable elements with role="button"
// see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

[role="button"] {
	cursor: pointer;
}

.react-container {
	height: 100vh;
	overflow-y: auto;
}
