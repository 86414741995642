/* ------------------------------------*\
	Components

	.duplicate-to-collection-modal consists of
		__section..........Section in modal
			__selector.....Selector control
\*------------------------------------ */

.duplicate-to-collection-modal {
	& .modal-dialog__pane {
		min-width: (80 * @grid-unit);
		max-width: (90 * @grid-unit);
	}

	& .modal-dialog__header {
		max-width: none;
	}

	& .modal-dialog__content {
		display: flex;
		flex-direction: column;
	}
}

.duplicate-to-collection-modal__section {
	margin-top: @spacing-xl;
}

.duplicate-to-collection-modal__selector {
	width: (38 * @grid-unit);
}
