// Public variables
@btn-square-width: @size-m;
@btn-square-s-width: @size-s;
@btn-square-l-width: @size-l;

@btn-quinary-size: @size-xs;

// Components
.button-size-style(@buttonSize, @horizontalPadding, @fontSize, @borderWidth: @border-width-default) {
	height: @buttonSize;
	padding: 0 (@horizontalPadding - @borderWidth);
	font-size: @fontSize;
}

.borderless-button-style(@bgColor, @bgColorHover, @bgColorDisabled) {
	background: @bgColor;

	&:focus {
		.shadow-focus-styles();
	}

	&.active,
	&:active,
	&:hover {
		background: @bgColorHover;
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&,
		&[disabled],
		&:hover,
		&:focus,
		&:active {
			background: @bgColorDisabled;
			box-shadow: none;
			color: @color-text-disabled;
		}
	}
}

.bordered-button-style(@borderColor, @borderColorHover, @bgColorHover) {
	border-style: solid;
	border-color: @borderColor;
	background: @color-transparent;

	&:focus {
		.shadow-focus-styles();
	}

	&.active,
	&:active,
	&:hover {
		border-color: @borderColorHover;
		background: @bgColorHover;
		background-clip: padding-box;
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&,
		&[disabled],
		&:hover,
		&:focus,
		&:active {
			border-style: solid;
			border-color: @color-transparent;
			background: @color-background-disabled;
			box-shadow: none;
			color: @color-text-disabled;

			.btn__icon,
			.btn__icon:not(.icon-spinner) {
				color: @color-text-disabled;
			}
		}
	}
}

.quinary-additional-button-style() {
	border-radius: @border-radius-s;
	box-shadow: none;
	color: @color-text-default-on-light;
	transition: all @OLD_transition-250, border-width 0ms, padding 0ms, box-shadow 0ms;

	&:not(.btn--help) {
		// Override the hover and active states to prevent phantom states while dragging an item
		.asset__list--is--dragging,
		.bar-item__list--is-dragging &,
		.content-type-elements-pane__elements-list--is-dragging &,
		.rte--is-dragging & {
			&:active,
			&:hover {
				background: transparent;
				color: @color-text-default-on-light;
			}
		}

		&.drag-action {
			.cursor-draggable();

			// Keep the drag button in visually active state in drag preview
			.drag-preview & {
				background-color: @color-background-selected;
				color: @color-primary;
			}
		}
	}
}

.btn-wrapper {
	display: inline-block;

	&--block {
		display: block;
		width: 100%;
	}

	& + & {
		margin-left: @spacing-between-buttons-horizontal;
	}
}

.btn {
	.button-size-style(@size-m, @spacing-xl, @font-size-s, 0);
	.typography-styles-action-medium();

	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	border-width: 0;
	border-radius: @border-radius-pill;
	margin-bottom: 0;
	cursor: pointer;
	outline: none;
	text-transform: uppercase;
	touch-action: manipulation;
	transition: all @OLD_transition-250, border-width 0ms, padding 0ms;
	user-select: none;
	white-space: nowrap;

	&:hover,
	&:focus,
	&.focus {
		outline: none;
		text-decoration: none;
	}

	&:active,
	&.active {
		outline: none;
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		cursor: not-allowed;
	}

	&--primary {
		.borderless-button-style(@color-primary, @color-primary-hover, @color-background-disabled);

		color: @color-text-default-on-dark;
	}

	&--secondary,
	&--tertiary {
		.bordered-button-style(@color-primary, @color-primary, @color-primary-hover-inverse);

		border-width: @border-width-default * 2;
		background-color: @color-white;
		color: @color-text-default-on-light;
	}

	&--quinary {
		.borderless-button-style(@color-white, @color-primary-hover-inverse, @color-background-disabled);
		.quinary-additional-button-style();
	}

	&--primary-inverted {
		.borderless-button-style(@color-white, @color-primary-hover-inverse, @color-background-disabled);

		color: @color-primary;

		&.active,
		&:active,
		&:hover {
			color: @color-primary-hover;
		}
	}

	&--secondary-inverted {
		.bordered-button-style(@color-white, @color-white, rgba(@color-white, 0.16));

		border-width: @border-width-default * 2;
		color: @color-text-default-on-dark;
	}

	&--destructive-primary {
		.borderless-button-style(@color-alert-background-inverse, @color-alert-hover, @color-background-disabled);

		color: @color-text-default-on-dark;
	}

	&--destructive-secondary {
		.bordered-button-style(@color-border-alert, @color-alert-hover, @color-alert-hover);

		border-width: @border-width-default * 2;
		background-color: @color-white;
		color: @color-alert-text;

		& .btn__icon {
			color: @color-alert-icon;
			transition: color @OLD_transition-250;
		}

		&.active,
		&:active,
		&:hover {
			color: @color-text-default-on-dark;

			& .btn__icon:not(.icon-spinner) {
				color: @color-text-default-on-dark;
			}
		}
	}

	&--square {
		padding: 0;
		border-radius: 50%;
		letter-spacing: 0;
	}

	&--square-m {
		width: @btn-square-width;
		min-width: @btn-square-width;
		height: @btn-square-width;
		min-height: @btn-square-width;

		[class^="icon-"],
		[class*=" icon-"] {
			.typography-styles-font-icon(@size-button-icon);
		}
	}

	&--square-quinary {
		width: @size-xs;
		min-width: @size-xs;
		height: @size-xs;
		min-height: @size-xs;

		[class^="icon-"],
		[class*=" icon-"] {
			.typography-styles-font-icon(@size-button-icon-quinary);
		}
	}

	&--square-quinary&--quinary {
		.borderless-button-style(@color-transparent, @color-primary-hover-inverse, @color-transparent);
		.quinary-additional-button-style();
	}

	&--square-quinary&--quinary-destructive-activated {
		background-color: @color-alert-hover;
		color: @color-text-default-on-dark;
	}

	&--square-quinary&--quinary-destructive {
		&:hover {
			background-color: @color-alert-hover;
			color: @color-text-default-on-dark;
		}
	}

	&--square-s {
		width: @btn-square-s-width;
		min-width: @btn-square-s-width;
		height: @btn-square-s-width;
		min-height: @btn-square-s-width;

		[class^="icon-"],
		[class*=" icon-"] {
			.typography-styles-font-icon(@size-button-icon-quinary);
		}
	}

	&--square-l {
		width: @btn-square-l-width;
		min-width: @btn-square-l-width;
		height: @btn-square-l-width;
		min-height: @btn-square-l-width;

		[class^="icon-"],
		[class*=" icon-"] {
			.typography-styles-font-icon(@icon-size-l);
		}
	}

	&--s {
		.button-size-style(@size-s, @spacing-l, @font-size-s);
		.typography-styles-action-small();

		min-width: @size-l;
	}

	&--s&--quinary {
		.button-size-style(@btn-quinary-size, @spacing-xs, @font-size-s, 0);
		.typography-styles-label-medium();

		min-width: @size-l;
		text-transform: none;
	}

	&--m {
		.button-size-style(@size-m, @spacing-xl, @font-size-s);
	}

	&--block {
		width: 100%;

		& + & {
			margin-top: @spacing-s;
			margin-left: 0;
		}
	}

	&--help {
		cursor: help;
	}
}

.btn__icon {
	margin-right: @spacing-xs;
	font-size: @size-button-icon-quinary;

	&--trailing {
		margin-right: 0;
		margin-left: @spacing-xs;
	}

	&--loader {
		margin-right: @spacing-s;
		font-size: @size-button-icon;
	}

	&--white {
		color: @color-white;
	}
}
