@color--delayed: @color-warning-background-inverse;
@color--on-track: @color-background-disabled;
@color--published: @color-persian-green60;
@color--scheduled: @color-ocean-blue60;

@agenda-item-status-color: @color-text-default-on-light;

@calendar-popup-bg-color: @color-white;
@calendar-popup-bottom-spacing: @spacing-l;

@item-details-title-color: @color-text-default-on-light;

@agenda-view-bg-color: @color-white;
@agenda-group-title-today-color: @color-text-disabled;

.calendar {
	display: flex;
	flex-direction: column;

	.canvas__content-pane& {
		padding-bottom: 8px;
		overflow-x: auto;
	}
}

.calendar__overlay-wrapper {
	position: relative;
}

.calendar__overlay {
	position: absolute;
	z-index: @z-index-one-hundred;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.items-count-limit-warning {
	padding-bottom: @spacing-l;
}

.calendar--month-view.canvas__content-pane {
	padding-right: 0;
}

.calendar--agenda-view {
	&.canvas__content-pane {
		padding-right: 0;
		padding-bottom: 0;
	}

	&.canvas__content-pane > *:last-child {
		margin-bottom: 0;
	}
}

.calendar__date-cell {
	height: 100%;
	flex: 1 0 0;
}

.agenda-item--variant(@colorname, @color, @background-color) {
	&--@{colorname} {
		background-color: @background-color;
		color: @color;
	}

	&--@{colorname}:hover,
	&--@{colorname}:focus,
	&--@{colorname}:active {
		background-color: darken(@background-color, 10%);
		color: @color;
	}
}

.agenda-listing {
	padding: 0;
	margin: 0;
	list-style-type: none;
	overflow-y: auto;
}

.agenda-listing__item {
	display: flex;
	justify-content: flex-start;
	padding: @spacing-xs @spacing-xl;
	cursor: pointer;

	&:hover,
	&:focus {
		background-color: @color-background-hover;

		.agenda-item {
			&--delayed {
				background-color: darken(@color--delayed, 10%);
			}

			&--on-track {
				background-color: darken(@color--on-track, 10%);
			}

			&--published {
				background-color: darken(@color--published, 10%);
			}

			&--scheduled {
				background-color: darken(@color--scheduled, 10%);
			}
		}
	}

	&--is-selected {
		background-color: @color-background-hover;
	}
}

.agenda-item {
	overflow: hidden;
	min-width: 0;
	height: @size-xs;
	padding: 0 @spacing-m;
	border-radius: @border-radius-pill;
	cursor: pointer;

	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
	}

	.agenda-item--variant(delayed, @color-text-default-on-light, @color--delayed);
	.agenda-item--variant(on-track, @color-text-default-on-light, @color--on-track);
	.agenda-item--variant(published, @color-text-default-on-dark, @color--published);
	.agenda-item--variant(scheduled, @color-text-default-on-dark, @color--scheduled);

	&--align-left {
		flex-grow: 0;
		justify-content: flex-start;
	}

	.agenda-listing__item--is-selected &--delayed,
	&--delayed&--is-selected {
		background-color: darken(@color--delayed, 10%);
	}

	.agenda-listing__item--is-selected &--on-track,
	&--on-track&--is-selected {
		background-color: darken(@color--on-track, 10%);
	}

	.agenda-listing__item--is-selected &--published,
	&--published&--is-selected {
		background-color: darken(@color--published, 10%);
	}

	.agenda-listing__item--is-selected &--scheduled,
	&--scheduled&--is-selected {
		background-color: darken(@color--scheduled, 10%);
	}
}

/* ----------------------------------------------------------------------------*\
		#calendar popup
\*---------------------------------------------------------------------------- */

.calendar-popup {
	display: flex;
	min-width: 250px;
	max-width: 350px;
	max-height: calc((100vh - @status-bar-height - @calendar-popup-bottom-spacing));
	flex-direction: column;
	padding-bottom: @spacing-xl;
	border-radius: @border-radius-l;
	background-color: @calendar-popup-bg-color;
	box-shadow: @box-shadow-l;

	&.item-details {
		padding-bottom: 0;

		& > .calendar-popup__content--with-dividers {
			border-bottom: none;
		}
	}
}


.calendar-popup__header {
	display: flex;
	width: 100%;
	max-width: 100%;
	flex-shrink: 0;
	align-items: flex-start;
	padding: @spacing-xl @spacing-xl 0;
	margin-bottom: @spacing-l;
}

.calendar-popup__content {
	overflow-y: auto;

	&--with-dividers {
		border-block: @border-width-default solid @color-border-low-emphasis;
	}
}

.calendar-popup__header-title {
	.typography-styles-headline-medium();

	display: -webkit-box;
	overflow: hidden;
	flex-grow: 1;
	-webkit-box-orient: vertical;
	color: @color-text-default-on-light;
	-webkit-line-clamp: 3;
	word-break: break-word;

	.btn-wrapper + &,
	& + .btn-wrapper {
		margin-left: @spacing-s;
	}
}


/* ----------------------------------------------------------------------------*\
		#item details
\*---------------------------------------------------------------------------- */

.item-details__body {
	width: 100%;
	padding: 0 @spacing-xl @spacing-xl @spacing-xl;
}


.item-details__header {
	word-break: break-word;
}


.item-details__category {
	.typography-styles-label-large();

	overflow: hidden;
	margin-bottom: @spacing-l;
	color: @color-text-low-emphasis;
	text-overflow: ellipsis;

	&:last-child {
		margin-bottom: 0;
	}
}


.item-details__title {
	.typography-styles-headline-medium();

	margin-bottom: @spacing-s;
	color: @item-details-title-color;
	cursor: default;
}


.item-details__open-item-button {
	overflow: initial;
}

.item-details__icon-back,
.item-details__icon-back:hover,
.item-details__icon-back:focus {
	color: inherit;
}

.agenda-view {
	display: flex;
	overflow: auto;
	min-height: 0; // FF flex fix https://stackoverflow.com/questions/44948158/flexbox-overflow-issue-in-firefox
	flex-direction: column;
}

.agenda-view__content {
	max-width: (75 * @grid-unit);
	padding: @spacing-xl 0;
	border: @border-width-default solid @color-border-low-emphasis;
	border-radius: @border-radius-l;
	margin: @spacing-l @spacing-xl @spacing-l 0;
	background: @agenda-view-bg-color;
}

.agenda-group {
	&--today {
		background-color: @color-background-highlighted;
	}

	&:first-child {
		margin-top: 0;
	}
}

.agenda-group__title {
	.typography-card-headline();

	display: flex;
	align-items: baseline;
	padding: 0 @spacing-xl;
	margin-bottom: @spacing-xs;
	color: @color-text-default-on-light;
	text-align: left;

	&--today {
		margin-left: @spacing-s;
		color: @agenda-group-title-today-color;
	}
}

