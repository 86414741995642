@confirmation-dialog-width: (64 * @grid-unit);
@confirmation-dialog-section-between-spacing: @spacing-m;
@confirmation-dialog-color: @color-text-default-on-light;

.confirmation-dialog__body {
	.typography-styles-ui-paragraph();

	min-width: @confirmation-dialog-width;
	max-width: @confirmation-dialog-width;
	color: @confirmation-dialog-color;
}

.confirmation-dialog__section + .confirmation-dialog__section {
	margin-top: @confirmation-dialog-section-between-spacing;
}

.confirmation-dialog__section {
	word-break: break-word;
}
