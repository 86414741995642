/*
 * This file serves to override some of our normalize and other tokens for the purposes of copy (Ctrl + C) export from Rich text.
 * If for example the exported text has an undesirable grey background, here's a way to get rid of it.
 */

.clipboard-sandbox {
	* {
		background-color: white;
	}

	sub,
	sup {
		position: initial;
		top: unset;
		bottom: unset;
		line-height: inherit;
	}

	sub {
		vertical-align: sub;
	}

	sup {
		vertical-align: super;
	}
}
