@comment-status-icon-color: @color-neon-green50;
@comment-status-border-color: @color-divider-default;

/* ------------------------------------*\
    Component variables
\*------------------------------------ */

/* ------------------------------------*\
    Components


    comment-status consists of
      __icon..........................Depicts status
      __label.........................Denotes status
\*------------------------------------ */

/* ------------------------------------*\
    #comment-status
\*------------------------------------ */
.comment-status {
	display: flex;
	padding-bottom: @spacing-l;
	border-bottom: solid 1px @comment-status-border-color;
	margin-bottom: @spacing-l;
}


/* ------------------------------------*\
    #comments-status__icon
\*------------------------------------ */
.comment-status__icon {
	color: @comment-status-icon-color;
	font-size: @icon-size-s;
}


/* ------------------------------------*\
    #comment-status__label
\*------------------------------------ */
.comment-status__label {
	.typography-styles-label-medium();

	overflow: hidden;
	margin-left: @spacing-s;
	color: @color-text-hint;
	text-overflow: ellipsis;
	white-space: nowrap;
}
