.collections-group {
	.border-low-emphasis();

	padding: @spacing-card;
	border-radius: @border-radius-m;
	background-color: @color-white;
}

.collections-group__title {
	.typography-card-headline();

	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: @spacing-xl;
	color: @color-text-default-on-light;
}

.collections-group__header {
	padding-right: (@spacing-l + @size-m);
	margin-bottom: @spacing-xl;
}

.collections-group__header-content {
	display: flex;
	align-items: center;
}

.collections-group__header-dropdown {
	width: 100%;
}
