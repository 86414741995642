// This file is generated upon a change in icons. Do not change it manually.
.icon-a-lowercase::before { content: @icon-a-lowercase; }
.icon-accordion::before { content: @icon-accordion; }
.icon-android::before { content: @icon-android; }
.icon-apple::before { content: @icon-apple; }
.icon-arrow-crooked-left::before { content: @icon-arrow-crooked-left; }
.icon-arrow-crooked-right::before { content: @icon-arrow-crooked-right; }
.icon-arrow-curved-left::before { content: @icon-arrow-curved-left; }
.icon-arrow-curved-right::before { content: @icon-arrow-curved-right; }
.icon-arrow-double-left::before { content: @icon-arrow-double-left; }
.icon-arrow-double-right::before { content: @icon-arrow-double-right; }
.icon-arrow-down::before { content: @icon-arrow-down; }
.icon-arrow-down-circle::before { content: @icon-arrow-down-circle; }
.icon-arrow-down-line::before { content: @icon-arrow-down-line; }
.icon-arrow-enter-square::before { content: @icon-arrow-enter-square; }
.icon-arrow-leave-square::before { content: @icon-arrow-leave-square; }
.icon-arrow-left::before { content: @icon-arrow-left; }
.icon-arrow-left-circle::before { content: @icon-arrow-left-circle; }
.icon-arrow-left-rect::before { content: @icon-arrow-left-rect; }
.icon-arrow-right::before { content: @icon-arrow-right; }
.icon-arrow-right-circle::before { content: @icon-arrow-right-circle; }
.icon-arrow-right-rect::before { content: @icon-arrow-right-rect; }
.icon-arrow-right-top-square::before { content: @icon-arrow-right-top-square; }
.icon-arrow-u-left::before { content: @icon-arrow-u-left; }
.icon-arrow-u-right::before { content: @icon-arrow-u-right; }
.icon-arrow-up::before { content: @icon-arrow-up; }
.icon-arrow-up-circle::before { content: @icon-arrow-up-circle; }
.icon-arrow-up-line::before { content: @icon-arrow-up-line; }
.icon-arrows::before { content: @icon-arrows; }
.icon-arrows-crooked::before { content: @icon-arrows-crooked; }
.icon-arrows-h::before { content: @icon-arrows-h; }
.icon-arrows-v::before { content: @icon-arrows-v; }
.icon-b::before { content: @icon-b; }
.icon-badge::before { content: @icon-badge; }
.icon-ban-sign::before { content: @icon-ban-sign; }
.icon-bell::before { content: @icon-bell; }
.icon-bezier-scheme::before { content: @icon-bezier-scheme; }
.icon-bin::before { content: @icon-bin; }
.icon-binder::before { content: @icon-binder; }
.icon-book-opened::before { content: @icon-book-opened; }
.icon-box::before { content: @icon-box; }
.icon-box-cart::before { content: @icon-box-cart; }
.icon-box-clock::before { content: @icon-box-clock; }
.icon-box-disc::before { content: @icon-box-disc; }
.icon-boxes::before { content: @icon-boxes; }
.icon-braces::before { content: @icon-braces; }
.icon-braces-octothorpe::before { content: @icon-braces-octothorpe; }
.icon-brand-bing::before { content: @icon-brand-bing; }
.icon-brand-facebook::before { content: @icon-brand-facebook; }
.icon-brand-google::before { content: @icon-brand-google; }
.icon-brand-google-plus::before { content: @icon-brand-google-plus; }
.icon-brand-instagram::before { content: @icon-brand-instagram; }
.icon-brand-linkedin::before { content: @icon-brand-linkedin; }
.icon-brand-mstranslator::before { content: @icon-brand-mstranslator; }
.icon-brand-open-id::before { content: @icon-brand-open-id; }
.icon-brand-pinterest::before { content: @icon-brand-pinterest; }
.icon-brand-sharepoint::before { content: @icon-brand-sharepoint; }
.icon-brand-twitter::before { content: @icon-brand-twitter; }
.icon-brand-youtube::before { content: @icon-brand-youtube; }
.icon-breadcrumb::before { content: @icon-breadcrumb; }
.icon-briefcase::before { content: @icon-briefcase; }
.icon-broom::before { content: @icon-broom; }
.icon-brush::before { content: @icon-brush; }
.icon-brush-slashed::before { content: @icon-brush-slashed; }
.icon-bubble::before { content: @icon-bubble; }
.icon-bubble-censored::before { content: @icon-bubble-censored; }
.icon-bubble-check::before { content: @icon-bubble-check; }
.icon-bubble-heart::before { content: @icon-bubble-heart; }
.icon-bubble-hide::before { content: @icon-bubble-hide; }
.icon-bubble-inverted::before { content: @icon-bubble-inverted; }
.icon-bubble-pencil::before { content: @icon-bubble-pencil; }
.icon-bubble-show::before { content: @icon-bubble-show; }
.icon-bubble-times::before { content: @icon-bubble-times; }
.icon-bubble-with-lines::before { content: @icon-bubble-with-lines; }
.icon-bubbles::before { content: @icon-bubbles; }
.icon-bucket-shovel::before { content: @icon-bucket-shovel; }
.icon-bug::before { content: @icon-bug; }
.icon-building::before { content: @icon-building; }
.icon-building-block::before { content: @icon-building-block; }
.icon-bullseye::before { content: @icon-bullseye; }
.icon-c-sharp::before { content: @icon-c-sharp; }
.icon-calendar::before { content: @icon-calendar; }
.icon-calendar-number::before { content: @icon-calendar-number; }
.icon-camera::before { content: @icon-camera; }
.icon-cancel::before { content: @icon-cancel; }
.icon-caret-down::before { content: @icon-caret-down; }
.icon-caret-left::before { content: @icon-caret-left; }
.icon-caret-left-aligned-right::before { content: @icon-caret-left-aligned-right; }
.icon-caret-right::before { content: @icon-caret-right; }
.icon-caret-right-aligned-left::before { content: @icon-caret-right-aligned-left; }
.icon-caret-right-down::before { content: @icon-caret-right-down; }
.icon-caret-up::before { content: @icon-caret-up; }
.icon-carousel::before { content: @icon-carousel; }
.icon-cb-check::before { content: @icon-cb-check; }
.icon-cb-check-disabled::before { content: @icon-cb-check-disabled; }
.icon-cb-check-inverted::before { content: @icon-cb-check-inverted; }
.icon-cb-check-minus::before { content: @icon-cb-check-minus; }
.icon-cb-check-preview::before { content: @icon-cb-check-preview; }
.icon-cb-check-sign::before { content: @icon-cb-check-sign; }
.icon-cb-uncheck::before { content: @icon-cb-uncheck; }
.icon-chain::before { content: @icon-chain; }
.icon-chain-broken::before { content: @icon-chain-broken; }
.icon-chain-slash::before { content: @icon-chain-slash; }
.icon-check::before { content: @icon-check; }
.icon-check-circle::before { content: @icon-check-circle; }
.icon-check-circle-inverted::before { content: @icon-check-circle-inverted; }
.icon-checklist::before { content: @icon-checklist; }
.icon-chevron-double-down::before { content: @icon-chevron-double-down; }
.icon-chevron-double-left::before { content: @icon-chevron-double-left; }
.icon-chevron-double-right::before { content: @icon-chevron-double-right; }
.icon-chevron-double-up::before { content: @icon-chevron-double-up; }
.icon-chevron-down::before { content: @icon-chevron-down; }
.icon-chevron-down-circle::before { content: @icon-chevron-down-circle; }
.icon-chevron-down-line::before { content: @icon-chevron-down-line; }
.icon-chevron-down-square::before { content: @icon-chevron-down-square; }
.icon-chevron-left::before { content: @icon-chevron-left; }
.icon-chevron-left-circle::before { content: @icon-chevron-left-circle; }
.icon-chevron-left-line::before { content: @icon-chevron-left-line; }
.icon-chevron-left-square::before { content: @icon-chevron-left-square; }
.icon-chevron-right::before { content: @icon-chevron-right; }
.icon-chevron-right-circle::before { content: @icon-chevron-right-circle; }
.icon-chevron-right-line::before { content: @icon-chevron-right-line; }
.icon-chevron-right-square::before { content: @icon-chevron-right-square; }
.icon-chevron-up::before { content: @icon-chevron-up; }
.icon-chevron-up-circle::before { content: @icon-chevron-up-circle; }
.icon-chevron-up-line::before { content: @icon-chevron-up-line; }
.icon-chevron-up-square::before { content: @icon-chevron-up-square; }
.icon-choice-multi-scheme::before { content: @icon-choice-multi-scheme; }
.icon-choice-single-scheme::before { content: @icon-choice-single-scheme; }
.icon-choice-user-scheme::before { content: @icon-choice-user-scheme; }
.icon-chrome::before { content: @icon-chrome; }
.icon-circle::before { content: @icon-circle; }
.icon-circle-dashed::before { content: @icon-circle-dashed; }
.icon-circle-inverted::before { content: @icon-circle-inverted; }
.icon-circle-slashed::before { content: @icon-circle-slashed; }
.icon-circle-square::before { content: @icon-circle-square; }
.icon-clapperboard::before { content: @icon-clapperboard; }
.icon-clipboard-checklist::before { content: @icon-clipboard-checklist; }
.icon-clipboard-list::before { content: @icon-clipboard-list; }
.icon-clock::before { content: @icon-clock; }
.icon-cloud::before { content: @icon-cloud; }
.icon-cogwheel::before { content: @icon-cogwheel; }
.icon-cogwheel-square::before { content: @icon-cogwheel-square; }
.icon-cogwheels::before { content: @icon-cogwheels; }
.icon-collapse::before { content: @icon-collapse; }
.icon-collapse-scheme::before { content: @icon-collapse-scheme; }
.icon-compass::before { content: @icon-compass; }
.icon-convert::before { content: @icon-convert; }
.icon-cookie::before { content: @icon-cookie; }
.icon-crop::before { content: @icon-crop; }
.icon-crosshair-f::before { content: @icon-crosshair-f; }
.icon-crosshair-o::before { content: @icon-crosshair-o; }
.icon-cup::before { content: @icon-cup; }
.icon-custom-element::before { content: @icon-custom-element; }
.icon-database::before { content: @icon-database; }
.icon-desktop::before { content: @icon-desktop; }
.icon-dialog-window::before { content: @icon-dialog-window; }
.icon-dialog-window-cogwheel::before { content: @icon-dialog-window-cogwheel; }
.icon-diamond::before { content: @icon-diamond; }
.icon-doc::before { content: @icon-doc; }
.icon-doc-arrows::before { content: @icon-doc-arrows; }
.icon-doc-ban-sign::before { content: @icon-doc-ban-sign; }
.icon-doc-copy::before { content: @icon-doc-copy; }
.icon-doc-inverted::before { content: @icon-doc-inverted; }
.icon-doc-move::before { content: @icon-doc-move; }
.icon-doc-paste::before { content: @icon-doc-paste; }
.icon-doc-plus::before { content: @icon-doc-plus; }
.icon-doc-torn::before { content: @icon-doc-torn; }
.icon-doc-user::before { content: @icon-doc-user; }
.icon-dollar-sign::before { content: @icon-dollar-sign; }
.icon-dots-vertical::before { content: @icon-dots-vertical; }
.icon-down-dir::before { content: @icon-down-dir; }
.icon-drawers::before { content: @icon-drawers; }
.icon-e-book::before { content: @icon-e-book; }
.icon-earth::before { content: @icon-earth; }
.icon-edge::before { content: @icon-edge; }
.icon-edit::before { content: @icon-edit; }
.icon-ekg-line::before { content: @icon-ekg-line; }
.icon-ellipsis::before { content: @icon-ellipsis; }
.icon-engage-users::before { content: @icon-engage-users; }
.icon-exclamation-triangle::before { content: @icon-exclamation-triangle; }
.icon-exclamation-triangle-inverted::before { content: @icon-exclamation-triangle-inverted; }
.icon-expand::before { content: @icon-expand; }
.icon-eye::before { content: @icon-eye; }
.icon-eye-slash::before { content: @icon-eye-slash; }
.icon-f::before { content: @icon-f; }
.icon-factory::before { content: @icon-factory; }
.icon-file::before { content: @icon-file; }
.icon-file-pdf::before { content: @icon-file-pdf; }
.icon-filter-1::before { content: @icon-filter-1; }
.icon-firefox::before { content: @icon-firefox; }
.icon-flag::before { content: @icon-flag; }
.icon-folder::before { content: @icon-folder; }
.icon-folder-belt::before { content: @icon-folder-belt; }
.icon-folder-clock::before { content: @icon-folder-clock; }
.icon-folder-inverted::before { content: @icon-folder-inverted; }
.icon-folder-opened::before { content: @icon-folder-opened; }
.icon-form::before { content: @icon-form; }
.icon-funnel::before { content: @icon-funnel; }
.icon-gauge::before { content: @icon-gauge; }
.icon-gift::before { content: @icon-gift; }
.icon-graduate-cap::before { content: @icon-graduate-cap; }
.icon-graph::before { content: @icon-graph; }
.icon-h-1::before { content: @icon-h-1; }
.icon-h-2::before { content: @icon-h-2; }
.icon-h-3::before { content: @icon-h-3; }
.icon-h-4::before { content: @icon-h-4; }
.icon-h-5::before { content: @icon-h-5; }
.icon-h-6::before { content: @icon-h-6; }
.icon-half-arrows-right-left::before { content: @icon-half-arrows-right-left; }
.icon-hat-moustache::before { content: @icon-hat-moustache; }
.icon-heart::before { content: @icon-heart; }
.icon-heartshake::before { content: @icon-heartshake; }
.icon-highlighter::before { content: @icon-highlighter; }
.icon-home::before { content: @icon-home; }
.icon-i::before { content: @icon-i; }
.icon-i-circle::before { content: @icon-i-circle; }
.icon-id-card::before { content: @icon-id-card; }
.icon-id-cards::before { content: @icon-id-cards; }
.icon-indent::before { content: @icon-indent; }
.icon-integration-scheme::before { content: @icon-integration-scheme; }
.icon-intercom::before { content: @icon-intercom; }
.icon-internet-explorer::before { content: @icon-internet-explorer; }
.icon-invoice::before { content: @icon-invoice; }
.icon-kentico::before { content: @icon-kentico; }
.icon-key::before { content: @icon-key; }
.icon-l-article-map::before { content: @icon-l-article-map; }
.icon-l-calendar-number-article::before { content: @icon-l-calendar-number-article; }
.icon-l-cols-2::before { content: @icon-l-cols-2; }
.icon-l-cols-20-60-20::before { content: @icon-l-cols-20-60-20; }
.icon-l-cols-20-80::before { content: @icon-l-cols-20-80; }
.icon-l-cols-25-50-25::before { content: @icon-l-cols-25-50-25; }
.icon-l-cols-3::before { content: @icon-l-cols-3; }
.icon-l-cols-30-70::before { content: @icon-l-cols-30-70; }
.icon-l-cols-4::before { content: @icon-l-cols-4; }
.icon-l-cols-70-30::before { content: @icon-l-cols-70-30; }
.icon-l-cols-80-20::before { content: @icon-l-cols-80-20; }
.icon-l-forms-2::before { content: @icon-l-forms-2; }
.icon-l-grid-2-2::before { content: @icon-l-grid-2-2; }
.icon-l-grid-3-2::before { content: @icon-l-grid-3-2; }
.icon-l-header-cols-2-footer::before { content: @icon-l-header-cols-2-footer; }
.icon-l-header-cols-3-footer::before { content: @icon-l-header-cols-3-footer; }
.icon-l-header-list-img::before { content: @icon-l-header-list-img; }
.icon-l-header-menu-text::before { content: @icon-l-header-menu-text; }
.icon-l-header-text::before { content: @icon-l-header-text; }
.icon-l-img-2-cols-3::before { content: @icon-l-img-2-cols-3; }
.icon-l-img-3-cols-3::before { content: @icon-l-img-3-cols-3; }
.icon-l-lightbox::before { content: @icon-l-lightbox; }
.icon-l-list-article::before { content: @icon-l-list-article; }
.icon-l-list-article-col::before { content: @icon-l-list-article-col; }
.icon-l-list-img-article::before { content: @icon-l-list-img-article; }
.icon-l-list-title::before { content: @icon-l-list-title; }
.icon-l-logotype-menu-col-footer::before { content: @icon-l-logotype-menu-col-footer; }
.icon-l-logotype-menu-h-col::before { content: @icon-l-logotype-menu-h-col; }
.icon-l-logotype-menu-v-col::before { content: @icon-l-logotype-menu-v-col; }
.icon-l-menu-cols-2::before { content: @icon-l-menu-cols-2; }
.icon-l-menu-cols-3::before { content: @icon-l-menu-cols-3; }
.icon-l-menu-list::before { content: @icon-l-menu-list; }
.icon-l-menu-list-img::before { content: @icon-l-menu-list-img; }
.icon-l-menu-list-img-col::before { content: @icon-l-menu-list-img-col; }
.icon-l-menu-text::before { content: @icon-l-menu-text; }
.icon-l-menu-text-col::before { content: @icon-l-menu-text-col; }
.icon-l-menu-text-col-bottom::before { content: @icon-l-menu-text-col-bottom; }
.icon-l-rows-2::before { content: @icon-l-rows-2; }
.icon-l-rows-3::before { content: @icon-l-rows-3; }
.icon-l-rows-4::before { content: @icon-l-rows-4; }
.icon-l-text::before { content: @icon-l-text; }
.icon-l-text-col::before { content: @icon-l-text-col; }
.icon-laptop::before { content: @icon-laptop; }
.icon-layout::before { content: @icon-layout; }
.icon-layouts::before { content: @icon-layouts; }
.icon-life-belt::before { content: @icon-life-belt; }
.icon-light-bulb::before { content: @icon-light-bulb; }
.icon-linux::before { content: @icon-linux; }
.icon-list::before { content: @icon-list; }
.icon-list-bullets::before { content: @icon-list-bullets; }
.icon-list-numbers::before { content: @icon-list-numbers; }
.icon-lock::before { content: @icon-lock; }
.icon-lock-unlocked::before { content: @icon-lock-unlocked; }
.icon-loop::before { content: @icon-loop; }
.icon-magnifier::before { content: @icon-magnifier; }
.icon-magnifier-minus::before { content: @icon-magnifier-minus; }
.icon-magnifier-plus::before { content: @icon-magnifier-plus; }
.icon-map::before { content: @icon-map; }
.icon-map-marker::before { content: @icon-map-marker; }
.icon-market::before { content: @icon-market; }
.icon-mask::before { content: @icon-mask; }
.icon-media-player::before { content: @icon-media-player; }
.icon-menu::before { content: @icon-menu; }
.icon-merge::before { content: @icon-merge; }
.icon-message::before { content: @icon-message; }
.icon-messages::before { content: @icon-messages; }
.icon-microphone::before { content: @icon-microphone; }
.icon-minus::before { content: @icon-minus; }
.icon-minus-circle::before { content: @icon-minus-circle; }
.icon-minus-square::before { content: @icon-minus-square; }
.icon-mobile::before { content: @icon-mobile; }
.icon-modal-close::before { content: @icon-modal-close; }
.icon-modal-maximize::before { content: @icon-modal-maximize; }
.icon-modal-minimize::before { content: @icon-modal-minimize; }
.icon-modal-question::before { content: @icon-modal-question; }
.icon-money-bill::before { content: @icon-money-bill; }
.icon-monitor::before { content: @icon-monitor; }
.icon-monitor-broken::before { content: @icon-monitor-broken; }
.icon-monitor-smartphone::before { content: @icon-monitor-smartphone; }
.icon-mug::before { content: @icon-mug; }
.icon-newspaper::before { content: @icon-newspaper; }
.icon-note::before { content: @icon-note; }
.icon-notebook::before { content: @icon-notebook; }
.icon-octothorpe::before { content: @icon-octothorpe; }
.icon-one::before { content: @icon-one; }
.icon-opera::before { content: @icon-opera; }
.icon-organisational-scheme::before { content: @icon-organisational-scheme; }
.icon-outdent::before { content: @icon-outdent; }
.icon-palette::before { content: @icon-palette; }
.icon-pants::before { content: @icon-pants; }
.icon-paper-plane::before { content: @icon-paper-plane; }
.icon-paperclip::before { content: @icon-paperclip; }
.icon-paragraph::before { content: @icon-paragraph; }
.icon-paragraph-center::before { content: @icon-paragraph-center; }
.icon-paragraph-short::before { content: @icon-paragraph-short; }
.icon-parent-child-scheme::before { content: @icon-parent-child-scheme; }
.icon-parent-child-scheme-inverted::before { content: @icon-parent-child-scheme-inverted; }
.icon-parent-children-scheme-2::before { content: @icon-parent-children-scheme-2; }
.icon-parent-children-scheme-3::before { content: @icon-parent-children-scheme-3; }
.icon-pause::before { content: @icon-pause; }
.icon-pc::before { content: @icon-pc; }
.icon-pda::before { content: @icon-pda; }
.icon-percent-sign::before { content: @icon-percent-sign; }
.icon-perfume::before { content: @icon-perfume; }
.icon-permission-list::before { content: @icon-permission-list; }
.icon-personalisation::before { content: @icon-personalisation; }
.icon-personalisation-variants::before { content: @icon-personalisation-variants; }
.icon-picture::before { content: @icon-picture; }
.icon-pictures::before { content: @icon-pictures; }
.icon-piechart::before { content: @icon-piechart; }
.icon-piechart-lines::before { content: @icon-piechart-lines; }
.icon-pilcrow::before { content: @icon-pilcrow; }
.icon-pin::before { content: @icon-pin; }
.icon-pin-inverted::before { content: @icon-pin-inverted; }
.icon-placeholder::before { content: @icon-placeholder; }
.icon-plus::before { content: @icon-plus; }
.icon-plus-circle::before { content: @icon-plus-circle; }
.icon-plus-square::before { content: @icon-plus-square; }
.icon-printer::before { content: @icon-printer; }
.icon-process-scheme::before { content: @icon-process-scheme; }
.icon-project-scheme::before { content: @icon-project-scheme; }
.icon-puzzle::before { content: @icon-puzzle; }
.icon-puzzle-plus::before { content: @icon-puzzle-plus; }
.icon-qr-code::before { content: @icon-qr-code; }
.icon-question-circle::before { content: @icon-question-circle; }
.icon-rb-check::before { content: @icon-rb-check; }
.icon-rb-check-sign::before { content: @icon-rb-check-sign; }
.icon-rb-uncheck::before { content: @icon-rb-uncheck; }
.icon-recaptcha::before { content: @icon-recaptcha; }
.icon-rectangle-a::before { content: @icon-rectangle-a; }
.icon-rectangle-a-inverted::before { content: @icon-rectangle-a-inverted; }
.icon-rectangle-h::before { content: @icon-rectangle-h; }
.icon-rectangle-lines::before { content: @icon-rectangle-lines; }
.icon-rectangle-paragraph::before { content: @icon-rectangle-paragraph; }
.icon-rectangle-v::before { content: @icon-rectangle-v; }
.icon-restriction-list::before { content: @icon-restriction-list; }
.icon-ribbon::before { content: @icon-ribbon; }
.icon-right-double-quotation-mark::before { content: @icon-right-double-quotation-mark; }
.icon-rotate-double-right::before { content: @icon-rotate-double-right; }
.icon-rotate-left::before { content: @icon-rotate-left; }
.icon-rotate-right::before { content: @icon-rotate-right; }
.icon-rss::before { content: @icon-rss; }
.icon-s::before { content: @icon-s; }
.icon-safari::before { content: @icon-safari; }
.icon-scheme-circles-triangle::before { content: @icon-scheme-circles-triangle; }
.icon-scheme-connected-circles::before { content: @icon-scheme-connected-circles; }
.icon-scheme-path-circles::before { content: @icon-scheme-path-circles; }
.icon-scheme-path-circles-flipped::before { content: @icon-scheme-path-circles-flipped; }
.icon-scissors::before { content: @icon-scissors; }
.icon-separate::before { content: @icon-separate; }
.icon-share-apple::before { content: @icon-share-apple; }
.icon-share-google::before { content: @icon-share-google; }
.icon-shoe-women::before { content: @icon-shoe-women; }
.icon-shopping-cart::before { content: @icon-shopping-cart; }
.icon-small-dot-centered::before { content: @icon-small-dot-centered; }
.icon-smartphone::before { content: @icon-smartphone; }
.icon-spinner::before { content: @icon-spinner; }
.icon-square::before { content: @icon-square; }
.icon-square-dashed::before { content: @icon-square-dashed; }
.icon-square-dashed-line::before { content: @icon-square-dashed-line; }
.icon-staging-scheme::before { content: @icon-staging-scheme; }
.icon-star-empty::before { content: @icon-star-empty; }
.icon-star-full::before { content: @icon-star-full; }
.icon-star-semi::before { content: @icon-star-semi; }
.icon-subscript::before { content: @icon-subscript; }
.icon-superscript::before { content: @icon-superscript; }
.icon-t::before { content: @icon-t; }
.icon-t-f::before { content: @icon-t-f; }
.icon-t-shirt::before { content: @icon-t-shirt; }
.icon-t-with-cross::before { content: @icon-t-with-cross; }
.icon-tab::before { content: @icon-tab; }
.icon-tab-vertical::before { content: @icon-tab-vertical; }
.icon-table::before { content: @icon-table; }
.icon-tablet::before { content: @icon-tablet; }
.icon-tag::before { content: @icon-tag; }
.icon-tags::before { content: @icon-tags; }
.icon-three-dots-vertical::before { content: @icon-three-dots-vertical; }
.icon-times::before { content: @icon-times; }
.icon-times-circle::before { content: @icon-times-circle; }
.icon-translate::before { content: @icon-translate; }
.icon-tree-structure::before { content: @icon-tree-structure; }
.icon-triangle-right::before { content: @icon-triangle-right; }
.icon-trophy::before { content: @icon-trophy; }
.icon-truck::before { content: @icon-truck; }
.icon-two-rectangles-h::before { content: @icon-two-rectangles-h; }
.icon-two-rectangles-stacked::before { content: @icon-two-rectangles-stacked; }
.icon-two-rectangles-stacked-times::before { content: @icon-two-rectangles-stacked-times; }
.icon-two-rectangles-v::before { content: @icon-two-rectangles-v; }
.icon-two-squares-line::before { content: @icon-two-squares-line; }
.icon-two-squares-with-lines::before { content: @icon-two-squares-with-lines; }
.icon-u::before { content: @icon-u; }
.icon-up-dir::before { content: @icon-up-dir; }
.icon-user::before { content: @icon-user; }
.icon-user-checkbox::before { content: @icon-user-checkbox; }
.icon-user-frame::before { content: @icon-user-frame; }
.icon-users::before { content: @icon-users; }
.icon-vb::before { content: @icon-vb; }
.icon-watch::before { content: @icon-watch; }
.icon-windows::before { content: @icon-windows; }
.icon-wizard-stick::before { content: @icon-wizard-stick; }
.icon-x::before { content: @icon-x; }
.icon-xml-tag::before { content: @icon-xml-tag; }
