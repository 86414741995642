.create-content-group {
	i& {
		display: flex;
		justify-content: center;
		margin: 0 @spacing-m 0 0;
		font-size: @icon-size-s;
	}
}

.content-type-group-tab {
	min-width: 0;

	&__inner {
		display: flex;
		min-width: 0;
		align-items: center;
		justify-content: center;
		gap: @grid-unit;
	}

	&__drag-handle {
		& i {
			font-size: @icon-size-s;
			transition: all @OLD_transition-150;
		}

		.cursor-draggable();

		.drag-preview & {
			background-color: @color-background-selected;
			color: @color-primary;
		}
	}

	&__title {
		overflow: hidden;
		padding: @grid-unit 0;
		box-shadow: inset 0 (-1 * @border-width-high-emphasis) 0 0 @color-accent;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&--is-dragging {
		.drag-placeholder();

		max-height: (6 * @grid-unit);
		align-self: flex-end;
		border-radius: @border-radius-s @border-radius-s 0 0;
	}

	&__codename-button {
		position: relative;
	}
}

