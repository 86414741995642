/* ------------------------------------*\
    Component variables
\*------------------------------------ */

@comment-author-color: @color-text-default-on-light;
@comment-hover-author-color: @color-text-default-on-light;
@comment-focus-author-color: @color-text-default-on-light;

@comment-info-color: @color-text-hint;
@comment-hover-info-color: @color-text-hint;
@comment-focus-info-color: @color-text-hint;

@comment-content-color: @color-text-default-on-light;
@comment-hover-content-color: @color-text-default-on-light;
@comment-focus-content-color: @color-text-default-on-light;

@comment-suggestion-guideline-color: @color-text-hint;
@comment-hover-suggestion-guideline-color: @color-text-hint;
@comment-focus-suggestion-guideline-color: @color-text-hint;

@comment-suggestion-guideline-approved-color: @color-neon-green50;

@comment-ref-anchor-color: @color-text-hint;
@comment-hover-ref-anchor-color: @color-text-hint;
@comment-focus-ref-anchor-color: @color-text-hint;

@comment-ref-preview-color: @color-text-hint;
@comment-hover-ref-preview-color: @color-text-hint;
@comment-focus-ref-preview-color: @color-text-hint;
@comment-ref-preview-border-color: @color-divider-default;

@comment-ref-text-hasRemovedSegment-bg-color: @color-transparent;

/* ------------------------------------*\
    Components
    comment consists of
      __header.........................Holds information about author, timestamp and includes menu
        __authortimestamp..............Wrapper for author and timestamp
          __author.....................Comment author
          __timestamp..................Date and time that comment was posted
        __actions......................Comment actions
            __tag......................Tag with suggestion status
            __action...................Comment action wrapper
            __menu.....................Action to reveal dropdown menu
                __menu-icon............Icon to depict menu
      __reference......................Informs about what was commented on
        __ref-anchor...................Name of the anchored element that was commented on
        __ref-preview..................Preview of the anchored element
      __suggestion-guideline...........Guideline for suggestion
      __message......................Wrapper for message
        __message-body.................Message
\*------------------------------------ */
.comment {
	& ~ & {
		margin-top: @spacing-xxl;
	}
}

.comment__header {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: @spacing-l;
}

.comment__author-timestamp {
	min-width: 25%;
	max-width: 90%;
	flex: 1 1 auto;
	margin-inline: @spacing-s;
}

.comment__author {
	.typography-styles-label-large();

	overflow: hidden;
	color: @comment-author-color;
	text-overflow: ellipsis;
	white-space: nowrap;

	.comment-thread:hover & {
		color: @comment-hover-author-color;
	}

	.comment-thread--has-focus & {
		color: @comment-focus-author-color;
	}
}

.comment__info {
	.typography-styles-label-medium();

	overflow: hidden;
	color: @comment-info-color;
	text-overflow: ellipsis;
	white-space: nowrap;

	.comment-thread:hover & {
		color: @comment-hover-info-color;
	}

	.comment-thread--has-focus & {
		color: @comment-focus-info-color;
	}
}

.comment__ref-anchor {
	.typography-styles-label-medium();

	overflow: hidden;
	color: @comment-ref-anchor-color;

	.comment-thread:hover & {
		color: @comment-hover-ref-anchor-color;
	}

	.comment-thread--has-focus & {
		color: @comment-focus-ref-anchor-color;
	}
}

.comment__ref-preview {
	.typography-styles-body-medium();

	display: -webkit-box;
	overflow: hidden;
	max-height: (2 * @line-height-l);
	padding-left: @spacing-s;
	border-left: 2px solid @comment-ref-preview-border-color;
	margin: @spacing-xs 0 @spacing-l 0;
	-webkit-box-orient: vertical;
	color: @comment-ref-preview-color;
	-webkit-line-clamp: 2;
	text-overflow: ellipsis;

	&-text {
		&--isInlineThreadWithRemovedContent {
			background-color: @comment-ref-text-hasRemovedSegment-bg-color;
			font-style: italic;
		}
	}

	.comment-thread:hover & {
		color: @comment-hover-ref-preview-color;
	}

	.comment-thread--has-focus & {
		color: @comment-focus-ref-preview-color;
	}
}

.comment__suggestion-guideline {
	.typography-styles-label-medium();

	margin-bottom: @spacing-xs;
	color: @comment-suggestion-guideline-color;

	.comment-thread:hover & {
		color: @comment-hover-suggestion-guideline-color;
	}

	.comment-thread--has-focus & {
		color: @comment-focus-suggestion-guideline-color;
	}

	&--is-approved {
		overflow: hidden;
		color: @comment-suggestion-guideline-approved-color;
		text-overflow: ellipsis;

		&,
		.comment-thread:hover &,
		.comment-thread--has-focus & {
			color: @comment-suggestion-guideline-approved-color;
		}
	}
}

.comment__message-body {
	&,
	.rte__content .rte__paragraph {
		.typography-comment();

		color: @comment-content-color;
		white-space: pre-wrap;
		word-wrap: break-word;

		.comment-thread:hover & {
			color: @comment-hover-content-color;
		}

		.comment-thread--has-focus & {
			color: @comment-focus-content-color;
		}
	}

	.rte__content {
		border: none;
		background: none;

		.public-DraftEditor-content {
			padding: 0;
		}
	}

	&--is-suggestion p {
		display: inline;
		background-color: @color-suggestion-background-highlighted;

		.comment-thread--has-focus & {
			background-color: @color-suggestion-background-selected;
		}
	}
}

.comment__fly {
	.typography-styles-body-medium();

	background-color: @color-suggestion-background-selected;
}
