@pane-padding: @spacing-l;
@card-pane-bg-color: @color-white;

/* --------------------------------------------------------------------------------------------------------------------------------------*\
    Components
    card consists of
	__pane..............................Wrapper for whole card
		__content-section...............Wrapper for section with content
			__title-section.............Wrapper for title section
				__title.................Card's title
				__activation-switch?....Activation switch
			__content+..................Content inside card - there can be more __content classes because of vertical rhytm of the content
				__content-item?........Wrapper for an API key input
		__bottom-section?...............Wrapper for section which is in the bottom of card - e.g. containing button etc.
\*-------------------------------------------------------------------------------------------------------------------------------------- */

.card__pane {
	.border-low-emphasis();

	display: flex;
	max-width: 100%;
	flex: 1 1 auto;
	flex-flow: column nowrap;
	justify-content: space-between;
	padding: @spacing-card;
	border-radius: @border-radius-l;
	background-color: @card-pane-bg-color;
	color: @color-text-default-on-light;
}

.card__content-section {
	& + .card__bottom-section {
		margin-top: @spacing-card;
	}
}

.card__title-section {
	display: flex;
	align-items: center;
}

.card__title-section + .name-editor {
	margin-top: @spacing-card;
}

.card__title {
	.typography-card-headline();

	margin-bottom: 0;
}

.card__activation-switch {
	min-width: 158px;
	margin-top: @spacing-xl;
}

.card__content {
	.typography-styles-ui-paragraph();

	color: @color-text-default-on-light;

	.card__title + &,
	.card__title-section + & {
		margin-top: @spacing-card;
	}

	& + & {
		margin-top: @spacing-card;
	}
}

.card__content-item {
	margin-top: @spacing-card;
}

.card__bottom-section {
	.typography-styles-ui-paragraph();

	flex: 1 1 auto;
	color: @color-text-default-on-light;
}
