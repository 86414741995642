/* ----------------------------------------------------------------------------------------------------------*\
		Component variables
\*---------------------------------------------------------------------------------------------------------- */

@content-item-element-name-placeholder-color: @color-text-hint;
@content-item-element-name-color: @color-text-default-on-light;

@content-item-element-bg-color: @color-white;
@content-item-element-border-color: @color-gray20;
@content-item-element-guideline-color: @color-gray60;
@content-item-element-incomplete-shadow-color: @color-alert-background-inverse;

@content-item-element-disabled-border-color: @color-border-disabled;
@content-item-element-disabled-bg-color: @color-background-disabled;

@content-item-element-focus-border-color: @color-focus;
@content-item-element-hover-border-color: @color-sky-blue20;

@content-item-element-highlight-animation-color: @color-background-highlighted;

@content-item-element-input-color: @color-text-default-on-light;
@content-item-element-input-error-color: @color-alert-text;

@content-item-element-status-color: @color-text-hint;
@content-item-element-status-invalid-color: @color-alert-text;

@content-item-element-alert-error-bg-color: @color-alert-background-inverse;
@content-item-element-alert-error-color: @color-text-default-on-dark;
@content-item-element-alert-warning-bg-color: @color-warning-background-inverse;
@content-item-element-alert-warning-color: @color-text-default-on-light;

@content-item-element-side-padding: @spacing-s;
@content-item-element-top-padding: @spacing-xl;

@content-item-element-bottom-padding: @spacing-xs;
@content-item-element-bottom-padding-small: @spacing-l;

@content-item-element-horizontal-padding: @spacing-xxl;
@content-item-element-horizontal-padding-small: @spacing-l;

@content-item-element-content-bottom-padding: @spacing-xxl;

/* ----------------------------------------------------------------------------------------------------------*\
		Components


		content-item-element consists of
			__header......................Wrapper for label and guidelines
			__content.......................Filled content defined by user
				__input-wrapper..................Wrapper for input and its action buttons
			__compact-wrapper....................Wrapper with compact width
			__label..............................Label as written in content type
			__tag................................Additional field info text
			__guidelines.........................Contextual guidelines
			__name...............................Item name input
			__text-field.........................Text field
			__alert-message......................Alert message
			__number-input.......................Special number input
			__status.............................Status of validation requirements
			__input-field........................Input field in ItemNameElement
			__revision-item-name.................Item name in Content item revision
			__placeholder........................Placeholder element
\*---------------------------------------------------------------------------------------------------------- */

.content-name-element-label() {
	margin-right: @spacing-s;
	.typography-styles-title-large();

	color: @color-text-low-emphasis;
	word-break: break-word;
}

.content-name-element-title() {
	.typography-styles-headline-large();

	position: relative;
	width: 100%;
	padding: 0;
	padding-right: @spacing-s;
	border: solid @border-width-input transparent;
	color: @content-item-element-name-color;
	outline: none;

	&--is-disabled {
		background-color: transparent;
	}

	&:not(.content-item-element__name--is-disabled):focus {
		outline: none;
		transition: all @OLD_transition-250;
	}

	&::placeholder {
		.placeholder();
	}
}

.content-name-element-border() {
	&::before {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 3px;
	}

	&:hover::before {
		background-color: @content-item-element-hover-border-color;
		content: "";
	}

	&--is-caret-focused::before,
	&--is-caret-focused:hover::before {
		background-color: @content-item-element-focus-border-color;
		content: "";
	}

	&--is-highlighted::before,
	&--is-highlighted:hover::before {
		background-color: @content-item-element-incomplete-shadow-color;
		content: "";
	}
}

.content-item-element__content {
	.typography-styles-body-large();

	padding: 0 @content-item-element-horizontal-padding @content-item-element-content-bottom-padding @content-item-element-horizontal-padding;
	color: @color-text-default-on-light;

	.content-item-pane--is-small &,
	.floating-editor & {
		padding: 0 @content-item-element-horizontal-padding-small @content-item-element-bottom-padding-small @content-item-element-horizontal-padding-small;
	}

	&--has-guidelines {
		padding: @spacing-xxl;

		.content-item-pane--is-small &,
		.floating-editor & {
			padding: @spacing-l;
		}
	}

	.rte__content .public-DraftEditor-content {
		padding: 0;
	}
}

.content_item__animation(@name, @color) {
	/* stylelint-disable-next-line keyframes-name-pattern */
	@keyframes @name {
		0% {
			background-color: @color-white;
		}

		50% {
			background-color: @color;
		}

		100% {
			background-color: @color-white;
		}
	}

	animation-delay: 0s;
	animation-direction: normal;
	animation-duration: 0.5s;
	animation-iteration-count: 1;
	animation-name: @name;
	animation-timing-function: ease-in;
}


.content-item-element__wrapper {
	position: relative;
	overflow: hidden;

	&::before {
		.bar-item-pseudo-element-border();

		border-radius: 0;
		border-bottom: none;
		inset: 0;
	}

	.content-item-pane--has-reduced-spacing &:has(.content-item-element--is-name)::before {
		border-top: none;
	}

	&--is-disabled {
		background-color: @color-background-low-emphasis;
	}

	&--is-highlighted-nle {
		background-color: @color-background-element-disabled;
	}
}

.expanded-linked-item__elements,
.expanded-content-component,
.content-component__elements-pane {
	> .content-item-element__wrapper:first-child,
	> .dropzone:first-child .content-item-element__wrapper {
		&::before {
			border-top: 0;
		}
	}

	> .content-item-element__wrapper:last-child,
	> .dropzone:last-child .content-item-element__wrapper {
		&::before {
			border-bottom: @border-width-default solid @color-border-low-emphasis;
			border-bottom-left-radius: @border-radius-element;
			border-bottom-right-radius: @border-radius-element;
		}
	}
}

.content-item-pane__elements-group {
	&--has-top-rounded-corners {
		& > .dropzone:first-child > .content-item-element__wrapper,
		& > .content-item-element__wrapper:first-child {
			//stylelint-disable-next-line
			&,
			&::before {
				border-top-left-radius: @border-radius-element;
				border-top-right-radius: @border-radius-element;
			}
		}
	}

	&--has-bottom-rounded-corners > .content-item-element__wrapper:last-child,
	&--has-bottom-rounded-corners > .dropzone:last-child > .content-item-element__wrapper {
		//stylelint-disable-next-line
		&,
		&::before {
			border-bottom-left-radius: @border-radius-element;
			border-bottom-right-radius: @border-radius-element;
		}

		&::before {
			border-bottom: @border-width-default solid @color-border-low-emphasis;
		}
	}

	&--are-header-content-group-tabs:not(:empty) + .content-item-pane__elements-list .content-item-pane__elements-group:first-child,
	&--are-header-content-group-tabs:not(:empty) + [role='tabpanel'] .content-item-pane__elements-list .content-item-pane__elements-group:first-child,
	&--are-header-content-group-tabs:not(:empty) + .content-item-pane__elements-group,
	.content-group-tabs + .content-item-pane__elements-list & {
		//stylelint-disable-next-line
		> .dropzone:first-child > .content-item-element__wrapper,
		> .content-item-element__wrapper:first-child {
			//stylelint-disable-next-line
			&,
			&::before,
			.content-item-element {
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}

			//stylelint-disable-next-line
			&::before {
				border-top: 0;
			}
		}
	}
}

.content-item-element {
	position: relative;
	overflow: hidden;

	&--is-inline {
		display: inline-flex;
	}

	&--is-highlighting {
		.content_item__animation(content-item__highlight; @content-item-element-highlight-animation-color);
	}

	&--has-focus-comment {
		border-top: 4px @color-comment-background-selected solid;
	}

	&--has-comment {
		border-top: 4px @color-comment-background-highlighted solid;
	}

	.content-component__content & {
		background-color: transparent;
	}

	&__header &__guidelines {
		margin: @spacing-xs 0 @spacing-s 0;
	}

	.content-name-element-border();

	&::before {
		left: @border-width-default;
	}

	&--is-disabled:not(&--is-highlighted) {
		&,
		&:hover {
			&::before {
				content: none;
			}
		}
	}

	&--is-disabled {
		.form__dropdown[disabled] {
			background-color: transparent;
		}
	}

	// Overrides for specific input visuals
	// URL slug
	.text-field {
		background-color: transparent;

		.text-field__input {
			border: none;
			border-radius: 0;
			background-color: transparent;
			font-size: @font-size-element;

			&::placeholder {
				.placeholder();

				font-size: @font-size-element;
			}
		}

		.text-field__button {
			border: none;
		}

		.text-field__button-icon:not(:hover) {
			background-color: transparent;
		}

		&--is-disabled {
			.text-field__input {
				border-color: transparent;
				background-color: transparent;

				&::placeholder {
					.placeholder();

					font-size: @font-size-element;
				}
			}
		}

		&--has-error {
			.text-field__input:focus {
				border-color: transparent;
			}
		}

		&--is-growing {
			min-width: (27 * @grid-unit);
		}
	}

	// Number
	.number__input {
		border: none;
		background-color: transparent;
		font-size: @font-size-element;

		&::placeholder {
			.placeholder();

			font-size: @font-size-element;
		}

		&--is-empty {
			color: @color-text-hint;
		}

		&--is-disabled {
			padding-left: 0;
			border-color: transparent;
			background-color: transparent;
		}
	}

	// Large single select, Large taxonomy
	.combo-box {
		width: calc((100% + @spacing-l));
		max-width: calc((100% + @spacing-l));
		padding-right: @spacing-s;
		border: none;
		margin-left: (@spacing-l * -1);

		// Align the chevron in narrow layout with the comment icon to properly use smaller element padding
		.content-item-pane--is-small &,
		.floating-editor & {
			width: calc((100% + @spacing-l + @spacing-m));
			max-width: calc((100% + @spacing-l + @spacing-m));
		}

		&:focus:not(&--is-disabled) {
			.shadow-focus-styles();
		}

		.dropdown-selected__pane {
			.typography-styles-body-large();

			padding: 0 0 0 @spacing-l;
			font-size: @font-size-element;
		}

		.dropdown-selected__item-icon {
			padding: @spacing-s;
			margin-left: 0;
		}

		&--is-disabled {
			.dropdown-selected__pane {
				background-color: transparent;
				color: @color-text-default-on-light;
			}

			.dropdown-selected__item-icon {
				color: @color-icon-default;
			}
		}

		//stylelint-disable-next-line
		.dropdown-selected--is-placeholder .dropdown-selected__text {
			color: @color-text-hint;
		}
	}

	// Date time
	.datetime-picker__input {
		max-width: calc((100% - @spacing-m));

		// Align the buttons in narrow layout with the comment icon to properly use smaller element padding
		.content-item-pane--is-small &,
		.floating-editor & {
			max-width: 100%;
		}
	}

	// Text, Rich text
	.rte__content {
		border: none;
		font-size: @font-size-element;

		&--is-disabled {
			background-color: transparent;
			caret-color: transparent;
		}

		.public-DraftEditorPlaceholder-root {
			color: @color-text-hint;
			font-size: @font-size-element;
		}
	}

	.diff__simple-value {
		font-size: @font-size-element;
	}
}

.content-item-element__header {
	display: flex;
	flex-direction: column;
	padding: @content-item-element-top-padding @content-item-element-horizontal-padding @content-item-element-bottom-padding @content-item-element-horizontal-padding;

	.expanded-linked-item & {
		padding-right: @content-item-element-horizontal-padding;
	}

	.floating-editor &,
	.content-item-pane--is-small & {
		padding-right: @content-item-element-horizontal-padding-small;
		padding-left: @content-item-element-horizontal-padding-small;

		.expanded-linked-item & {
			padding-right: @content-item-element-horizontal-padding-small;
		}
	}

	.content-item-element--is-disabled & {
		.expanded-linked-item & {
			padding-right: @content-item-element-horizontal-padding;
		}

		.floating-editor & {
			padding-right: @content-item-element-horizontal-padding-small;
		}
	}

	.content-item-element--is-name & {
		padding-bottom: @spacing-m;
	}

	.content-item-element--is-highlighted-nle & {
		padding-right: @content-item-element-horizontal-padding;
	}
}

.content-item-element__info {
	.typography-styles-subheadline-large();

	padding: @spacing-xxl;
	color: @color-text-low-emphasis;

	.content-item-pane--is-small &,
	.floating-editor & {
		padding: @spacing-l;
	}
}

.content-item-element__guidelines {
	clear: both;
	color: @content-item-element-guideline-color;

	p {
		margin: 0;
		.typography-styles-body-small();

		word-wrap: break-word;

		& + p {
			margin-top: (0.5rem);
		}
	}
}

.content-item-element__editing-status {
	.typography-styles-title-large();

	display: flex;
	min-height: @spacing-xxl;
	flex-grow: 1;
	align-items: center;
	color: @color-text-low-emphasis;

	&--unlocked-outdated {
		color: @color-text-low-emphasis;
	}
}

.content-item-element__editing-status-text-wrapper {
	margin: 0 @spacing-s;
}

.content-item-element__editing-status-username {
	.typography-styles-headline-small();

	word-wrap: break-word;
}

.content-item-element__editing-status-icon {
	margin-right: @spacing-s;
	font-size: @icon-size-s;
}

.content-item-element__editing-status-text {
	margin-right: @spacing-s;

	&--is-last {
		margin-right: 0;
	}
}

.content-item-element__text-field {
	min-width: 100%;
	max-width: 100%;
	padding-left: 0;
	border-color: transparent;

	&.text-input--has-error,
	&:focus.text-input--has-error {
		color: @content-item-element-input-error-color;
	}
}

.content-item-element__alert-message {
	border-bottom: 1px solid transparent;
	transition: all 350ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

	&--is-error {
		& .content-item-element__alert-inner-message {
			border-color: @content-item-element-alert-error-bg-color;
			background-color: @content-item-element-alert-error-bg-color;
		}
	}

	&--is-warning {
		& .content-item-element__alert-inner-message {
			border-color: @content-item-element-alert-warning-bg-color;
			background-color: @content-item-element-alert-warning-bg-color;
		}
	}
}


.content-item-element__alert-inner-message {
	.typography-styles-body-small();

	padding: @spacing-s @spacing-m;
	border-radius: @border-radius-m;
	margin: 0 @spacing-xxl @spacing-s @spacing-xxl;
	color: @content-item-element-alert-error-color;

	& + & {
		margin-top: 0;
	}

	&--is-warning {
		color: @content-item-element-alert-warning-color;
	}

	.content-item-pane--is-small &,
	.floating-editor & {
		margin: 0 @spacing-l @spacing-s @spacing-l;
	}
}

.content-item-element__number-input {
	width: 100%;
	min-width: 0;
	padding-right: 0;

	:disabled,
	&--is-disabled,
	input&--is-disabled:disabled { /* This is needed to strengthen the selector in order to override normalize.less */
		border-color: @content-item-element-disabled-border-color;
		appearance: textfield;
		background-color: @content-item-element-disabled-bg-color;
		color: @content-item-element-input-color;
		cursor: text;
		-webkit-text-fill-color: @content-item-element-input-color;
	}
}

.content-item-element__status {
	.typography-styles-label-medium();

	display: flex;
	justify-content: flex-start;
	color: @content-item-element-status-color;
	opacity: 1;
	transition: opacity 200ms;

	&--is-hidden {
		visibility: hidden;
	}
}

.content-item-element__status-pane {
	flex-grow: 1;
	padding-top: @spacing-m;
	opacity: 1;
	transition: opacity 200ms;

	&--right {
		min-width: 15%;
		align-self: flex-end;
		padding-top: @spacing-xs;
		text-align: right;
	}

	&--is-hidden {
		opacity: 0;
	}
}

.content-item-element__status + .content-item-element__status > .content-item-element__status-pane {
	padding-top: @spacing-xs;
}

.content-item-element__status-pane > .content-item-element__status-segment {
	&::before {
		content: "";
	}
}

.content-item-element__status-pane > .content-item-element__status-segment ~ .content-item-element__status-segment {
	&:not(:empty)::before {
		content: " | ";
		white-space: pre-wrap;
	}
}

.content-item-element__status-segment {
	opacity: 1;
	transition: opacity 200ms;

	&--is-invalid {
		color: @content-item-element-status-invalid-color;
	}

	&--is-hidden {
		opacity: 0;
	}
}

.content-item-element__status-item {
	&--is-invalid {
		color: @content-item-element-status-invalid-color;
	}

	&::after {
		content: ", ";
	}

	&:last-child::after {
		content: "";
	}
}

.content-item-element__status-fragment {
	&--is-invalid {
		color: @content-item-element-status-invalid-color;
	}
}

.content-item-element__name {
	.content-name-element-title();
}

.content-item-element__name-wrapper--is-disabled {
	border-top: none;
}

.content-item-element__placeholder {
	color: @color-text-hint;
	font-size: @font-size-element;
}
