.paper {
	.typography-styles-ui-paragraph();
	.border-low-emphasis();

	display: flex;
	width: 100%;
	box-sizing: border-box;
	flex-direction: column;
	align-items: center;
	padding: @spacing-card;
	border-radius: @border-radius-card;
	background-color: @color-white;
	color: @color-text-default-on-light;
}

.paper__container {
	margin-top: @spacing-xl;
}

.paper__title {
	.typography-styles-headline-large();

	margin-bottom: @spacing-xl;
	color: @color-text-default-on-light;
}

.paper__link {
	.typography-styles-ui-paragraph();

	margin-top: @spacing-xl;
	color: @color-text-default-on-light;
	text-align: center;
}

.paper__image {
	height: (27 * @grid-unit);
	margin-bottom: @spacing-l;
}

.paper__content {
	&--has-image {
		display: flex;
		flex-direction: column;
	}
}

.paper__info-below-paper {
	.typography-styles-ui-paragraph();

	margin-top: @spacing-xl;
	color: @color-text-default-on-light;
	text-align: center;
}
