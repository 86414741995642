@web-spotlight-tree-width: (40 * @grid-unit);

.web-spotlight {
	display: grid;
	// fixes the weird scroll behavior inside WS tree in Safari
	max-height: calc((100vh - @status-bar-height));
	flex-grow: 1;
	grid-template-areas: "tree tabs sidebar" "tree workspace sidebar";
	grid-template-rows: auto 1fr;
}


/**
 * The following helper classes (.web-spotlight__layout-guard, .web-spotlight__layout-guard-content) solve a weird bug in Chrome when iframe
 * content breaks parent window layout by calling `.scrollIntoView()` or `.focus()` on an element located outside viewport. Those two classes make sure that
 * the scrollbar doesn't appear when it happens and that the content of the page sticks to its place instead of moving out the viewport.
 *
 * More information about the bug can be found in KCL-9309.
 */
.web-spotlight__layout-guard {
	overflow: hidden;
}

.web-spotlight__layout-guard-content {
	position: sticky;
	top: 0;
}
