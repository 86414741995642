.billing-history__empty-state-title {
	.typography-styles-headline-large();

	color: @color-text-default-on-light;
}

.billing-history__billing-period-column {
	@media (max-width: @OLD_screen-md-max) {
		width: (25 * @grid-unit);
	}
}
