.content-editing-actions__preview-form {
	padding: @spacing-xs 0 @spacing-xs 0;
	color: @color-text-default-on-light;
}

.actions-menu-item {
	display: flex;
	width: 100%;
	align-items: center;
}

.actions-menu-item__icon {
	display: flex;
	width: @size-menu-item-leading-element;
	height: @size-menu-item-leading-element;
	align-items: center;
	justify-content: center;
	margin-right: @spacing-s;
	font-size: @icon-size-m;
}
