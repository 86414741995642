@indent-width: @spacing-xl;

// Max allowed indent ranges from 10% to 90% editor with to fit properly in smaller editor width and still keep the visual sense of nesting
@max-indent-percent: 4%;

.list-counters(@current-depth, @child-counters, @all-counters, @property) {
	// Initial counters for all levels needs to be at the editor top level and table cell level so that they can reach all levels of starting lists
	.DraftEditor-root when (@current-depth = 0) {
		@{property}: @all-counters 0;
	}

	.rte__table-cell when (@current-depth = 0) {
		@{property}: @all-counters 0;
	}

	// Whenever there is a new list after normal content (not list), reset the counters to start from scratch
	.rte__block:not(.rte__list-item) + .rte__list-item when (@current-depth = 0) {
		@{property}: @all-counters 0;
	}

	.rte__list-item--depth@{current-depth} {
		&.rte__ulist-item {
			// Whenever there is a new unordered list, reset the counters at deeper/equal levels
			@{property}: @child-counters 0;
		}

		&.rte__olist-item {
			// Whenever there is a new ordered list, reset the counters at deeper levels
			@{property}: @child-counters 0;
		}
	}
}

.list-items(@scale, @max-depth, @child-counters) when (@max-depth >= 0) {
	@current-depth: @max-depth;
	@indent-depth: (@current-depth * @indent-width);
	@max-indent-depth-percent: (10% + (@current-depth * @max-indent-percent));
	@all-counters: ~"rte__olist-item--depth@{current-depth} @{child-counters}";

	// Firefox needs counter-set to initialize the counters, this also works for Chrome
	.list-counters(@current-depth, @child-counters, @all-counters, counter-set);

	// Safari needs counter-reset but this breaks numbering in FF due to different hierarchical behavior than counter-set (applies only to subtree)
	// counter-set is not supported in Safari at all
	body.safari & {
		.list-counters(@current-depth, @child-counters, @all-counters, counter-reset)
	}

	.rte__list-item--depth@{current-depth} {
		&.rte__olist-item,
		&.rte__ulist-item {
			&::before {
				min-width: ~"calc(@{indent-depth} + 4ch)";
				// The initial level aligns number to dot up to 999. (space for 4 characters)
				max-width: @max-indent-depth-percent;
			}
		}

		&.rte__olist-item::before {
			content: ~"counter(rte__olist-item--depth@{current-depth})"".";
			counter-increment: ~"rte__olist-item--depth@{current-depth}";
		}
	}

	.list-items(@scale, (@current-depth - 1), @all-counters);
}

.list-typography(@scale) {
	.rte__list-item {
		position: relative;
		display: flex;
		max-width: 100%;
		list-style-type: none;

		&::before {
			flex: 0 0 auto;
			text-align: right;
			user-select: none;
			white-space: nowrap;
		}
	}

	.rte__ulist-item::before {
		padding-right: @spacing-s;
		content: "•";
	}

	.rte__olist-item::before {
		padding-right: @spacing-xs;
	}

	.list-items(@scale, 20, "");
}
