// A set of hacks to compensate for the Grammarly extension bugs
// https://github.com/facebook/draft-js/issues/616


// Disable full screen mode from the summary view
grammarly-extension > div:last-child > div:last-child > div:last-child > div:last-child > div:last-child {
	pointer-events: none !important;
}

// Disable full screen mode from the inline card view
grammarly-inline-card,
grammarly-card {
	> div:last-child > div:last-child > div:last-child > div:last-child > div:last-child {
		display: none !important;
	}
}

grammarly-popups {
	// Inline card - Latest version
	div[data-grammarly-part="inline-card"] div[data-part="card-item"][role="button"]:last-child {
		display: none !important;
	}

	// Place tone detector and other fixed global popups to fixed position to make sure it will display in the view
	div[data-grammarly-part="emogenie-popup"],
	> div > div[style*="fixed"] {
		position: fixed !important;
		top: auto !important;
		right: 50px !important;
		bottom: 20px !important;
		left: auto !important;
	}
}

// Make the Grammarly button always visible via sticky position
grammarly-extension div[data-grammarly-part="button"] > div {
	overflow: visible !important;

	> div {
		position: sticky !important;
		z-index: @z-index-five-hundred;
		top: 95px !important;
		margin-left: 100%;
		transform: translateX(-10px) !important;
	}
}
