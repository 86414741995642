@smiley-unsatisfied: "/images/smiley/unsatisfied.svg";
@smiley-satisfied: "/images/smiley/satisfied.svg";
@smiley-neutral: "/images/smiley/neutral.svg";
@smiley-very-satisfied: "/images/smiley/very-satisfied.svg";
@smiley-very-unsatisfied: "/images/smiley/very-unsatisfied.svg";

.smiley {
	margin: auto;

	&--unsatisfied {
		content: url(@smiley-unsatisfied);
	}

	&--satisfied {
		content: url(@smiley-satisfied);
	}

	&--very-satisfied {
		content: url(@smiley-very-satisfied);
	}

	&--very-unsatisfied {
		content: url(@smiley-very-unsatisfied);
	}

	&--neutral {
		content: url(@smiley-neutral);
	}

	&--small {
		height: @size-xs;
	}

	&--after-text {
		margin-left: @spacing-s;
	}

	&--never-used {
		margin: initial;
	}
}
