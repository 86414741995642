.ui-blocking-message__wrapper {
	display: flex;
	width: 100%;
	height: 100%;
	max-height: 100%;
	flex-direction: row;
	align-items: center;
	background: @color-overlay-background;
}


.ui-blocking-message {
	display: flex;
	width: 100%;
	max-width: 100%;
	flex-direction: column;
	align-items: center;
}


.ui-blocking-message__pane {
	width: 500px;
	max-width: 90vw;
	flex-shrink: 0;
	padding: @spacing-xl;
	border-radius: @border-radius-card;
	background-color: @color-white;
	box-shadow: @box-shadow-xs;
	color: @color-text-default-on-light;
	text-align: center;
}

.ui-blocking-message__logo {
	display: flex;
	justify-content: space-around;
	margin-bottom: @spacing-xl;
}

.ui-blocking-message__filler {
	flex-shrink: 1;

	@media (min-width: @OLD_screen-sm-min) {
		height: 200px;
	}
}

.ui-blocking-message__content-title {
	.typography-styles-headline-large();

	margin-bottom: @spacing-xl;
	word-break: break-word;
}

.ui-blocking-message__content-subtitle {
	.typography-styles-ui-paragraph();

	margin-top: @spacing-s;
	margin-bottom: @spacing-xl;
	word-break: break-word;
}
