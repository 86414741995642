/* ----------------------------------------------------------------------------------------------------------*\
		Component variables
\*---------------------------------------------------------------------------------------------------------- */
@revision-item-status-width: @size-assigment-status-width;
@revision-item-status-border-radius: @border-radius-m;

@revision-wrapper-is-selected-color: @color-white;
@revision-wrapper-is-selected-bg-color: @color-primary;

@revision-separator-border-color: @color-divider-default;

@revision-inner-hover-bg-color: @color-background-hover;

@revision-item-current-version-label-color: @color-text-low-emphasis;
@revision-item-detail-note-color: @color-text-default-on-light;
@revision-item-detail-term-color: @color-text-low-emphasis;

/* ----------------------------------------------------------------------------------------------------------*\
		Components


		revision-item consists of
			__status-pane.......................Wrapper for status
				__status..........................Workflow status that is shown together with workflow revision item
			__details-pane......................Wrapper for all details shown in revision
				__current-version-label...........Muted label for current version
				__title...........................Revision item title usually holding a timestamp or workflow status
				__detail-note.....................Small detail note under a title
				__detail-list.....................List of details shown in workflow revision item
					__detail-term...................Detail term shown in workflow revision item
					__detail-data...................Detail data shown in workflow revision item
				__action-link.....................Wrapper for action link to expand / collapse workflow details
			__action-pane.......................Wrapper for action
				__action..........................Action to select a revision
\*---------------------------------------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------------------------------------------*\
		#revision-item
\*---------------------------------------------------------------------------------------------------------- */
.revision-item {
	display: flex;
	overflow: hidden;
	flex-grow: 1;
	align-items: stretch;

	+ .revision-item {
		padding-top: @spacing-xl;
	}
}


.revision__wrapper {
	color: @color-text-default-on-light;
	text-decoration: none;


	.revision__separator {
		border-top: 1px solid @revision-separator-border-color;
	}

	&:first-child {
		.revision__separator {
			border-top: none;
		}
	}

	&:focus,
	&:hover {
		color: @color-text-default-on-light;
		text-decoration: none;
	}
}


.revision__inner {
	display: flex;
	justify-content: space-between;
	padding: @spacing-xl @spacing-xxl;
	cursor: pointer;

	&:hover {
		background-color: @revision-inner-hover-bg-color;
	}
}


.revision__outer {
	padding-right: @spacing-xxl;
	padding-left: @spacing-xxl;
}


/* ----------------------------------------------------------------------------------------------------------*\
		#revision-item__status-pane
\*---------------------------------------------------------------------------------------------------------- */
.revision-item__status-pane {
	display: flex;
}


/* ----------------------------------------------------------------------------------------------------------*\
		#revision-item__status
\*---------------------------------------------------------------------------------------------------------- */
.revision-item__status {
	width: @revision-item-status-width;
	border-radius: @revision-item-status-border-radius;
}


/* ----------------------------------------------------------------------------------------------------------*\
		#revision-item__details-pane
\*---------------------------------------------------------------------------------------------------------- */
.revision-item__details-pane {
	overflow: hidden;
	flex-grow: 1;
	margin-right: @spacing-s;
	margin-left: (@spacing-s + @size-assigment-status-width);

	.revision-item__status-pane ~ & {
		margin-left: @spacing-s;
	}
}


/* ----------------------------------------------------------------------------------------------------------*\
		#revision-item__current-version-label
\*---------------------------------------------------------------------------------------------------------- */
.revision-item__current-version-label {
	.typography-styles-label-large();

	padding-bottom: @spacing-s;
	color: @revision-item-current-version-label-color;
}


/* ----------------------------------------------------------------------------------------------------------*\
		#revision-item__title
\*---------------------------------------------------------------------------------------------------------- */
.revision-item__title {
	.typography-styles-body-large();

	overflow: hidden;
	padding-bottom: @spacing-s;
	text-overflow: ellipsis;
}


/* ----------------------------------------------------------------------------------------------------------*\
		#revision-item__detail-note
\*---------------------------------------------------------------------------------------------------------- */
.revision-item__detail-note {
	.typography-styles-label-large();

	color: @revision-item-detail-note-color;

	&-tooltip {
		text-decoration: underline;
	}
}


/* ----------------------------------------------------------------------------------------------------------*\
		#revision-item__detail-list
\*---------------------------------------------------------------------------------------------------------- */
.revision-item__detail-list {
	.typography-styles-label-large();

	padding-top: @spacing-l;
}


/* ----------------------------------------------------------------------------------------------------------*\
		#revision-item__detail-term
\*---------------------------------------------------------------------------------------------------------- */
.revision-item__detail-term {
	padding-bottom: @spacing-s;
	color: @revision-item-detail-term-color;
}


/* ----------------------------------------------------------------------------------------------------------*\
		#revision-item__action-link
\*---------------------------------------------------------------------------------------------------------- */
.revision-item__action-link {
	display: inline-block;
	margin-top: @spacing-s;

	& .action-link {
		.typography-styles-label-medium();

		border-bottom: none;
		color: @color-link;
		text-decoration: underline;
	}

	&:hover .action-link {
		color: @color-link-hover;
	}
}


/* ----------------------------------------------------------------------------------------------------------*\
		#revision-item__action-pane
\*---------------------------------------------------------------------------------------------------------- */
.revision-item__action-pane {
	display: flex;
	align-items: center;
}


/* ----------------------------------------------------------------------------------------------------------*\
		#revision-item__action
\*---------------------------------------------------------------------------------------------------------- */
.revision-item__action {
	display: flex;
	width: @spacing-xxl;
	height: @spacing-xxl;
	align-items: center;
	justify-content: center;
	border-radius: @border-radius-pill;
	font-family: kentico-icons;
	// Better Font Rendering in Chrome
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 1;
	speak: none;
	visibility: hidden;

	.revision__wrapper--is-selected & {
		.action-scaling-animation;

		background-color: @revision-wrapper-is-selected-bg-color;
		color: @revision-wrapper-is-selected-color;
		visibility: visible;

		&::before {
			content: @icon-check;
		}
	}
}
