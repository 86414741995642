@popover-bg-color: @color-white;
@popover-bg-color-dark: @color-gray80;
@popover-border-color: @color-border-low-emphasis;

@popover-over-canvas-sidebar-width: (52 * @grid-unit);

// popover hint
@popover-hint-width: (40 * @grid-unit);
@popover-hint-padding: @spacing-xl;
@popover-hint-is-wide-width: (@popover-hint-width * 2);
@popover-hint-is-medium-wide-width: (@popover-hint-width * 1.5);
@popover-hint-is-small-width: (26 * @grid-unit);
@popover-hint-bg-color: @color-white;

// popover arrow
@popover-arrow-size: (3 * @grid-unit);
@popover-half-arrow-size: (@popover-arrow-size / 2);
@popover-arrow-offset: (2 * @grid-unit);
@popover-arrow-center-offset: (@popover-arrow-offset + @popover-half-arrow-size);
@popover-rte-arrow-offset: (sqrt(((@popover-arrow-size * @popover-arrow-size) + (@popover-arrow-size * @popover-arrow-size))) / 6);

.popover {
	position: relative;
	z-index: @z-index-two-thousand-four-hundred;
	align-items: center;

	&--is-pointing-up {
		margin-top: @popover-arrow-offset;
	}

	&--is-pointing-right {
		margin-right: @popover-arrow-offset;
	}

	&--is-pointing-down {
		margin-bottom: @popover-arrow-offset;
	}

	&--is-pointing-left {
		margin-left: @popover-arrow-offset;
	}
}


.popover__dialog {
	position: absolute;
	z-index: @z-index-eight-hundred;
	display: flex;
	width: @codename--dialog-width;
	flex-direction: column;
	cursor: initial;

	// Code name popover hacks
	&--is-over-content-element {
		// Code name button in content type element is quinary button, popover is positioned relatively to the button
		top: @btn-quinary-size;
		left: ((@codename--dialog-width - @popover-arrow-center-offset - (@btn-quinary-size / 2)) * -1);
	}

	&--is-over-content-group {
		// Code name button in content group is quinary button, popover is positioned relatively to the button
		top: @btn-quinary-size;
		right: ((@codename--dialog-width - @popover-arrow-center-offset - (@btn-quinary-size / 2)) * -1);
		text-align: left;
	}

	&--is-over-app-bar {
		// App bar code name button is tertiary M-size button with icon, popover is positioned relatively to the button
		top: @size-m;
		right: ((@popover-arrow-center-offset - (@size-m / 2)) * -1);
	}

	&--is-over-canvas-sidebar {
		width: @popover-over-canvas-sidebar-width;
		margin-top: ((@popover-arrow-center-offset + (@btn-quinary-size / 2)) * -1);
		// Saved filter edit button is quinary button, popover is positioned relatively to the button
		margin-left: @btn-quinary-size;
	}
}

.popover__arrow {
	&,
	&::before {
		position: absolute;
		z-index: 1;
		display: block;
		height: @popover-arrow-size;
		box-sizing: border-box;
		background: transparent;
		content: " ";
		transition: transform 0.15s linear;
	}

	&::before {
		width: 100%;
		height: 100%;
	}

	.popover--is-pointing-up & {
		top: (@popover-half-arrow-size * -1);
		bottom: initial;
		left: 50%;
		width: @popover-arrow-size;
		height: @popover-half-arrow-size;

		&,
		&::before {
			border: solid @popover-half-arrow-size transparent;
			border-top: none;
			margin-left: (@popover-half-arrow-size * -1);
		}

		&::before {
			border-bottom-color: @popover-bg-color;
		}
	}

	.popover--is-dark.popover--is-pointing-up & {
		& {
			border-bottom-color: transparent;
		}

		&::before {
			border-bottom-color: @popover-bg-color-dark;
		}
	}

	.popover--is-pointing-right & {
		top: 50%;
		right: (@popover-half-arrow-size * -1);
		left: initial;
		width: @popover-half-arrow-size;
		height: @popover-arrow-size;

		&,
		&::before {
			border: solid @popover-half-arrow-size transparent;
			margin-top: (@popover-half-arrow-size * -1);
		}

		&::before {
			border-left-color: @popover-bg-color;
		}
	}

	.popover--is-dark.popover--is-pointing-right & {
		& {
			border-left-color: @popover-bg-color-dark;
		}

		&::before {
			border-left-color: @popover-bg-color-dark;
		}
	}

	.popover--is-pointing-down & {
		top: initial;
		bottom: (@popover-half-arrow-size * -1);
		left: 50%;
		width: @popover-arrow-size;
		height: @popover-half-arrow-size;

		&,
		&::before {
			border: solid @popover-half-arrow-size transparent;
			margin-left: (@popover-half-arrow-size * -1);
		}

		&::before {
			border-top-color: @popover-bg-color;
		}
	}

	.popover--is-dark.popover--is-pointing-down & {
		& {
			border-top-color: @popover-bg-color-dark;
		}

		&::before {
			border-top-color: @popover-bg-color-dark;
		}
	}

	.popover--is-pointing-left & {
		top: 50%;
		right: initial;
		left: (@popover-half-arrow-size * -1);
		width: @popover-half-arrow-size;
		height: @popover-half-arrow-size;

		&,
		&::before {
			border: solid @popover-half-arrow-size transparent;
			border-left: none;
			margin-top: (@popover-half-arrow-size * -1);
		}

		&::before {
			border-right-color: @popover-bg-color;
		}
	}

	.popover--is-dark.popover--is-pointing-left & {
		& {
			border-right-color: @popover-bg-color-dark;
		}

		&::before {
			border-right-color: @popover-bg-color-dark;
		}
	}

	.popover--is-pointing-up.popover--has-pointer-at-start &,
	.popover--is-pointing-down.popover--has-pointer-at-start &,
		/* Popper flip */
	.popover--is-pointing-up.popover--has-pointer-at-end[data-placement="bottom-start"] &,
	.popover--is-pointing-down.popover--has-pointer-at-end[data-placement="bottom-start"] & {
		right: initial;
		left: 0;
		margin-left: @popover-arrow-offset;
	}

	.popover--is-pointing-up.popover--has-pointer-at-end &,
	.popover--is-pointing-down.popover--has-pointer-at-end &,
		/* Popper flip */
	.popover--is-pointing-up.popover--has-pointer-at-start[data-placement="bottom-end"] &,
	.popover--is-pointing-down.popover--has-pointer-at-start[data-placement="bottom-end"] & {
		right: 0;
		left: initial;
		margin-right: @popover-arrow-offset;
	}

	.popover--is-pointing-right.popover--has-pointer-at-start &,
	.popover--is-pointing-left.popover--has-pointer-at-start & {
		top: 0;
		bottom: initial;
		margin-top: @popover-arrow-offset;
	}

	.popover--is-pointing-right.popover--has-pointer-at-end &,
	.popover--is-pointing-left.popover--has-pointer-at-end & {
		top: initial;
		bottom: 0;
		margin-bottom: @popover-arrow-offset;
	}
}

.popover__hint {
	position: relative;
	display: flex;
	max-width: @popover-hint-width;
	flex-direction: column;
	padding: @spacing-xl;
	border: @border-width-default solid @popover-border-color;
	border-radius: @border-radius-l;
	background-color: @color-white;
	box-shadow: @box-shadow-l;
	color: @color-text-default-on-light;

	.popover--is-dark & {
		background-color: @popover-bg-color-dark;
		color: @color-text-default-on-dark;
	}

	.popover--has-full-width & {
		width: @popover-hint-width;
	}

	.popover--is-wide & {
		max-width: @popover-hint-is-wide-width;
	}

	.popover--is-wide.popover--has-full-width & {
		width: @popover-hint-is-wide-width;
	}

	.popover--is-medium-wide & {
		max-width: @popover-hint-is-medium-wide-width;
	}

	.popover--is-medium-wide.popover--has-full-width & {
		width: @popover-hint-is-medium-wide-width;
	}

	.popover--is-small & {
		max-width: @popover-hint-is-small-width;
	}

	.popover--is-small.popover--has-full-width & {
		width: @popover-hint-is-small-width;
	}
}


.popover__message {
	.typography-styles-ui-paragraph();

	max-width: @popover-hint-width;
	white-space: normal;

	.popover--is-wide & {
		max-width: @popover-hint-is-wide-width;
	}

	.popover--is-medium-wide & {
		max-width: @popover-hint-is-medium-wide-width;
	}

	.popover--is-small & {
		max-width: @popover-hint-is-small-width;
	}
}

.popover__action {
	position: relative;
	display: flex;
	justify-content: flex-end;
	margin-top: @spacing-xl;

	.popover--is-dark & {
		border-top: 1px solid @popover-bg-color-dark;
	}
}

.popover__link {
	.popover--is-dark & {
		color: @color-link-inverted;

		&:hover,
		&:focus {
			color: @color-link-inverted-hover;
		}
	}
}

