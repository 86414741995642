@list-item-hover-bg-color: @color-background-hover;
@list-item-text-color: @color-text-default-on-light;
@list-item-text-disabled-color: @color-text-disabled;


.list {
	padding: 0;
	margin: 0;
	list-style-type: none;
}


.list_item {
	.typography-styles-label-large();

	overflow: hidden;
	padding: @spacing-s @spacing-l;
	color: @list-item-text-color;
	cursor: pointer;
	text-overflow: ellipsis;
	white-space: nowrap;

	&--is-disabled {
		color: @list-item-text-disabled-color;
		cursor: not-allowed;
	}
}


.list .list_item:hover:not(.list_item--is-disabled), .list_item:focus:not(.list_item--is-disabled) {
	background-color: @list-item-hover-bg-color;
}
