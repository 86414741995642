/* ------------------------------------*\
    Components

    .tabbed-navigation consist of
      __tabs...........................Holds tabs navigation
        __tab..........................Wraps every tab
          __tab-content.......................... Tab content with active indication
            __tab-title..................Wraps the tab's text
                __tab-title-icon...........Holds the icon of a tab
      __tools..........................Wraps the footer of tabbed UI
        __tool.........................Wraps every tools
\*------------------------------------ */

@tab-height: (6 * @grid-unit);
@toggle-height: (5 * @grid-unit);

@tab-text-color: @color-text-default-on-light;
@tab-bg-color: @color-background-low-emphasis;
@tab-hover-bg-color: overlay(@color-background-hover, @tab-bg-color);

@tab-active-bg-color: @color-white;
@tab-active-hover-bg-color: overlay(@color-background-hover, @tab-active-bg-color);

.tabbed-navigation {
	display: flex;
	max-width: 100%;
	flex: 1;

	&--in-content-pane {
		position: relative;
		padding-top: @spacing-l;
		border-bottom: 0;
	}

	&--has-disabled-content {
		background-color: transparent;
		box-shadow: none;
	}

	.expanded-linked-item &,
	.content-component__content & {
		&::before {
			.bar-item-pseudo-element-border();

			z-index: 1;
			border-radius: 0;
			border-block: none;
		}
	}
}

.tabbed-navigation__tabs {
	display: flex;
	flex: 1;
	border-bottom: @border-width-default solid @color-border-low-emphasis;
	margin-top: (@spacing-content-group-tabs * -1);

	.tabbed-navigation--is-empty & {
		border-bottom: none;
	}

	.tabbed-navigation--in-content-pane &,
	.tabbed-navigation--in-type-editor & {
		max-width: 100%;
		flex-wrap: wrap;
	}

	.tabbed-navigation--in-content-pane & {
		// Ensures that the last tab bottom sharp edge does not overlap the rounder corner of the elements below
		padding-right: @border-radius-m;
	}
}

.tabbed-navigation__tab {
	.typography-styles-label-large();

	position: relative;
	display: flex;
	max-width: 100%;
	min-height: @tab-height;
	box-sizing: border-box;
	flex: 0 1;
	align-items: center;
	padding: 0 @spacing-l;
	border: @border-width-default solid transparent;
	border-bottom: none;
	margin: @spacing-content-group-tabs @spacing-content-group-tabs 0 0;
	background-color: @tab-bg-color;
	color: @tab-text-color;
	text-align: center;
	text-decoration: none;
	transition: border-color @transition-250, background-color @transition-250;
	user-select: none;
	white-space: nowrap;

	&::after {
		position: absolute;
		right: 0;
		bottom: -1px;
		left: 0;
		border-bottom: @border-width-default solid transparent;
		content: "";
		pointer-events: none;
		transition: border-color @transition-250;
	}

	&:focus {
		color: @tab-text-color;
		text-decoration: none;
	}

	&:hover {
		background-color: @tab-hover-bg-color;

		.rte--has-focus .content-component__content--is-highlighted &,
		.rte--has-focus .expanded-linked-item--is-highlighted & {
			background-color: @tab-hover-bg-color;
		}

		.tabbed-navigation--is-dragging & {
			background-color: @tab-bg-color;
		}
	}

	&:hover,
	&:active {
		color: @tab-text-color;
		text-decoration: none;
	}

	&&--is-active {
		.typography-styles-label-large();

		border-color: @color-border-low-emphasis;
		background-color: @tab-active-bg-color;

		&::after {
			border-color: @tab-active-bg-color;
		}

		&:hover {
			background-color: @tab-active-hover-bg-color;

			.tabbed-navigation--is-dragging &,
			.rte--has-focus .content-component__content--is-highlighted &,
			.rte--has-focus .expanded-linked-item--is-highlighted & {
				background-color: @tab-active-bg-color;
			}
		}
	}

	&:not(.my-content__tab) {
		&:first-child {
			border-top-left-radius: @border-radius-s;
		}

		&:last-child,
		&:last-of-type {
			border-top-right-radius: @border-radius-s;
		}
	}

	&--with-warning,
	&--with-error {
		&::before {
			.typography-styles-font-icon(@icon-size-s);

			margin-right: @spacing-s;
			color: @color-icon-default;
			content: @icon-small-dot-centered;
		}
	}

	&--in-content-type {
		border-top-left-radius: @border-radius-s;
		border-top-right-radius: @border-radius-s;
	}

	&--with-warning::before {
		color: @color-warning-icon;
	}

	&--with-error::before {
		color: @color-alert-icon;
		content: @icon-exclamation-triangle-inverted;
	}

	&--is-stretched {
		flex-grow: 1;

		&:last-child {
			margin-right: 0;
		}
	}
}

.tabbed-navigation__tab-layout {
	display: flex;
	min-width: 0;
	align-items: center;
}

.tabbed-navigation__tab-content {
	display: flex;
	min-width: 0;
	align-items: center;

	&--is-stretched {
		flex-grow: 1;
		justify-content: space-around;
	}
}

.tabbed-navigation__tab-title {
	display: flex;
	align-items: center;
	border-block: 2px solid transparent;
	padding-block: @spacing-xs;
	transition: border-color @transition-250;

	.tabbed-navigation__tab--is-active & {
		border-bottom-color: @color-accent;
	}

	&--has-icon {
		justify-content: center;
	}

	&--in-content-group {
		display: inline-block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.tabbed-navigation__tab-info {
	.typography-styles-label-large();

	display: flex;
	align-items: center;
	margin-left: @spacing-s;

	i {
		font-size: @icon-size-s;
	}

	&--with-warning,
	&--with-error {
		&::after {
			content: @icon-small-dot-centered;
			font-family: kentico-icons;
			font-size: @icon-size-s;
		}

		&:not(:empty)::after {
			margin-left: @spacing-s;
		}
	}

	&--with-warning::after {
		color: @color-warning-icon;
	}

	&--with-error::after {
		color: @color-alert-icon;
	}
}

.tabbed-navigation__tab-title-icon {
	height: (2 * @grid-unit);
	max-height: (4 * @grid-unit);
	margin-right: @grid-unit;

	&--hidden-on-small-screen {
		@media screen and (max-width: @OLD_screen-sm-max) {
			display: none;
		}
	}
}

.tabbed-navigation__vertical-separator {
	width: 1px;
	background-color: transparent;
}

.tabbed-navigation__call-to-action {
	display: flex;
	align-items: center;
	padding-right: @spacing-xs;
	margin-left: auto;
}
