.typography-comment() {
	.typography-styles-body-medium();
}

.rte--in-comment .rte__content {
	.typography-comment();

	padding: ((@size-m - @line-height-l) / 2) @spacing-input-large;
	background-color: @color-input-background;

	.rte__paragraph {
		.typography-comment();
	}

	.public-DraftEditorPlaceholder-root {
		.typography-comment();

		padding: 0;
		-webkit-text-fill-color: @color-text-hint;
	}

	.public-DraftEditor-content {
		padding: 0;
	}

	.public-DraftEditorPlaceholder-inner {
		margin-top: 0;
	}
}
