/**
 * .content-element is composed of:
 *   .content-element-wrapper.................................Wraps around the element the entire width to improve drag&drop experience
 *     .content-element__signifier-space......................Makes space for the signifier (dashed line that visually connects elements to groups)
 *       .content-element.....................................The element itself. This element gets the selection highlight etc.
 */


// local variables
@content-element-type-color: @color-text-hint;
@content-element-name-input-color: @color-text-default-on-light;
@content-element-name-input-has-error-color: @color-alert-text;
@content-element-name-focus-bg-color: @color-background-hover;
@content-element-name-status-color: @color-text-hint;
@content-element-name-status-invalid-color: @color-alert-text;
@content-element-hint-color: @color-text-low-emphasis;
@content-element-guidelines-text-input-bg-color: @color-transparent;
@content-element-guidelines-text-input-hover-bg-color: @color-background-hover;
@content-element-guidelines-vertical-padding: @spacing-xs;
@content-element-dropdown-vertical-padding: (1.25 * @grid-unit);
@content-element-dropdown-height: (@line-height-xxl + (2 * @content-element-dropdown-vertical-padding));
@content-element-config-category-title-color: @color-text-default-on-light;
@content-element-config-section-title-label-color: @color-text-default-on-light;
@content-element-config-section-title-tooltip-color: @color-icon-default;
@content-element-config-section-vertical-spacing: @spacing-l;
@content-element-alert-message-color: @color-text-default-on-dark;
@content-element-border-radius: @border-radius-l;
@content-element-bg-selected-color: @color-background-selected;
@content-element-base-inner-spacing: @spacing-l;
@content-element-additional-inner-spacing: @spacing-s;
@content-element-action-bar-text-margin-top: @spacing-xs; // used to center the text in action-bar vertically (unfortunately, there's no better way to vertically center the first line of a multiline text)
@content-element-dropdown-width: (52 * @grid-unit);
@content-element-config-section-title-tooltip-padding: @spacing-xs;
@content-element-height: (20 * @grid-unit);

.content-element {
	.border-low-emphasis();

	position: relative;
	display: flex;
	min-width: 0;
	flex-direction: column;
	border-radius: @content-element-border-radius;
	background: @color-white;

	&--dialog-pane-expanded {
		min-height: 110px;
	}

	&--is-selected {
		background: @content-element-bg-selected-color;
	}

	&--is-dragging {
		.drag-placeholder();
	}

	// Prevent phantom hovers by disabling pointer events while something is dragging in the list
	.content-type-elements-pane__elements-list--is-dragging & {
		pointer-events: none;
	}

	// Reduce the height for drag preview and use scrim to cut the element content from the bottom to make sure the element is not too large
	.drag-preview & {
		overflow: hidden;
		max-height: @content-element-height;

		&::after {
			position: absolute;
			right: 0;
			bottom: 0;
			left: 0;
			height: (2 * @grid-unit);
			background-image: linear-gradient(to bottom, rgb(@color-white 0) 0%, @color-white 100%);
			content: "";
		}
	}
}


.content-element-wrapper {
	position: relative;
	width: 100%;
	flex-direction: column;
	align-items: center;

	& + & {
		margin-top: @spacing-l;
	}

	// We extend the area horizontally across the whole screen width to properly position new element dragged from element library
	&::before {
		position: absolute;
		top: (@spacing-l * -1);
		left: -50vw;
		width: 200vw;
		height: calc((100% + @spacing-l));
		content: "";
	}
}

.content-element__action-bar {
	display: flex;
	align-content: center;
	align-items: flex-start;
	justify-content: space-between;
	padding: @content-element-base-inner-spacing;
}

.content-element__action-bar-checkbox {
	margin: @grid-unit * -0.5;
	margin-left: 0;
}

.content-element__title {
	display: flex;
	flex: 25;
	margin: 0 @spacing-m;
}

.content-element__type {
	.typography-styles-title-large();

	margin-top: @content-element-action-bar-text-margin-top;
	color: @content-element-type-color;
	white-space: nowrap;
}

.content-element__summary {
	.typography-styles-label-large();

	margin-top: @content-element-action-bar-text-margin-top;
	margin-left: @spacing-l;
	color: @color-link;
	cursor: pointer;
	text-decoration: underline;

	&:hover {
		color: @color-link-hover;
	}

	// Reduce the height for drag preview and use scrim to cut the element content from the bottom to make sure element name is still visible
	.drag-preview & {
		overflow: hidden;
		max-height: (5.5 * @grid-unit);
		mask-image: linear-gradient(to top, transparent 0, @color-black 2 * @grid-unit);
	}
}

.content-element__actions-pane {
	display: flex;
}

// inward icon referencing, consider refactoring it
.content-element__action {
	& + & {
		margin-left: @spacing-l;
	}

	& > [class^="icon-"],
	> [class*=" icon-"] {
		padding: @spacing-xs;
		border-radius: @border-radius-s;
		color: @color-icon-default;
		cursor: pointer;
		font-size: @icon-size-s;
		transition: all @OLD_transition-150;

		&:hover {
			background-color: @color-primary-hover-inverse;
			color: @color-text-default-on-light;
		}

		&:active {
			background-color: @color-primary-hover-inverse;
			color: @color-text-default-on-light;
		}
	}

	&--is-delete {
		[class^="icon-"]:hover,
		[class*=" icon-"]:hover {
			background-color: @color-alert-hover;
			color: @color-text-default-on-dark;
		}
	}

	&--is-disabled-delete {
		& > [class^="icon-"],
		> [class*=" icon-"] {
			color: @color-icon-disabled;
			cursor: default;

			&:hover {
				background-color: @color-transparent;
				color: @color-icon-disabled;
			}

			&:active {
				background-color: inherit;
			}
		}
	}

	&--is-active {
		& > [class^="icon-"],
		> [class*=" icon-"] {
			background-color: @color-background-selected;
			color: @color-primary-selected;
		}
	}
}

.content-element__codename-pane {
	position: relative;
}

.content-element__body {
	padding: @content-element-base-inner-spacing;
	padding-top: 0;
}

.content-element__name {
	.typography-styles-headline-large();

	color: @content-element-name-input-color;
}

.content-element__name-input {
	display: inline-block;
	width: 100%;
	padding: 0 @content-element-additional-inner-spacing;
	border: @border-width-default solid @color-transparent;
	background: @color-transparent;
	cursor: text;
	transition: all @OLD_transition-150;
	word-break: break-all;

	&:hover:not(&:focus) {
		border-radius: @border-radius-s;
		background-color: @content-element-name-focus-bg-color;
	}

	&:focus {
		border: @border-width-default solid @content-item-element-focus-border-color;
		border-radius: @border-radius-s;
	}

	&&--is-empty { // Strong selector in order to override color.
		&::placeholder {
			.placeholder();
		}
	}

	&&--has-error {
		color: @content-element-name-input-has-error-color;

		&::placeholder {
			.placeholder(@content-element-name-input-has-error-color);
		}
	}
}

.content-element-name__status {
	.typography-styles-label-medium();

	display: flex;
	justify-content: flex-start;
	color: @content-element-name-status-color;
	opacity: 1;
	transition: opacity 200ms;

	&--is-hidden {
		opacity: 0;
	}
}

.content-element-name__status-pane {
	flex-grow: 1;
	padding-top: @spacing-xs;

	&--right {
		text-align: right;
	}
}

.content-element-name__status-item {
	&--is-invalid {
		color: @content-element-name-status-invalid-color;
	}
}

.content-element__dropdown {
	.typography-styles-headline-large();

	max-width: @content-element-dropdown-width;
	height: @content-element-dropdown-height;
}

.content-element__default-value label {
	display: none;
}

.content-element__value {
	margin: 0 @content-element-additional-inner-spacing;

	&--in-guidelines-element {
		margin: 0;
	}

	&--without-guideline {
		margin-bottom: @content-element-additional-inner-spacing;
	}
}

.content-element__hint {
	.typography-styles-label-large();

	margin: 0 @content-element-additional-inner-spacing;
	color: @content-element-hint-color;

	.content-element__name + & {
		margin-top: @spacing-l;
	}
}

.content-element__config-category {
	margin: @spacing-xl @content-element-additional-inner-spacing 0;

	& > .row {
		margin-bottom: (@content-element-config-section-vertical-spacing * -1);
	}
}

.content-element__config-category-title {
	.typography-styles-label-large();

	margin: 0 0 @spacing-l 0;
	color: @content-element-config-category-title-color;
}

.content-element__config-section {
	padding-bottom: @content-element-config-section-vertical-spacing;
}

.content-element__config-section-title {
	display: flex;
	align-items: center;
	padding-bottom: @spacing-element-label;

	&--with-tooltip {
		padding-bottom: (@spacing-element-label - @content-element-config-section-title-tooltip-padding);
		margin-top: (@content-element-config-section-title-tooltip-padding * -1);
	}
}

.content-element__config-section-title-label {
	.typography-styles-label-large();

	color: @content-element-config-section-title-label-color;
}

.content-element__config-section-title-tooltip {
	.typography-styles-label-large();

	width: @icon-size-l;
	height: @icon-size-l;
	padding: @content-element-config-section-title-tooltip-padding;
	border-radius: @border-radius-s;
	margin-left: @spacing-xs;
	color: @content-element-config-section-title-tooltip-color;

	&:hover {
		background-color: @color-primary-hover-inverse;
	}
}

.content-element__config-section-control {
	&--full-width {
		width: 100%;
	}
}

.content-element__config-checkbox {
	margin-top: (@spacing-l - @option-padding);
	margin-bottom: (@option-padding * -1);

	& + .content-element__config-category {
		margin-bottom: 0;
	}
}

.content-element__config-predefined-regexes {
	display: flex;
	flex-flow: row wrap;
}

.content-element__config-predefined-regexes-dropdown-item {
	width: 50%;
	align-self: center;
}

.content-element__config-predefined-regexes-hint-item {
	align-self: center;
}

.content-element__config-number-limit {
	display: flex;
}

.content-element__config-number-limit-item {
	width: 100%;

	& + & {
		padding-left: @spacing-s;
	}
}

.content-element__config-new-option-creator {
	&--has-options {
		margin-top: @spacing-m;
	}
}

.content-element__alert {
	padding: @spacing-s @spacing-l;
	border-radius: @border-radius-m;
	margin: (@content-element-base-inner-spacing + @content-element-additional-inner-spacing);
	margin-top: 0;
	background-color: @color-alert-background-inverse;

	&--before-warning {
		margin-bottom: @spacing-m;
	}
}

.content-element-alert-message {
	.typography-styles-label-medium();

	display: block;
	color: @content-element-alert-message-color;
}

.content-element__formatting {
	display: inline-block;
}

.content-element__formatting-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: @spacing-m;
	text-align: right;
}

.content-element__formatting-header-title {
	.typography-styles-label-large();

	color: @content-element-config-category-title-color;
}
