/* ----------------------------------------------------------------------------------------------------------*\
		Components


		testimonial consists of
			__title...........................Title
			__avatar..........................Ensures correct position of avatar
				__avatar-image................Ensures correct size of avatar
			__content.........................Wrapper for text and signature
				__text........................Text of testimonial
				__signature...................Signature of testimonial
\*---------------------------------------------------------------------------------------------------------- */
.testimonial {
	width: 580px;

	@media (max-width: @OLD_screen-lg-min) {
		width: 360px;
	}
}


.testimonial__title {
	.typography-styles-headline-large();

	padding-left: 140px;
	margin-bottom: @spacing-s;

	@media (max-width: @OLD_screen-lg-min) {
		padding-left: 0;
		margin-bottom: @spacing-xl;
	}
}


.testimonial__text {
	.typography-styles-body-large();

	padding-top: @spacing-l;
	margin-left: (11 * @grid-unit);
	background: url("/images/testimonial/testimonial-quote-marks.svg") no-repeat;
	background-size: 40px 40px;

	@media (max-width: @OLD_screen-lg-min) {
		padding-left: 50px;
		margin-left: 0;
	}
}


.testimonial__avatar {
	position: relative;
	overflow: hidden;
	width: 110px;
	height: 110px;
	border-radius: 50%;
	margin: 120px 30px 0 0;
	float: left;

	@media (max-width: @OLD_screen-lg-min) {
		margin-top: 0;
		margin-bottom: @spacing-xl;
		float: none;
	}
}


.testimonial__avatar-image {
	width: 110px;
	height: auto;
	border: none;
}


.testimonial__signature {
	.typography-styles-body-large();

	padding-top: @spacing-l;

	@media (max-width: @OLD_screen-lg-min) {
		padding-left: 50px;
	}
}
