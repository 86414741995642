@diff-format-border: @border-width-default dashed @color-diff-format-border;
@diff-positive-border: @border-width-default solid @color-diff-positive-border;
@diff-negative-border: @border-width-default solid @color-diff-negative-border;

.added-block-border(@border-radius) {
	&::after {
		position: absolute;
		z-index: @z-index-two;
		border: @diff-positive-border;
		border-radius: @border-radius;
		content: "";
		inset: 0;
		pointer-events: none;
	}
}

.removed-block-border(@border-radius) {
	&::after {
		position: absolute;
		z-index: @z-index-two;
		border: @diff-negative-border;
		border-radius: @border-radius;
		content: "";
		inset: 0;
		pointer-events: none;
	}
}

.text-diff(@class-name, @background-color, @border) {
	.@{class-name} {
		border: @border;
		border-right: none;
		background-color: @background-color;
	}

	.@{class-name} + .@{class-name}:not(.rte__ai-instruction) {
		border-left: none;
	}

	.@{class-name}:first-child {
		border-right: none;
	}

	.@{class-name}:last-child {
		border-right: @border;
	}

	.@{class-name} + span:not(.rte__added):not(.rte__removed):not(.rte__changed) {
		border-top: @border-width-default solid transparent;
		border-bottom: @border-width-default solid transparent;
		border-left: @border;
	}

	// KCL-6325 - This solves hiding extra borders for a link within larger diff text
	// https://kontent-ai.atlassian.net/browse/KCL-6325?focusedCommentId=426959
	// It should ideally apply only when the link itself ends with the same diff class as well
	.@{class-name} + .rte__link .@{class-name} {
		border-left: none;
	}

	.rte__link + .@{class-name} {
		border-left: none;
	}

	.rte__link {
		// KCL-6325 - This solves hiding extra borders for a link within larger diff text
		// https://kontent-ai.atlassian.net/browse/KCL-6325?focusedCommentId=426959
		// It should ideally apply only when there is a same change type after the link
		.@{class-name} {
			border-right: none;
		}
	}
}

/* Text content */
.rte__content {
	[data-block="true"]:not(.rte__table-cell-content),
	.rte__table-cell-content:not(.rte__block--is-added):not(.rte__block--is-removed) {
		.text-diff(rte__changed, @color-diff-format, @diff-format-border);
	}

	.text-diff(rte__added, @color-diff-positive, @diff-positive-border);
	.text-diff(rte__removed, @color-diff-negative, @diff-negative-border);

	.rte__removed {
		text-decoration: line-through;
	}
}

.rte__text-block--is-empty {
	&.rte__block--is-added {
		span[data-offset-key] {
			border: @diff-positive-border;
			background-color: @color-diff-positive;
		}
	}

	&.rte__block--is-removed {
		span[data-offset-key] {
			border: @diff-negative-border;
			background-color: @color-diff-negative;
		}
	}
}

.rte__content-module.rte__block--is-added {
	.bar-item__bar,
	.linked-item--is-top-level > .bar-item__bar {
		background-color: @color-diff-positive;
		.added-block-border(@border-radius-m);
	}

	.expanded-linked-item .bar-item__bar {
		background-color: @color-bar-item-background;

		&::after {
			border: none;
		}
	}
}

.bar-item__bar {
	&--is-added {
		background-color: @color-diff-positive;
		.added-block-border(@border-radius-m);
	}

	&--is-removed {
		background-color: @color-diff-negative;
		.removed-block-border(@border-radius-m);

		.bar-item__title-name {
			text-decoration: line-through;
		}
	}
}

.rte__content-module.rte__block--is-removed {
	.bar-item__bar {
		border: @diff-negative-border;
		border-radius: @border-radius-m;
		background-color: @color-diff-negative;
	}

	.bar-item__title-name {
		text-decoration: line-through;
	}
}

.rte__content-module.rte__block--is-removed .expanded-linked-item {
	.bar-item__bar {
		border: none;
		background-color: @color-bar-item-background;
	}

	.bar-item__title-name {
		text-decoration: none;
	}
}

/* Assets */
.rte__content-image.rte__block--is-added .asset-thumbnail,
.asset-thumbnail.asset-thumbnail--is-added {
	position: relative;
	border-color: @color-diff-positive-border;

	.asset-thumbnail__actions-pane,
	.asset-thumbnail__bottom {
		background-color: @color-diff-positive;
	}
}


.rte__content-image.rte__block--is-removed .asset-thumbnail,
.asset-thumbnail.asset-thumbnail--is-removed {
	position: relative;
	border-color: @color-diff-negative-border;

	.asset-thumbnail__actions-pane,
	.asset-thumbnail__bottom {
		background-color: @color-diff-negative;
	}
}

/* Content component */
.rte__content-component.rte__block--is-added {
	.content-component {
		position: relative;
		.added-block-border(@border-radius-m);
	}

	.bar-item__bar {
		background-color: @color-diff-positive;
		.added-block-border(@border-radius-m);
	}
}

.rte__content-component.rte__block--is-removed {
	.content-component {
		position: relative;
		.removed-block-border(@border-radius-m);
	}

	.bar-item__bar {
		background-color: @color-diff-negative;
		.removed-block-border(@border-radius-m);
	}

	.bar-item__title-name {
		text-decoration: line-through;
	}
}

/* Tree */
.tree-node__item {
	&&--is-added {
		border: @diff-positive-border;
		border-radius: @border-radius-s;
		background-color: @color-diff-positive;
	}

	&&--is-removed {
		border: @diff-negative-border;
		border-radius: @border-radius-s;
		background-color: @color-diff-negative;
		color: @color-text-default-on-light;

		span {
			text-decoration: line-through;
		}
	}
}


/* Multiple choice */
.option {
	&&--is-checkbox&,
	&&--is-radio-button& {
		&--is-added {
			position: relative;
			background-color: @color-diff-positive;
			.added-block-border(@border-radius-s);
		}

		&--is-removed {
			position: relative;
			background-color: @color-diff-negative;
			.removed-block-border(@border-radius-s);

			.option__label {
				text-decoration: line-through;
			}
		}
	}
}


/* Simple value */
.diff__simple-value {
	&--is-compare {
		width: auto;
		box-sizing: border-box;
		white-space: nowrap;
	}

	&--is-empty {
		&:not(.diff__simple-value--is-compare) {
			color: @color-text-hint;
		}
	}

	.diff__chunk {
		&--is-added {
			border: @diff-positive-border;
			background: @color-diff-positive;
		}

		&--is-removed {
			border: @diff-negative-border;
			background-color: @color-diff-negative;
			text-decoration: line-through;
		}
	}
}

/* Diff quick tip */
.diff-tip {
	display: inline-block;
	margin-right: @spacing-s;

	&__content {
		display: inline-block;
	}

	&--added &__content {
		border: @diff-positive-border;
		background-color: @color-diff-positive;
	}

	&--removed &__content {
		border: @diff-negative-border;
		background-color: @color-diff-negative;
		text-decoration: line-through;
	}

	&--changed &__content {
		border: @diff-format-border;
		background-color: @color-diff-format;
	}
}

.notification-bar__actions-pane .switch-wrapper {
	padding-right: @spacing-l;
}
