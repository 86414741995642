@rte-text-base-font-size: @font-size-element;
@rte-heading-scale-factor: 1.1;
@rte-heading-line-height-factor: 1.21;
@rte-paragraph-line-height-factor: 1.37;

@rte-empty-paragraph-size: (@rte-text-base-font-size * @rte-paragraph-line-height-factor);

.rte__typography(@scale) {
  @heading-margin-top: (@scale * @rte-text-base-font-size * @rte-heading-scale-factor * @rte-heading-scale-factor);
  @text-font-size: (@scale * @rte-text-base-font-size);

  word-break: break-word; /* [2] breaking all insanely long words */

  .rte__heading-one,
  .rte__heading-two,
  .rte__heading-three,
  .rte__heading-four,
  .rte__heading-five,
  .rte__heading-six {
    margin-bottom: 0;
    line-height: 1;
  }

  // Font sizes
  .rte__heading-one {
    font-size: (@text-font-size * @rte-heading-scale-factor * @rte-heading-scale-factor * @rte-heading-scale-factor * @rte-heading-scale-factor * @rte-heading-scale-factor * @rte-heading-scale-factor * @rte-heading-scale-factor);
    line-height: @rte-heading-line-height-factor;
  }

  .rte__heading-two {
    font-size: (@text-font-size * @rte-heading-scale-factor * @rte-heading-scale-factor * @rte-heading-scale-factor * @rte-heading-scale-factor * @rte-heading-scale-factor);
    line-height: @rte-heading-line-height-factor;
  }

  .rte__heading-three {
    font-size: (@text-font-size * @rte-heading-scale-factor * @rte-heading-scale-factor * @rte-heading-scale-factor * @rte-heading-scale-factor);
    line-height: @rte-heading-line-height-factor;
  }

  .rte__heading-four {
    color: @color-gray60;
    font-size: (@text-font-size * @rte-heading-scale-factor * @rte-heading-scale-factor * @rte-heading-scale-factor);
    line-height: @rte-heading-line-height-factor;
  }

  .rte__heading-five {
    font-size: (@text-font-size * @rte-heading-scale-factor * @rte-heading-scale-factor);
    font-weight: 500;
    line-height: @rte-heading-line-height-factor;
  }

  .rte__heading-six {
    color: @color-gray60;
    font-size: (@text-font-size * @rte-heading-scale-factor);
    font-weight: 700;
    line-height: @rte-heading-line-height-factor;
  }

  // Typography within content editor
  .rte__paragraph {
    font-size: @rte-text-base-font-size;
    line-height: @rte-paragraph-line-height-factor;
  }

  .rte__list-item {
    font-size: @text-font-size;
  }

  // Spacing
  .rte__block {
    // Reset the default margins, all vertical spacing between blocks is done with padding
    // so that the droppable area made from individual blocks is continuous
    margin-top: 0;
    margin-bottom: 0;

    + .rte__heading-one {
      padding-top: (@scale * 2.5 * @grid-unit);
    }

    + .rte__heading-two,
    + .rte__heading-three,
    + .rte__heading-four,
    + .rte__heading-five,
    + .rte__heading-six {
      padding-top: (@scale * @spacing-xl);
    }

    + .rte__paragraph {
      padding-top: (@scale * @rte-block-margin);
    }

    + .rte__custom-block-sleeve--is-before {
      padding-top: (@scale * @rte-block-margin);
    }

    // Bar item blocks have a reduced spacing between them
    // Note that this gets overriden to normal block spacing by EnsureExpandedBlockSpacing.tsx for expanded bar items
    &.rte__previous--is-content-module,
    &.rte__previous--is-content-component {
      + .rte__next--is-content-module,
      + .rte__next--is-content-component {
        padding-top: (@scale * @spacing-m);
      }
    }

    &:not(.rte__list-item) + .rte__list-item {
      padding-top: (@scale * @rte-block-margin);
    }

    &.rte__list-item + .rte__list-item {
      padding-top: (@scale * @spacing-m);
    }

    &.rte__text-block {
      + .rte__content-image,
      + .rte__table,
      + .rte__content-module,
      + .rte__content-component {
        padding-top: (@scale * @rte-block-margin);
      }
    }
  }

  .rte__custom-block-sleeve + .rte__paragraph,
  .rte__table + .rte__text-block {
    padding-top: @rte-block-margin;
  }

  .list-typography(@scale);
}

.rte__content {
  .rte__typography(1);
}
