@playable-image-min-size: 150px;

.playable-image {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 0;

	&--can-play {
		min-width: @playable-image-min-size;
		min-height: @playable-image-min-size;
		cursor: pointer;
	}
}

.playable-image__preview-image {
	max-width: 100%;

	&--is-missing-size {
		height: @playable-image-min-size;
	}
}

.playable-image__preview-overlay {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;

	&:hover {
		background-color: @color-background-hover;
		transition: all @OLD_transition-250;

		.btn {
			background-color: @color-primary-hover;
		}
	}
}
