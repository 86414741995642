.warning-notification {
	display: flex;
	height: 100%;
	min-height: 50vh;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0;
	margin-bottom: 10vh;
}


.warning-notification__title {
	.typography-styles-headline-large();

	color: @color-text-default-on-light;
}

.warning-notification__message-line {
	margin-top: @spacing-s;
	.typography-styles-ui-paragraph();

	color: @color-text-default-on-light;
	text-align: center;

	& + & {
		margin-top: 0;
	}

	.floating-editor__content & {
		padding: 0 @spacing-6xl;
	}
}
