/* ------------------------------------*\
    Component variables
\*------------------------------------ */
@comment-thread-bg-color: @color-white;
@comment-thread-is-resolved-bg-color: @color-white;
@comment-thread-undo-dialog-bg-color: @color-white;
@comment-thread-padding: @spacing-l;

@comment-animation-duration: 300ms;
@comment-timing: cubic-bezier(0.8, 0, 0.2, 1);

/* ------------------------------------*\
    Components


    comment-thread consists of
      __comment-status................Wraps comment's status (ie. when comment is Resolved)
      __comment.......................Wraps a single-posted comment within a thread
      __reply-pane....................Wraps an area within comment thread to reply to comments
\*------------------------------------ */

/* ------------------------------------*\
    #comment-thread
\*------------------------------------ */
.comment-thread {
	.border-low-emphasis();

	position: relative;
	padding: @comment-thread-padding;
	border-radius: @border-radius-l;
	margin-bottom: @spacing-xl;
	background-color: @comment-thread-bg-color;

	&--is-highlighted {
		box-shadow: @box-shadow-l;
	}

	&:hover:not(&--has-focus) {
		box-shadow: @box-shadow-l;
		cursor: pointer;
	}

	&__undo-dialog {
		padding: @comment-thread-padding;
		background-color: @comment-thread-undo-dialog-bg-color;
	}
}

.comment-thread__positioner {
	overflow: hidden;
	max-height: 0;
	opacity: 0;

	&--has-known-position {
		overflow: visible;
		max-height: initial;
		opacity: 1;
	}

	&--is-animated {
		transition: transform @comment-animation-duration @comment-timing 0ms;
	}
}
