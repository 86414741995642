@singleEaseQuestion-input-height: (11 * @grid-unit);
@singleEaseQuestion-input-width: (12 * @grid-unit);

.single-ease-question {
	&__title {
		margin-bottom: @spacing-s;
		color: @color-text-default-on-light;
		.typography-styles-headline-medium();
	}

	&__scale {
		display: flex;
		justify-content: center;
		margin-bottom: @spacing-xl;
	}

	&__item {
		min-width: @singleEaseQuestion-input-width;
		min-height: @singleEaseQuestion-input-height;
		margin-right: @spacing-s;
		color: @color-text-default-on-light;

		&:last-child {
			margin-right: 0;
		}
	}

	&__label {
		display: flex;
		width: 100%;
		height: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-bottom: @spacing-xs;
		cursor: pointer;

		&::before {
			.typography-styles-font-icon(@icon-size-s);

			border-radius: @border-radius-pill;
			margin-bottom: @spacing-s;
			background-color: @color-white;
			content: @icon-rb-uncheck;
		}

		&--isChecked {
			&::before {
				color: @color-primary;
				content: @icon-rb-check;
			}
		}

		.toggle__input--hidden:focus + & {
			.shadow-focus-styles();

			border-radius: @border-radius-m;
		}

		.toggle__input--hidden:focus@{not-focus-visible} + & {
			.remove-shadow-focus-styles();
		}

		&:hover {
			border-radius: @border-radius-m;
			background-color: @color-background-hover;
		}
	}

	&__explanation {
		.typography-styles-label-medium();

		color: @color-text-low-emphasis;
		text-align: center;
	}
}
