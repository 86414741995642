// This is an adjustment to the cropper.css which is a styling for the cropper-js library (downloaded from the author)
// We try not to modify the original css file and add the adjustments here instead. Just a few media queries were removed from the cropper.css

.cropper-bg {
	background-color: @color-gray50;
	background-image: linear-gradient(45deg, @color-gray30 25%, transparent 25% 75%, @color-gray30 75%), linear-gradient(45deg, @color-gray30 25%, transparent 25% 75%, @color-gray30 75%);
	background-position: 0 0, @grid-unit @grid-unit;
	background-size: 2 * @grid-unit 2 * @grid-unit;
}

.cropper-point {
	width: @grid-unit;
	height: @grid-unit;
	border-radius: (@grid-unit / 2);


	&.point-e {
		right: -(@grid-unit / 2);
		margin-top: -(@grid-unit / 2);
	}

	&.point-n {
		top: -(@grid-unit / 2);
		margin-left: -(@grid-unit / 2);
	}

	&.point-w {
		left: -(@grid-unit / 2);
		margin-top: -(@grid-unit / 2);
	}

	&.point-s {
		bottom: -(@grid-unit / 2);
		margin-left: -(@grid-unit / 2);
	}

	&.point-ne {
		top: -(@grid-unit / 2);
		right: -(@grid-unit / 2);
	}

	&.point-nw {
		top: -(@grid-unit / 2);
		left: -(@grid-unit / 2);
	}

	&.point-sw {
		bottom: -(@grid-unit / 2);
		left: -(@grid-unit / 2);
	}

	&.point-se {
		right: -(@grid-unit / 2);
		bottom: -(@grid-unit / 2);
	}
}

.cropper-point,
.cropper-line {
	background-color: @color-primary;
}

.cropper-view-box {
	border: @border-width-default solid @color-primary;
	outline: none;
}

.cropper-crop-box {
	&:hover .cropper-line,
	&:hover .cropper-point {
		background-color: @color-primary-hover;
	}

	&:hover .cropper-view-box {
		border: @border-width-default solid @color-primary-hover;
	}
}

.cropper-disabled {
	& .cropper-crop-box:hover .cropper-line,
	& .cropper-crop-box:hover .cropper-point {
		background-color: @color-gray60;
	}

	& .cropper-point,
	& .cropper-line {
		background-color: @color-gray60;
	}

	& .cropper-crop-box:hover .cropper-view-box {
		border: @border-width-default solid @color-gray60;
	}

	& .cropper-view-box {
		border: @border-width-default solid @color-gray60;
		cursor: default;
	}

	& .cropper-drag-box,
	& .cropper-line,
	& .cropper-face {
		cursor: default;
	}

	& .cropper-point {
		cursor: not-allowed;
	}
}

.cropper-dashed {
	border: 0 dashed @color-white;
	opacity: 0.3;

	&.dashed-h {
		border-top-width: @border-width-default;
		border-bottom-width: @border-width-default;
	}


	&.dashed-v {
		border-right-width: @border-width-default;
		border-left-width: @border-width-default;
	}
}

.cropper-center {
	&::before,
	&::after {
		background-color: @color-white;
	}
}
