/* ----------------------------------------------------------------------------------------------------------*\
		#typography
\*---------------------------------------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------------------------------------------*\
		#headings
\*---------------------------------------------------------------------------------------------------------- */

h1, h2, h3, h4, h5, h6 {
	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

/* ----------------------------------------------------------------------------------------------------------*\
		#paragraph
\*---------------------------------------------------------------------------------------------------------- */
p {
	margin: 0;

	//vertical rhythm
	& + p {
		margin-top: ((@font-size-l * 3) / 8); // 6px
	}

	& + .form__group {
		margin-top: @spacing-l;
	}
}


/* ----------------------------------------------------------------------------------------------------------*\
		#list
\*---------------------------------------------------------------------------------------------------------- */
ul, ol {
	padding-left: @spacing-l;
	margin: @spacing-s;
}
