/* stylelint-disable */

/* we need custom overrides for intercom, thus disabling stylelint */
.intercom-container,
#intercom-container {
	position: relative;
	z-index: @z-index-seven-hundred !important;
}


/* stylelint-enable */
