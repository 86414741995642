.content-item-editor {
	display: grid;
	grid-template-areas:
		"notifications       notifications     notifications"
		"editing-actions     editing-actions   editing-actions"
		"preview             editor            sidebar";
	grid-template-columns: auto 1fr auto;
	grid-template-rows: auto auto 1fr;

	&--with-preview-opened {
		grid-template-columns: 1fr max(30%, 400px) auto;
	}
}

.content-item-editor__notifications {
	padding-right: @spacing-xl;
	padding-left: @spacing-xl;

	&:not(:empty) {
		padding-top: @spacing-l;

		.floating-editor & {
			padding: @spacing-s;
			border-bottom: solid 1px @color-divider-default;
		}

		.canvas__workspace--detached-scrollbars & {
			padding-top: @spacing-main-layout-top;
		}

		// When we combine two groups of notifications (e.g. standard item notifications and AI notifications)
		// we need to adjust spacing of the second group to keep distance between individual notifications correct
		+ .content-item-editor__notifications:not(:empty) {
			margin-top: -@spacing-s;
		}
	}
}

.content-item-editor__floating-comments {
	pointer-events: none;

	& .comment-thread {
		pointer-events: all;
	}
}
