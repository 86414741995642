.sign-out__title {
	.typography-styles-headline-large();

	margin-top: @spacing-6xl;
	margin-bottom: @spacing-xl;
	color: @color-text-default-on-light;
}

.sign-out__logo-wrapper {
	display: inline-block;
	padding-right: @spacing-3xl;
}
