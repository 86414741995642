.web-spotlight-tree {
	position: relative;
	display: flex;
	max-height: 100%;
	flex-direction: column;
	justify-content: space-between;
	border-right: @border-width-default solid @color-border-low-emphasis;
	grid-area: tree;

	&--is-clickable {
		cursor: pointer;
	}
}

.web-spotlight-tree__nodes-wrapper {
	display: flex;
	height: 100%;
	flex-direction: column;
	background-color: @color-white;
}

.web-spotlight-tree__node {
	padding: 0;
	margin: 0;
	list-style: none;

	&:not(&--is-root) & {
		border-left: @border-width-default solid @color-divider-default;
		margin-left: @spacing-l;
	}
}

.web-spotlight-tree__item {
	.typography-styles-label-large();

	display: flex;
	width: 100%;
	height: @size-s;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding-right: @spacing-s;
	padding-left: @spacing-xs;
	border-radius: @border-radius-m;
	cursor: pointer;
	transition: all @OLD_transition-250;

	&,
	&:active,
	&:focus,
	&:hover {
		color: @color-text-default-on-light;
		text-decoration: none;
	}

	&:not(&--is-disabled):hover,
	&--is-focused:not(&--is-disabled) {
		background-color: @color-background-hover;
		cursor: pointer;
	}

	&--is-selected {
		font-weight: @font-weight-bold;
	}

	&--is-selected:not(&--is-disabled):hover {
		background-color: @color-transparent;
	}

	&--is-disabled {
		cursor: not-allowed;
	}

	.web-spotlight-tree__node--is-root > li > & {
		padding-left: @spacing-s;
	}
}

.web-spotlight-tree__item-name {
	display: flex;
	width: 100%;
	min-width: 0;
	height: 100%;
	align-items: center;

	&--is-redacted {
		color: @color-text-disabled;
	}
}

.web-spotlight-tree__item-name-text-wrapper {
	display: flex;
	min-width: 0;
	height: 100%;
	align-items: center;

	.web-spotlight-tree__item--is-selected & {
		box-shadow: inset 0 (-1 * @border-width-high-emphasis) 0 0 @color-accent;
	}
}

.web-spotlight-tree__item-name-text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.web-spotlight-tree__item-expand-loader {
	padding: @spacing-micro;
	margin: 0 @spacing-micro 0 (@spacing-s - @spacing-micro);
	font-size: @icon-size-xs;
}

.web-spotlight-tree__expand-button {
	width: @spacing-xl;
	height: 100%;
	padding-left: @spacing-s;
	border: none;
	border-radius: @border-radius-m 0 0 @border-radius-m;
	background-color: transparent;
	transition: all @OLD_transition-250;
	visibility: hidden;

	&--active {
		visibility: visible;

		&:hover {
			background-color: @color-background-hover;
		}
	}

	& i {
		margin-right: @spacing-xs;
		font-size: @icon-size-xs;
	}
}

.web-spotlight-tree__top-toggle-button {
	margin: @spacing-m @spacing-s @spacing-s @spacing-s;
}

.web-spotlight-tree__toggle-button {
	position: absolute;
	z-index: @z-index-three;
	top: 50%;
	left: 100%;
	border-radius: @border-radius-pill;
	background-color: @color-white;
	// translate3d fix problem with scrollbar in Safari
	transform: translate3d(-50%, -50%, 0);
}
