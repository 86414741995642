.web-spotlight__workspace {
	position: relative;
	overflow: hidden;
	height: 100%;
	grid-area: workspace;

	.canvas {
		height: 100%;
	}

	&--with-item-editor {
		border-top: @border-width-default solid @color-border-default;
	}

	:not(.web-spotlight__layout-guard-content) > & + .content-item-pane__sidebar {
		margin-top: @status-bar-height;
	}
}
