@color-link-inverted-visited: @color-purple70;
@color-link-inverted-visited-hover: @color-purple30;
@color-link-inverted-hover-background: fade(@color-white, 16%);


.asset-ai-error {
	color: @color-link-inverted;

	&:hover,
	&:active {
		background-color: @color-link-inverted-hover-background;
		color: @color-link-inverted-hover;
	}

	&:visited {
		color: @color-link-inverted-visited;
	}

	&:visited:hover {
		color: @color-link-inverted-visited-hover;
	}
}
