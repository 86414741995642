@notification-bar-height: 56px;

.onboarding-notification-bar__pane {
	position: absolute;
	right: @spacing-main-layout-right;
	bottom: @spacing-l;
	left: (@size-navigation-bar-width + @spacing-main-layout-left);
}

.onboarding-notification-bar {
	height: @notification-bar-height;
}
