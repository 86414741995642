@dropdown-options-bg-color: @color-white;
@dropdown-options-link-color: @color-text-default-on-light;
@dropdown-options-title-color: @color-text-default-on-light;

@dropdown-option-color: @color-text-default-on-light;
@dropdown-option-hover-bg-color: @color-background-hover;

@dropdown-option-is-destructive-hover-color: @color-text-default-on-dark;
@dropdown-option-is-destructive-hover-bg-color: @color-alert-background-inverse;

@dropdown-option-is-disabled-color: @color-text-disabled;

@dropdown-option-is-selected-color: @color-primary-selected;
@dropdown-option-is-selected-bg-color: @color-background-selected;

@dropdown-option-is-selected-hover-bg-color: @color-background-selected-hover;

@dropdown-option-name-with-empty-badge-bg-color: @color-alert-text;

@dropdown-option-action-glyph-color: @color-white;
@dropdown-option-action-glyph-bg-color: @color-azure-blue;

@dropdown-selected-pane-color: @color-text-default-on-light;

@dropdown-option-separator-color: @color-divider-default;

@dropdown-selected-item-icon-loader-color: @color-text-low-emphasis;

@dropdown-option-trailing-size: @size-menu-item-trailing-element;
@dropdown-option-trailing-spacing: @spacing-s;

.dropdown-options {
	z-index: @z-index-two-thousand-four-hundred;
	overflow: hidden;
	min-width: 180px;
	border-radius: @border-radius-m;
	background: @dropdown-options-bg-color;
	box-shadow: @box-shadow-l;

	li {
		list-style: none;
	}
}

.dropdown-options-group {
	&--is-scrollable {
		max-height: 40vh;
		overflow-y: auto;
	}

	> :first-child {
		margin-top: @spacing-s;
	}

	> :last-child {
		margin-bottom: @spacing-s;
	}

	// Dropdown items which are wrapped by a link (e.g. Preview, Asset download)
	// need to preserve the flex layout of the item and avoid default link styles
	> a {
		display: flex;
		width: 100%;
		text-decoration: none;

		&:focus,
		&:hover {
			text-decoration: none;
		}
	}

	& + & {
		border-top: solid 1px @dropdown-option-separator-color;

		&::before {
			display: block;
			overflow: hidden;
			height: 0;
			margin-bottom: @spacing-s;
			content: "";
		}
	}
}

.dropdown-options-group .dropdown-options-title {
	.typography-styles-headline-medium();

	padding: 0 @spacing-l;
	margin-top: @spacing-l;
	margin-bottom: @spacing-s;
	color: @dropdown-options-title-color;
	white-space: nowrap;
}

.dropdown-options__pane {
	.typography-styles-label-large();

	display: flex;
	max-height: 40vh;
	flex-flow: column;
	flex-shrink: 0;
	overflow-x: hidden;
	overflow-y: auto;

	&--longer {
		max-height: calc((100vh - (2 * @spacing-s)));
	}
}


.dropdown-option {
	display: flex;
	width: 100%;
	color: @dropdown-option-color;
	cursor: pointer;

	a {
		color: @dropdown-options-link-color;

		&:focus,
		&:hover {
			text-decoration: none;
		}
	}

	.dropdown-options:not(.dropdown-options--is-selector) &:not(&--is-disabled):hover {
		background-color: @dropdown-option-hover-bg-color;
		color: @dropdown-options-link-color;

		&.dropdown-option--is-destructive {
			background-color: @dropdown-option-is-destructive-hover-bg-color;
			color: @dropdown-option-is-destructive-hover-color;
		}
	}

	&--is-highlighted {
		background-color: @multi-select-option-hover-bg-color;
	}

	&--is-selected {
		background-color: @dropdown-option-is-selected-bg-color;
		color: @dropdown-option-is-selected-color;

		a,
		& {
			background-color: @dropdown-option-is-selected-bg-color;
			color: @dropdown-option-is-selected-color;
		}

		.dropdown-options:not(.dropdown-options--is-selector) &:hover,
		&.dropdown-option--is-highlighted {
			a,
			& {
				background-color: @color-background-selected-hover;
			}
		}
	}

	&--is-disabled {
		color: @dropdown-option-is-disabled-color;
		cursor: not-allowed;

		&:hover {
			background-color: transparent;
			color: @dropdown-option-is-disabled-color;
		}
	}
}


.dropdown-option__pane {
	position: relative;
	display: flex;
	width: 100%;
	flex-flow: row;
	flex-shrink: 0;
	align-items: center;
	justify-content: space-between;
	padding: @spacing-s @spacing-l;
	text-decoration: none;

	&--caption-option {
		justify-content: start;
	}

	.dropdown-options__pane--has-trailing-elements & {
		min-height: (@size-menu-item-trailing-element + (2 * @spacing-s));
	}

	.dropdown-options__pane--has-trailing-elements & > :last-child:not(.dropdown-option__icon):not(.dropdown-option__badge-pane) {
		padding-right: (@dropdown-option-trailing-size + @dropdown-option-trailing-spacing);
	}
}


.dropdown-option__name {
	display: flex;
	overflow: hidden;
	max-width: 300px;
	flex: 1 1 auto;
	align-items: center;
	justify-content: space-between;
	text-overflow: ellipsis;
	white-space: nowrap;

	.dropdown-options__pane--has-trailing-elements & {
		line-height: @dropdown-option-trailing-size;
	}
}

.dropdown-option__action-pane {
	display: flex;
	margin-left: @spacing-xl;
}


.dropdown-option__action {
	display: flex;
	width: (5 * @grid-unit);
	height: (5 * @grid-unit);
	padding: @spacing-xs;
}


.dropdown-option__badge-pane {
	display: flex;
	min-width: @size-menu-item-trailing-element;
	min-height: @size-menu-item-trailing-element;
	flex: 0 0 auto;
	align-items: center;
	justify-content: center;
	margin-left: @spacing-s;
}


.dropdown-option__badge {
	.typography-styles-caption();

	display: flex;
	min-width: @icon-size-s;
	height: @icon-size-s;
	align-items: center;
	justify-content: space-around;
	padding: 0 @spacing-micro;
	border-radius: @border-radius-pill;
	background-color: @color-background-disabled;
	color: @color-text-default-on-light;

	&:empty {
		min-width: @spacing-s;
		height: @spacing-s;
	}

	&--orange {
		background-color: @color-warning-background-inverse;
		color: @color-text-default-on-light;
	}

	&--red {
		background-color: @color-alert-background-inverse;
		color: @color-text-default-on-dark;
	}
}


.dropdown-option__action-glyph {
	display: flex;
	width: 100%;
	height: 100%;
	flex: 1 1;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	visibility: hidden;

	&--is-selected {
		background: @dropdown-option-action-glyph-bg-color;
		color: @dropdown-option-action-glyph-color;
		visibility: visible;
	}
}


.dropdown-option__icon {
	display: flex;
	width: @dropdown-option-trailing-size;
	height: @dropdown-option-trailing-size;
	align-items: center;
	justify-content: center;
	margin-left: @dropdown-option-trailing-spacing;

	i {
		font-size: @icon-size-m;
	}

	&--left {
		min-width: @size-menu-item-leading-element;
		min-height: @size-menu-item-leading-element;
		margin-right: @dropdown-option-trailing-spacing;
		margin-left: 0;
	}

	&--loading {
		color: @dropdown-selected-item-icon-loader-color;

		&.icon-spinner {
			line-height: 1.5;
			.spin-500;
		}
	}
}

.dropdown-option-separator {
	overflow: hidden;
	height: 1px;
	min-height: 1px;
	background-color: @dropdown-option-separator-color;

	+ .dropdown-option {
		margin-top: @spacing-s;
	}

	.dropdown-option + & {
		margin-top: @spacing-s;
	}
}


.dropdown-item {
	padding: @spacing-m @spacing-l;
}


.dropdown-selected {
	display: flex;
	overflow: hidden;
	align-items: center;
	align-self: stretch;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.dropdown-selected__text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.dropdown-selected__pane {
	display: inherit;
	overflow: hidden;
	align-items: center;
	padding: 0 @spacing-s;
	color: @dropdown-selected-pane-color;
	cursor: pointer;
	text-overflow: ellipsis;
	user-select: none;
	white-space: nowrap;

	.dropdown-selected--is-placeholder & {
		color: @color-text-hint;
	}
}

.dropdown-selected__item-icon {
	margin-left: @spacing-m;
	font-size: @icon-size-xs;
}
