@link-dialog-detail-bg-color: @color-white;

.link-dialog {
	box-sizing: border-box;
	outline: none;
	white-space: normal;
	word-wrap: normal;
}

.link-dialog__label {
	.typography-styles-label-large();

	display: block;
	max-width: (28 * @grid-unit);
	flex: 1 0 0;
	padding-left: 0;
	margin: 0;
	margin-bottom: @spacing-m;
	color: @color-text-default-on-light;
	text-align: left;
	white-space: nowrap;
}

.link-dialog__detail-link {
	overflow: hidden;
	text-overflow: ellipsis;
	user-select: none;
	white-space: nowrap;
}

.link-dialog__detail-link-pane {
	overflow: hidden;
}
