.phone-number-reason {
	position: relative;
	padding-top: @spacing-s;
}

.phone-number-reason__explanation {
	.typography-styles-ui-paragraph();

	display: none;
	color: @color-text-default-on-light;

	.phone-number-reason__question:hover + &,
	.phone-number-reason__question:focus + & {
		display: block;
	}
}

