.possibly-incorrect-placement-warning__content {
	height: 100%;
	padding: 0 @spacing-xl;
	background-color: @color-white;
}

.possibly-incorrect-placement-warning__paragraph {
	.typography-styles-ui-paragraph();

	color: @color-text-default-on-light;
}

.possibly-incorrect-placement-warning__checkbox {
	margin-right: @spacing-m;
}
