.setting-section {
	padding: @spacing-card;
	border-radius: @border-radius-l;
	background-color: @card-pane-bg-color;
	box-shadow: @box-shadow-xs;
	color: @color-text-default-on-light;

	&:not(:last-child) {
		margin-bottom: @spacing-xl;
	}
}

.setting-section__title {
	.typography-styles-headline-large();

	margin-bottom: @spacing-xl;
	color: @color-text-default-on-light;
}

.settings__preview-url {
	margin-top: @spacing-l;

	& + & {
		margin-top: @spacing-xl;
	}

	.form__text-field {
		margin-top: @spacing-xs;
	}
}

.preview-settings-macros {
	padding: 0;
	margin: 0;
	list-style: none;
}

