.utility-message {
	&--centered {
		margin: @spacing-l;
		text-align: center;

		@media (min-width: @OLD_screen-sm-min) {
			padding: 0 @spacing-l;
			margin: 100px auto;
		}
	}

	&--in-progress {
		.pulse-500;
	}

	&__title {
		.typography-styles-headline-large();

		color: @color-text-default-on-light;
	}

	&__content {
		.typography-styles-ui-paragraph();

		margin-top: @spacing-xl;
		color: @color-text-default-on-light;
	}
}
